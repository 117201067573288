import { routesRoot } from "App";
import { Button, Image, Tooltip } from "antd";
import { ColumnType as ColumnTypeAntd } from "antd/lib/table";
import { IconCopy, IconSetting } from "assets/icons";
import { IconDoubleChat2 } from "assets/icons";
import { IconInfo } from "assets/icons";
import { IconPlusCircle } from "assets/icons";
import { IconStar } from "assets/icons";
import { IconLocation } from "assets/icons";
import { IconPathLock } from "assets/icons";
import { IconBin } from "assets/icons";
import { IconEditProfile } from "assets/icons";
import classNames from 'classnames';
import { commonCheckPermission, formatDateTime, getLanguage } from "common/shares";
import i18next from "i18next";
import { find, get, isArray, isEmpty, isObject, isObjectLike, isPlainObject, map } from "lodash";
import querystring from 'query-string'
import { CSSProperties } from "react";

type headerType = 'update' |
  'status' |
  'create' |
  'activity' |
  'action' |
  'store' |
  'index' |
  'createAndUpdate' |
  'image' |
  'fullname' |
  'level'

type ColumnType = {
  header?: headerType
  actions?: {
    key: string
    title?: string
    icon?: React.ReactElement | any
    classNameIcon?: string
    onShow?: (value?: unknown, record?: unknown) => boolean
    onClick?: (value?: unknown, record?: unknown) => void
  }[]
}

export const renderCell = (props: (ColumnTypeAntd<unknown>) & ColumnType): ColumnTypeAntd<unknown> => {
  const {
    header,
    actions,
    className,
  } = props;
  const lang = getLanguage() as string;

  switch (header) {
    case 'index':
      const queryParams: any = querystring.parse(window.location.search);

      const { page, limit } = queryParams;
      return ({
        title: 'No.',
        dataIndex: '',
        width: 30,
        className: 'text-center',
        render: (value, record, index) => {
          return (
            <div>{(page - 1) * limit + index + 1}</div>
          );
        }
      });
    case 'image':
      return (
        {
          title: `${i18next.t('image')}`,
          dataIndex: 'image',
          className: classNames(className),
          render: (value) => {

            if (isEmpty(value)) return null;

            if (isPlainObject(value)) {
              return <div className='flex items-center'>
                <div className='item_image'>
                  <div className='box_image'>
                    <Image
                      className={`image-list__item--img`}
                      src={get(value, ['thumb', 'url'], '')}
                      width={94}
                      preview={{
                        src: get(value, ['large', 'url'], '')
                      }}
                    />
                  </div>
                </div>
              </div >
            } else if (isArray(value)) {

              return <div className='flex items-center'>
                <div className='item_image'>
                  <div
                    className='box_icon'>
                    {value?.length > 1 && (<IconCopy className='icon_small' color={'#fff'} />)}
                  </div>
                  <div className='box_image'>
                    <Image.PreviewGroup>
                      {map(value, (image: any, index: number) => {
                        return (
                          <Image
                            className={`image-list__item--img ${index >= 1 && 'hidden'}`}
                            src={get(image, ['thumb', 'url'], '')}
                            width={94}
                            preview={{
                              src: get(image, ['large', 'url'], '')
                            }}
                          />
                        );
                      })}
                    </Image.PreviewGroup>
                  </div>
                </div>
              </div >;
            }
          }
        }
      );
    case 'create':
      return (
        {
          title: `${i18next.t('create')}`,
          dataIndex: 'number',
          className: classNames('text-right', className),
          render: (_, record) => {
            const createdBy = get(record, ['created', 'name']) || get(record, ['created', 'fullname']);
            const createdAt = formatDateTime(get(record, ['createdAt'], ''), '');
            return (
              <div>
                {!isEmpty(createdBy) && <div>{`${i18next.t('bySingle')} ${createdBy}`}</div>}
                {!isEmpty(get(record, ['createdAt'], '')) && <div>{`${i18next.t('atSingle')} ${createdAt}`}</div>}
              </div>
            );
          }
        }
      );
    case 'update':
      return ({
        title: `${i18next.t('update')}`,
        dataIndex: 'updated',
        className: classNames('text-right', className),
        render: (_, record) => {

          const updatedBy = get(record, ['updated', 'name'], '') || get(record, ['updated', 'fullname'], '');
          const updatedAt = formatDateTime(get(record, ['updatedAt']));
          return (
            <div>
              {!isEmpty(updatedBy) && <div>{`${i18next.t('bySingle')} ${updatedBy}`}</div>}
              {!isEmpty(get(record, ['updatedAt'], '')) && <div>{`${i18next.t('atSingle')} ${updatedAt}`}</div>}
            </div>
          );
        }
      });
    case 'createAndUpdate':
      return ({
        title: `${i18next.t('createAndUpdate')}`,
        dataIndex: 'updated',
        className: classNames('text-right', className),
        render: (_, record) => {
          const createdBy = get(record, ['created', 'name']) || get(record, ['created', 'fullname']);
          const createdAt = formatDateTime(get(record, ['createdAt'], ''), '');

          const updatedBy = get(record, ['updated', 'name'], '') || get(record, ['updated', 'fullname'], '');
          const updatedAt = formatDateTime(get(record, ['updatedAt']));
          return (
            <div className="text-xs">
              <div className="mb-2">
                {!isEmpty(createdBy) && <div>{`${i18next.t('createdBy')} ${createdBy}`}</div>}
                {!isEmpty(get(record, ['createdAt'], '')) && <div>{`${i18next.t('atSingle')} ${createdAt}`}</div>}
              </div>
              <div>
                {!isEmpty(updatedBy) && <div>{`${i18next.t('updatedBy')} ${updatedBy}`}</div>}
                {!isEmpty(get(record, ['updatedAt'], '')) && <div>{`${i18next.t('atSingle')} ${updatedAt}`}</div>}
              </div>
            </div>

          );
        }
      });
    case 'status':
      return ({
        title: `${i18next.t('status')}`,
        dataIndex: 'status',
        className: classNames('text-center', className),
        render: (record) => {
          const color = get(record, ['optional', 'color'], '');
          const statusVal = get(record, ['value'], record);
          return (
            <div className={classNames({ 'skeleton-box': false })}>
              <Button
                className={classNames(
                  'detailLabeler__tag d-table m-auto cursor-default bg-gray-200',
                  {
                    'primary': statusVal === 'A',
                    'danger': statusVal === 'F',
                    'lock': statusVal === 'L',
                    'bg-gray-200 text-white': statusVal === 'D' || statusVal === 'W' || statusVal === 'P',
                  }
                )}
                style={{
                  backgroundColor: color,
                }}
              >
                {get(record, ['title', `${lang}`], '')}
              </Button>
            </div>
          );
        }
      });
    case 'activity':
      return (
        {
          title: `${i18next.t('activity')}`,
          dataIndex: 'number',
          className: 'text-right',
          render: (_, item) => {
            const updatedAt = formatDateTime(get(item, ['updatedAt'], ''), '');
            const createdAt = formatDateTime(get(item, ['createdAt'], ''), '');
            return (
              <div>
                {!isEmpty(get(item, ['updatedAt'], '')) && <div>{`${i18next.t('created')} ${createdAt}`}</div>}
                {!isEmpty(get(item, ['createdAt'], '')) && <div>{`${i18next.t('updated')} ${updatedAt}`}</div>}
              </div>
            );
          }
        }
      );
    case 'store':
      return ({
        title: `${i18next.t('store')}`,
        dataIndex: 'store',
        render: (value, record) => {
          const urlImage = get(record, ['store', 'avatar', 'thumb', 'url'], '');
          return <div className='flex items-center'>
            {urlImage && <img src={urlImage} style={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              marginRight: 10
            }} />}
            {get(value, 'name', '')}
          </div>
        }
      })
    case 'level':
      return ({
        title: `${i18next.t('level')}`,
        dataIndex: 'level',
        className: classNames(className),
        render: (value) => {
          const urlImage = get(value, 'image', '');
          return <div className='flex items-center'>
            {urlImage && <img src={urlImage} style={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              marginRight: 10
            }} />}
            {get(value, ['title', lang], '')}
          </div>
        }
      })
    case 'fullname':
      return ({
        title: `${i18next.t('fullname')}`,
        dataIndex: 'fullname',
        className: classNames(className),
        render: (value, record) => {
          const urlImage = get(record, ['avatar'], '');
          return <div className='flex items-center'>
            {urlImage && <img src={urlImage} style={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              marginRight: 10
            }} />}
            {value}
          </div>
        }
      })
    case 'action':
      return ({
        title: i18next.t('action') as string,
        dataIndex: 'action',
        className: classNames('text-center', className),
        render: (value, record) => {
          const pathname = window.location.pathname;
          const currentRoute = find(Object.values(routesRoot), (
            route: { path: string }) => route?.path === pathname);
          const actionsPage: string[] = get(currentRoute, 'action', []);

          return (
            <div className='flex items-center justify-center gap-3'>
              {map(actions, (action, index) => {
                let title = '', className = '', isShow = true, Icon = IconEditProfile;
                const {
                  isCanEdit,
                  isCanDelete,
                } = commonCheckPermission(actionsPage);

                const isActive = !!find(actions, item => item.key === action.key);

                switch (action.key) {
                  case 'edit':
                    title = action.title || i18next.t('Edit');
                    isShow = isCanEdit && isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconEditProfile;
                    break;
                  case 'delete':
                    title = action.title || i18next.t('Delete');
                    isShow = isCanDelete && isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconBin;
                    break;
                  case 'copy':
                    title = action.title || i18next.t('copy');
                    isShow = isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconCopy;
                    break;
                  case 'lock':
                    title = action.title || i18next.t('resetPassword');
                    isShow = isCanEdit && isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconPathLock;
                    break;
                  case 'copyDeepLink':
                    title = action.title || i18next.t('copyDeepLink');
                    isShow = isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconCopy;
                    break;
                  case 'reply':
                    title = action.title || i18next.t('reply');
                    isShow = isCanEdit && isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconDoubleChat2;
                    break;
                  case 'configAddress':
                    title = action.title || i18next.t('configStoreAddress');
                    isShow = isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconLocation;
                    break;
                  case 'closeRequest':
                    title = action.title || i18next.t('requestCloseStore');
                    isShow = isActive && !!get(record, 'requestClose') && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconInfo;
                    break;
                  case 'configStyle':
                    title = action.title || i18next.t('configMainStyle');
                    isShow = isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconStar;
                    break;
                  case 'configTheme':
                    title = action.title || i18next.t('configThemeStyle');
                    isShow = isCanEdit && isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconStar;
                    break;
                  case 'add':
                    title = action.title || i18next.t('add');
                    isShow = isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconPlusCircle;
                    break;
                  case 'add':
                    title = action.title || i18next.t('add');
                    isShow = isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconPlusCircle;
                    break;
                  case 'config':
                    title = action.title || i18next.t('config');
                    isShow = isCanEdit && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon || IconSetting;
                    break;
                  default:
                    title = action.title || '';
                    isShow = isActive && (action.onShow?.(value, record) ?? true);
                    Icon = action.icon;
                    break;
                }
                return (isShow && (isActive ? (
                  <div key={index}>
                    <Tooltip placement="top"
                      title={title}>
                      {Icon && <Icon className={classNames(className, action.classNameIcon, {
                        'cursor-pointer': action?.onClick
                      })}
                        onClick={() => {
                          action?.onClick?.(value, record);
                        }} />}
                    </Tooltip>
                  </div>
                )
                  : (<div key={index} className={'mx-[6px]'}>
                    {Icon && <Icon className={'cursor-not-allowed text-gray-200'} />}
                  </div>)))
              })}
            </div>
          );
        }
      });
    default: {
      return props;
    }
  }

}