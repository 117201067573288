import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import swal from 'sweetalert';

import LoginService from '../../../services/login.service';
import { CodiconAccount } from 'assets/icons';
import useLanguage from 'common/hook/useLanguage';
import WrapperLogin from 'components/WrapperLogin';
import routes from 'routes';
function ForgetPass() {
  const { t: translation } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const {language} = useLanguage();
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  function onFinish(values) {
    setIsVisible(true);
    dispatch(showLoading());
    LoginService.ForgotPass(values).then((result) => {
      dispatch(hideLoading());
      const { isSuccess, message, data = {} } = result;
      setIsVisible(false);
      if (!isSuccess) {
        swal(message && message[language] ? message[language] : translation('forgotPass.fail'), {
          icon: 'warning'
        });
        return;
      } else {
        form.resetFields();

        swal(
          data.message && data.message[language]
            ? data.message[language]
            : translation('forgotPass.success'),
          {
            icon: 'success'
          }
        ).then(() => {
          history.push(routes.optMail.path, { ...values, ...data });
        });
      }
    });
  }

  useEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.render('recaptcha-container', {
        sitekey: process.env.REACT_APP_RECAPTCHA_V3,
        'expired-callback': () => {
          window.grecaptcha.reset('recaptcha-container');
        }
      });
    });
  }, []);

  function handleRenderChild() {
    return (
      <>
        <div className="login">
          <div className="login__parent">
            <Form
              name="login"
              autoComplete="off"
              form={form}
              onFinish={(values) => {
                onFinish(values);
              }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: translation('login.inputEmail')
                  },
                  {
                    type: 'email',
                    message: translation('login.invalibEmail')
                  }
                ]}
              >
                <div className="login__input__icon">
                  <Input
                    className="login__input"
                    placeholder="Email *"
                    type="text"
                    size="large"
                    prefix={<CodiconAccount className="login__iconLeft" />}
                  />
                </div>
              </Form.Item>

              <div className="w-full flex justify-center">
                <Button
                  className="login__button-second"
                  onClick={() => {
                    history.push(routes.login.path);
                  }}
                  size="large"
                >
                  {translation('forgotPass.back')}
                </Button>
                <Button
                  className="login__button blue_button w-full ml-1"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isVisible}
                >
                  {translation('forgotPass.send')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="g-recaptcha" id={'recaptcha-container'} data-size="invisible"></div>
      </>
    );
  }

  return (
    <div className="container">
      <WrapperLogin>{handleRenderChild()}</WrapperLogin>
    </div>
  );
}
export default ForgetPass;
