import { Suspense, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryClientProvider } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import QueryString from 'query-string';

import i18next from './translations/i18next';
import { ENUM_TYPES, LOCAL_STORAGE, } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import Loader from 'components/Loader/third';
import { queryClient } from 'lib/queryClient';
import { changeEnum, handleSignout } from 'redux/actions';

import routers from 'routes';
import AdminRoutes from 'routes/adminRoutes';
import DefaultRoutes from 'routes/defaultRoutes';
import EnumService from 'services/enum.service';
// import useSocket from 'common/hook/WebSocket/useSocket'
export const routesRoot = {};

function App(props) {
   const { isUserLoggedIn } = props;
   const dataUser = encryptStorage.getItem(LOCAL_STORAGE.data);
   const [isLoading, setIsLoading] = useState(true);
   const dispatch = useDispatch();

   useEffect(() => {
      if (!isEmpty(dataUser)) {
         const { permission } = dataUser;

         const listMenu = [...permission];
         handleMappingPermission(listMenu);

         if (isEmpty(permission)) {
            toast.error(i18next.t('youDonotHavePermission'), 3000);
            handleLogout();
         }
      }
      setIsLoading(false);
   }, [dataUser]);


   const handleLogout = () => {
      dispatch(showLoading());
      setTimeout(() => {
         Object.values(LOCAL_STORAGE).forEach((val) => {
            encryptStorage.removeItem(val);
         });
         dispatch(hideLoading());
         dispatch(handleSignout());
         window.location.replace(routers?.login?.path);
      }, 3000);
   };

   // useSocket({ dataUser, handleLogout })

   const checkViewPermission = (screen = {}) => {
      return screen?.action?.indexOf('view') !== -1;
   };

   const handleMappingPermission = (listRouter, parentKeyPaths = []) => {
      !isEmpty(listRouter) &&
         listRouter.forEach((item) => {
            const isView = checkViewPermission(item);
            const key = item.key;
            const child = item.child;
            const keyPaths = [item.key, ...parentKeyPaths];
            routesRoot[key] = {
               ...routers[key],
               ...item,
               isView,
               keyPaths
            };

            if (!isEmpty(child)) {
               handleMappingPermission(child, keyPaths);
            }
         });
      setIsLoading(false);
   };

   function handleFetchCommonFilter() {
      const realQuery = {
         'params[]': Object.values(ENUM_TYPES)
      };
      EnumService.getList(QueryString.stringify(realQuery)).then((result) => {
         const { isSuccess, data } = result;
         if (isSuccess) {
            dispatch(changeEnum(data));
         }
      });
   }

   useEffect(() => {
      if (isUserLoggedIn) {
         const timeOut = setTimeout(() => {
            handleFetchCommonFilter();
         }, 500);
         return () => {
            clearTimeout(timeOut);
         };
      }
   }, [isUserLoggedIn]);

   return (
      <QueryClientProvider client={queryClient}>
         <I18nextProvider i18n={i18next}>
            {isLoading ? (
               <Loader></Loader>
            ) : (
               <Router>
                  <Suspense fallback={<div></div>}>
                     {!isEmpty(dataUser) ? <AdminRoutes /> : <DefaultRoutes />}
                  </Suspense>
               </Router>
            )}
         </I18nextProvider>
      </QueryClientProvider>
   );
}

const mapStateToProps = (state) => ({
   isUserLoggedIn: state.member ? state.member.isUserLoggedIn : false,
   permission: state.member ? state.member.permission : []
});

const mapDispatchToProps = () => {
   return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
