import { CHANGE_LOADING } from 'redux/constant';

let initialState = {
  isOpen: false
};

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOADING: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
