import configServices from './configService';
import Request from './request.service';
import ApiEndpoints from 'common/apiEndPoints';
export default class LoginService {
  static async Signin(data = {}) {
    return await configServices.postService(ApiEndpoints.userLogin, data, false, false);
  }

  static async Register(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/registerUser',
        data
      }).then((result = {}) => {
        const { code, data, errors } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message: errors });
        }
      });
    });
  }

  static async ForgotPass(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Auth/forgot',
        data
      }).then((result = {}) => {
        const { code, data, errors = [] } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message: errors });
        }
      });
    });
  }

  static async ChangeUserPassWord(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'users/change-password',
        data
      }).then((result = {}) => {
        const { code, data, message } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }

  static async ForgotPassStep2(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Auth/forgot_step2',
        data
      }).then((result = {}) => {
        const { code, data, errors = [] } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message: errors });
        }
      });
    });
  }

  static async ForgotPassStep3(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Auth/forgot_step3',
        data
      }).then((result = {}) => {
        const { code, data, message, errors = [] } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data, errors });
        } else {
          return resolve({ isSuccess: false, message, errors });
        }
      });
    });
  }
  static async ForgotRftoken(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Auth/rf_token',
        data
      }).then((result = {}) => {
        const { code, data, message, errors = [] } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data, errors });
        } else {
          return resolve({ isSuccess: false, message, errors });
        }
      });
    });
  }
}
