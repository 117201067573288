import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.userGetList, params);
};
export const Update= async (id, data) => {
  return await configServices.putService(`${ApiEndpoints.userUpdate}/${id}`, data);
};
export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.userCreate, data);
};
export const Delete = async (id) => {
  return await configServices.deleteService(`${ApiEndpoints.userDelete}/${id}`);
};

export const ResetPass= async (id, data) => {
  return await configServices.putService(`${ApiEndpoints.userResetPassword}/${id}`, data);
};
