import { createAsyncThunk } from '@reduxjs/toolkit';

import { StoreAddressConstants } from 'redux/constant';
import { StoreAddressServices } from 'services/index';
export const GetList = createAsyncThunk(StoreAddressConstants.GetList, async (query) => {
  let result = {};
  await StoreAddressServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const Update = createAsyncThunk(StoreAddressConstants.Update, async (query) => {
  let result = {};
  const {id, data} = query;
  await StoreAddressServices.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(StoreAddressConstants.Create, async (query) => {
  let result = {};
  await StoreAddressServices.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Delete = createAsyncThunk(StoreAddressConstants.Delete, async (query) => {
  let result = {};
  await StoreAddressServices.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});


