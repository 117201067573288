import { createSlice } from '@reduxjs/toolkit';

import {
  StoreAction
} from 'redux/actions';

export const storeSlice = createSlice({
  name: 'store',
  initialState: {
    listData: [],
    listDataLite: [],
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {
    clearStoreListLite(state) {
      state.listDataLite = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(StoreAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(StoreAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData, totalCount} = data;
      state.listData = listData;
      state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(StoreAction.GetListLite.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.listDataLite = [];
    });
    builder.addCase(StoreAction.GetListLite.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.listDataLite = data;
      state.isSuccess = isSuccess;
    });
    builder.addCase(StoreAction.Update.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(StoreAction.Create.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(StoreAction.Delete.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});

export const { clearStoreListLite } = storeSlice.actions;

export default storeSlice.reducer;
