import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal as ModalAntd, ModalProps, Spin } from 'antd';
import { forEach, get, isEmpty, map } from 'lodash';

import ButtonCustom from 'components/ButtonCustom';
import { FormInstance, useForm } from 'antd/es/form/Form';
import classNames from 'classnames';

import './styles.scss';
import { getLanguage } from 'common/shares';

type footerType = 'default' | 'cancel'

type modalOptions = {
  open: boolean,
  data: unknown,
  title: string
  id?: string
  loading?: boolean
}

type modalType = {
  modal: modalOptions
  id: string
  setModal: (preModal: unknown) => void
  updateModal: (data: unknown) => void
  footerType?: footerType
  form?: FormInstance<any>
  onFinish?: (values: any) => void
}

const Modal = (props: modalType & ModalProps) => {
  const [formAntd] = useForm();
  const {
    modal,
    open,
    id = '',
    form = formAntd,
    setModal,
    onFinish,
    title,
    footer: footerProp,
    footerType = 'default',
    width = 560,
    onCancel,
    className,
    ...restProps
  } = props;

  useEffect(() => {
    if (modal.open && modal.id === id) {
      form.setFieldsValue({ ...get(modal, 'data', {}) as Record<string, unknown> })
    } else {
      const values: Record<string, unknown> = {};

      forEach(Object.entries(form.getFieldsValue()), ([key]) => {
        values[key] = null;
      })
      form.setFieldsValue({ ...values })
    }
  }, [modal]);

  const { t: translation } = useTranslation();
  const language = getLanguage()

  const footer = useMemo(() => {
    switch (footerType) {
      case 'cancel':
        return (<div className='flex justify-end mb-3'>
         <Button
            onClick={() => setModal({ data: {}, open: false, id: '' })}
            className='button-clear h-[36px]'>
            {translation('cancel')}
          </Button>
        </div>)
      case 'default':
        return (<div className='flex justify-end mb-3 gap-1'>
          <Button
            onClick={() => setModal({ data: {}, open: false, id: '' })}
            className='button-clear h-[36px]'>
            {translation('cancel')}
          </Button>
          <Button onClick={() => form.submit()} className={'button-submit h-[36px]'} type="primary">
            {translation('save')}
          </Button>
        </div>)
    }
  }, [footerType, translation, language]);

  return (
    <ModalAntd
      open={open || (modal.open && modal.id === id)}
      title={title || translation(get(modal, 'title', ''))}
      onCancel={(e) => {
        onCancel?.(e);
        setModal((preModal: Object) => ({
          ...preModal,
          open: false
        }))
      }}
      footer={footerProp !== undefined ? footerProp : footer}
      className={classNames(
        'modal-custom',
        className,
      )}
      width={width}
      {...restProps}
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        {get(modal, 'loading', false) && <div className="modal-loading">
          <div className="modal-loading-wrapper">
            <Spin />
          </div>
        </div>}
        {props.children}
      </Form>
    </ModalAntd>
  );

}
export default Modal;

