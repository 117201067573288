/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, get, isEmpty } from 'lodash';
import querystring from 'query-string';

import { INITIAL_FILTER } from 'common/constants';
import useFilters from 'common/hook/useFilters';
import useLoading from 'common/hook/useLoading';
import { commonCheckPermission, omitNull } from 'common/shares';
import {
  MemberMgmtAction,
  NoticeAction,
  NotificationAction,
  NotificationGroupAction,
  ProductAction,
  RequestEntranceAction,
  SellerRegisterAction,
  ThemeMGMTAction
} from 'redux/actions';
import routers from 'routes';

const withPage = ({
  initialFilters ={}
}) => WrappedComponent => (props) => {
  const {
    keyName: keyNameMain,
    action,
  } = props;

  const keyName = keyNameMain;
  const actions = commonCheckPermission(action);

  const [filters, setFilters] = useFilters({
    initial: { ...INITIAL_FILTER, isFetch: false, ...initialFilters }
  });
  const [loading, setLoading] = useLoading(true);
  const [data, setData] = React.useState([]);
  const [modal, setModal] = useState({
    id: '',
    open: false,
    data: {},
    title: '',
    loading: false,
  });

  const dispatch = useDispatch();

  const currentState = get(routers, [keyName, 'state'], '');
  const {
    totalCount,
    isSuccess,
    listData,
    message,
    ...otherState
  } = useSelector((state) => state[currentState]);

  const fetchList = useCallback(() => {
    const params = {};
    const currentPathName = querystring.parse(window.location.search, {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'bracket'
    });
    const filterParams = omitNull(currentPathName);

    if (!isEmpty(filterParams)) {
      Object.keys({ page: 1, limit: 20, ...filterParams }).forEach(key => {
        if (filterParams[key]) {
          params[key] = filterParams[key];
        }
      });

      setLoading(true);

      switch (keyName) {
        case 'seller_register': {
          dispatch(SellerRegisterAction.GetList(params));
          break;
        }
        case 'product_mgmt': {
          dispatch(ProductAction.GetList(params));
          break;
        }
        case 'store_request_entrance': {
          dispatch(RequestEntranceAction.GetList(params));
          break;
        }
        case 'notice_mgmt': {
          dispatch(NoticeAction.GetList(params));
          break;
        }
        case 'notification_mgmt': {
          dispatch(NotificationAction.GetList(params));
          break;
        }
        case 'notification_group': {
          dispatch(NotificationGroupAction.GetList(params));
          break;
        }
        case 'member_mgmt': {
          dispatch(MemberMgmtAction.GetList(params));
          break;
        }
        case 'theme_mgmt': {
          dispatch(ThemeMGMTAction.GetList(params));
          break;
        }
      }
    }
  }, [data]);

  useEffect(() => {
    fetchList();
  }, [window.location.search]);

  const updateFilter = React.useCallback(
    debounce((dataFilter) => {
      if (dataFilter.isReset) {
        setFilters({ ...INITIAL_FILTER, isFetch: true });
      } else {
        const newFilter = {};
        Object.keys(dataFilter).forEach((key) => {
          if (dataFilter[key]) {
            newFilter[key] = dataFilter[key];
          } else {
            newFilter[key] = null;
          }
        });

        setFilters({
          ...filters,
          ...newFilter
        });
      }

    }, 200), [filters]);


  React.useEffect(() => {
    if (isSuccess) {
      setData(listData);
      setLoading(false);
    }
  }, [listData]);

  const openModal = useCallback((newModal = {}) => {
    setModal(preModal => ({
      ...preModal,
      ...newModal
    }));
  }, [modal]);

  return (<WrappedComponent
    {...{
      ...props,
      ...actions,
      ...otherState,
      fetchList,
      loading, setLoading,
      filters, setFilters,
      data, setData,
      totalCount,
      updateFilter,
      modal, openModal, setModal,
      message,
    }}
  />);
};

export default withPage;
