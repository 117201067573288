import { createAsyncThunk } from '@reduxjs/toolkit';

import { AttributePatternConstants } from 'redux/constant';
import { AttributePatternServices } from 'services/index';

export const GetList = createAsyncThunk(AttributePatternConstants.GetList, async (query) => {
  let result = {};
  await AttributePatternServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
export const Create = createAsyncThunk(AttributePatternConstants.Create, async (query) => {
  let result = {};
  await AttributePatternServices.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
export const UpdateArrange = createAsyncThunk(AttributePatternConstants.UpdateArrange, async (query) => {
  let result = {};
  await AttributePatternServices.UpdateArrange(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
export const Update = createAsyncThunk(AttributePatternConstants.Update, async (query) => {
  let result = {};
  await AttributePatternServices.Update(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});