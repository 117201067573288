import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.noticeGetList, params);
};
export const Update= async (id, data) => {
  return await configServices.putService(`${ApiEndpoints.noticeUpdate}/${id}`, data);
};
export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.noticeCreate, data);
};
export const Delete = async (id) => {
  return await configServices.deleteService(`${ApiEndpoints.noticeDelete}/${id}`);
};

