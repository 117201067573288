import * as React from 'react';
import { Form } from 'antd';

import withFilter from 'common/hoc/withFilter';
import FilterInput from 'components/Filter/FilterInput';
import FilterSelect from 'components/Filter/FilterSelect';

const FilterHeader = (props) => {
  const {
    filters = {},
    listNotificationMainAccountType,
    listNotificationMainStatus,
    listNotificationMainGender,
    listNoticeMainPushStatus,
    loading,
  } = props;

  return (
    <>
      <Form.Item name="info" className="w-full">
        <FilterInput
          filter={filters}
          showIconSearch={false}
          placeholder={'searchFilter.info'}
          label={'info'}
        />
      </Form.Item>
      <Form.Item name="notificationType" className="w-full">
        <FilterSelect
          loading={loading}
          filter={filters}
          placeholder={'filterBy.notificationType'}
          label={'notificationType'}
          dataSelect={listNotificationMainAccountType}
        />
      </Form.Item>
      <Form.Item name={'gender'} className="w-full">
        <FilterSelect
          loading={loading}
          filter={filters}
          placeholder={'filterBy.gender'}
          label={'gender'}
          dataSelect={listNotificationMainGender}
        />
      </Form.Item>
      <Form.Item name={'status'} className="w-full">
        <FilterSelect
          loading={loading}
          filter={filters}
          placeholder={'filterBy.status'}
          label={'status'}
          dataSelect={listNotificationMainStatus}
        />
      </Form.Item>
      <Form.Item name={'pushStatus'} className="w-full">
        <FilterSelect
          loading={loading}
          filter={filters}
          placeholder={'filterBy.pushStatus'}
          label={'pushStatus'}
          dataSelect={listNoticeMainPushStatus}
        />
      </Form.Item>
      <div />
    </>
  );
};

export default withFilter(FilterHeader);