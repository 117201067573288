export const TimeRefreshStudio = 60 * 60 * 1000;

export const defaultPageSize = [
  20,
  50,
  100,
  200,
  300
];
export const ADMINISTRATOR = 'Administrator';
export const STORE_SEQ = 'store_seq';
export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const DELETE = 'delete';
export const PATCH = 'patch';
export const OPTIONS = 'options';
export const DEFAULT_VALUE_SELECT = {
  title: {
    en: '',
    vi: '',
    kr: ''
  },
  value: ''
};

/* CONFig */
export const ROOT_URL = process.env.REACT_APP_API_ROOT;

/* LOCAL_STORAGE */
export const LOCAL_STORAGE = {
  isLogin: 'app@isUserLoggedIn',
  data: 'app@data',
  token: 'app@token',
  refreshToken: 'app@refreshToken',
  enum: 'app@enum',
  setting: 'app@setting',
  drawer: 'app@drawer',
  mode: 'app@mode',
  theme: 'app@theme',
  language: 'app@language',
};

/* config theme */
export const DARK = 'dark';
export const LIGHT = 'light';
export const THEME_DEFAULT = LIGHT;

/* STATUS CODE */
export const STATUS_CODE = {
  SUCCESS: 200,
  CREATE: 201,
  FORBIDDEN: 403,
  UNAUTHORIZED: 401,
  TOKEN_EXPIRED: 1401
};

/* STATUS String */
export const STATUS_TYPE = {
  reject: 'R',
  pass: 'P',
  wait: 'W'
};

export const INITIAL_FILTER = {
  limit: 20,
  page: 1,
  sortedBy: 'desc',
  orderBy: 'updatedAt'
};

export const ENUM_TYPES = {
  commonDayOfTheWeek: 'common-day_of_the_week',
  /* user */
  listUserMainStatus: 'user_main-status',
  listUserMainGender: 'user_main-gender',
  /* event category */
  listEventCategoryStatus: 'event_category-status',
  /* member */
  listMemberMainGender: 'member_main-gender',
  listMemberMainStatus: 'member_main-status',
  listMemberMainMemberType: 'member_main-status_seller',
  /* notice */
  listNoticeMainGender: 'notice_main-gender',
  listNoticeMainStatus: 'notice_main-status',
  listNoticeMainAccountType: 'notice_main-notice_type',
  listNoticeMainAgainstDelete: 'notice_main-against_delete',
  listNoticeMainPushStatus: 'notification_main-push_status',
  /* notification */
  listNotificationMainGender: 'notification_main-gender',
  listNotificationMainStatus: 'notification_main-status',
  listNotificationMainAccountType: 'notification_main-notification_type',
  listNotificationMainSendOneTimes: 'notification_main-send_one_times',
  /* notification group*/
  listNotificationGroupType: 'notification_group-type',
  listNotificationGroupStatus: 'notification_group-status',
  listNotificationGroupMemberType: 'notification_group-member_type',
  listNotificationGroupOperation: 'notification_group-operation',
  /* store */
  listStoreMainGender: 'store_main-gender',
  listStoreMainStatus: 'store_main-status',
  listStoreMainAccountType: 'store_main-account_type',
  listStoreMainStoreType: 'store_main-store_type',
  /* feedback */
  listFeedbackMainStatus: 'feedback_main-status',
  /* store address */
  listStoreAddressMainStatus: 'store_address-status',
  /* main store style*/
  listStoreMainStyleStatus: 'style_main-status',
  /* event */
  listEventMainAccountType: 'event_main-type',
  listEventMainGender: 'event_main-gender',
  listEventMainStatus: 'event_main-status',
  listEventMainShowHome: 'event_main-show_home',
  /* faq */
  listFAQMainStatus: 'faq_main-status',
  /* theme */
  listThemeMainStatus: 'theme_main-status',
  listThemeMainType: 'theme_main-type',
  listThemeMainGender: 'theme_main-gender',
  // theme style
  listThemeStyleMainStatus: 'style_main-status',
  /* qna */
  listQnAMainStatus: 'qna_main-status',
  /* comment */
  listCommentMainStatus: 'comment_main-status',
  listCommentMainRefType: 'comment_main-ref_type',
  /* Review */
  listReviewMainStatus: 'review_main-status',
  listReviewMainEmoType: 'review_main-emo_type',
  /* Age*/
  listAgeMainStatus: 'age_main-status',
  listAgeMainGender: 'age_main-gender',
  /*color*/
  listColorMainStatus: 'color_main-status',
  listColorMainGender: 'color_main-gender',
  /*pattern*/
  listPatternMainStatus: 'pattern_main-status',
  listPatternMainGender: 'pattern_main-gender',
  /*catalog*/
  listCatalogMainStatus: 'style_catalog_main-status',
  listCatalogMainGender: 'style_catalog_main-gender',
  /*category*/
  listCategoryMainStatus: 'category_main-status',
  listCategoryMainGender: 'category_main-gender',
  /** media */
  listMediaDimension: 'file_mgmt-dimension',
  /* Magazine */
  listMagazineMainStatus: 'magazine_main-status',
  listMagazineMainGender: 'magazine_main-gender',
  listMagazineMainExternalConfig: 'magazine_detail_main-external_config',
  listMagazineDetailMainCategoryConfig: 'magazine_detail_main-category_config',
  listMagazineType: 'magazine_detail_main-type',
  listReportSearchImageType: 'report_search-image_type',
  listSellerRegisterMainStatus: 'seller_main-status',
  listLevelStatus: 'user_level-status',
  listStyleMainLogic: 'style_main-logic',
  listStyleMainTime: 'style_main-time'
};

export const SLUG_ROOT = process.env.REACT_APP_API_ROOT || '';

export const DATA_TYPE_MOBILE_CONFIG = {
  STRING: 'STRING',
  JSON: 'JSON',
  FILE_UPLOAD: 'FILE_UPLOAD',
  OBJECTID: 'OBJECTID',
  NUMBER: 'NUMBER',
  JSON_FILE_UPLOAD: 'JSON_FILE_UPLOAD'
};

export const MOBILE_CONFIG_TYPES = [{
  key: 'APP',
  label: 'APP'
}, {
  key: 'SURVEY',
  label: 'SURVEY'
}, {
  key: 'COMMON',
  label: 'COMMON'
}];

export const PRODUCT = 'PRODUCT';
export const STYLE = 'STYLE';