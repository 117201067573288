import { default as cn } from 'classnames';
import PropTypes from 'prop-types';

import MenuComponent from './menu';
import { LeftSquareOutlined, RightSquareOutlined } from '@ant-design/icons';
import Footer from 'components/Widtget/FooterMenu';

import './index.scss';

function Drawer(props) {
  const {
    className,
    isShowSidebar,
    setOpen,
    ...otherProps
  } = props;
  const DrawerClasses = cn('custom-Drawer', 'CustormScrollBar', [className], {
    toggled: !isShowSidebar
  });

  return (
    <>
      {isShowSidebar && (
        <div
          onClick={() => {
            setOpen(false);
          }}
          className='custom-custom-Drawer__overlay md-hidden'
        />
      )}
      <div className={DrawerClasses}>
        <div
          className={cn('custom-Drawer custom-custom-Drawer', {
            toggled: !isShowSidebar
          })}
        >
          <div>
            <MenuComponent {...otherProps} />
          </div>

          <div className="custom-custom-Drawer__footer">

            <div className="flex items-center">
              <Footer />
              {!isShowSidebar ? (
                <LeftSquareOutlined
                  className="custom-custom-Drawer__icon"
                  onClick={() => {
                    setOpen(!isShowSidebar);
                  }}
                />
              ) : (
                <RightSquareOutlined
                  className="custom-custom-Drawer__icon"
                  onClick={() => {
                    setOpen(!isShowSidebar);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Drawer;

Drawer.propTypes = {
  className: PropTypes.string,
  isShowSidebar: PropTypes.bool,
  setOpen: PropTypes.func,
  member: PropTypes.object
};

Drawer.defaultProps = {
  className: '',
  isShowSidebar: false,
  setOpen: () => { },
  member: {}
};
