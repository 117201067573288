import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import { IconSearch } from 'assets/icons';

import './style.scss';

const FilterInput = (props) => {
  const {
    filter,
    handleChange,
    label,
    showIconSearch = false,
    name,
    placeholder,
    ...otherProps
  } = props;

  const value = filter[name] ?? '';

  const { t: translation } = useTranslation();
  return (
    <div className="store__relative w-full">
      {showIconSearch && <IconSearch className="folder__icon" />}
      <Input.Group compact>
        <Button className="button-select r">{translation(label)}</Button>
        <Input
          value={value}
          onChange={(e) => {
            isFunction(handleChange) && handleChange(e.target.value, name);
          }}
          name={name}
          placeholder={translation(placeholder)}
          className="input__search input__group-two"
          {...otherProps}
        ></Input>
      </Input.Group>
    </div>
  );
};

FilterInput.propTypes = {
  dataSelect: PropTypes.any,
  filter: PropTypes.object,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  showIconSearch: PropTypes.bool
};

export default FilterInput;
