import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async(params = {}) => {
    return await configServices.getService(ApiEndpoints.storeMainStyleGetList, params);
};

export const SetListStyle = async(params = {}) => {
    return await configServices.putService(ApiEndpoints.storeMainStyleSet, params);
};

export const UnSetListStyle = async(params = {}) => {
    return await configServices.putService(ApiEndpoints.storeMainStyleUnset, params);
};