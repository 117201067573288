import { createAsyncThunk } from '@reduxjs/toolkit';

import { EventConstants } from 'redux/constant';
import { EventServices } from 'services/index';
export const GetList = createAsyncThunk(EventConstants.GetList, async (query) => {
  let result = {};
  await EventServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
export const GetDetail = createAsyncThunk(EventConstants.GetDetail, async (query) => {
  let result = {};
  await EventServices.GetDetail(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});


export const Update = createAsyncThunk(EventConstants.Update, async (query) => {
  let result = {};
  const {id, data} = query;
  await EventServices.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(EventConstants.Create, async (query) => {
  let result = {};
  await EventServices.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
