import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.menuGetList, params);
};
export const GetListLite = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.menuPermissionGetListLite, params);
};

export const Update= async (id, set) => {
  return await configServices.putService(`${ApiEndpoints.menuUpdate}/${id}`, set);
};

export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.menuCreate, data);
};
export const CreatePermission = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.menuPermissionCreate, data);
};
export const Delete = async (id) => {
  return await configServices.deleteService(`${ApiEndpoints.menuDelete}/${id}`);
};
export const GetListApi = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.menuGetListApi, params);
};
export const EditApi = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.menuEditApi, data);
};

// import Request from './request.service';
// export default class MenuService {
//   static async getMenuList(queryString = '') {
//     return new Promise((resolve) => {
//       Request.send({
//         method: 'GET',
//         path: `bo/menu/get_list?${queryString}`
//       }).then((result = {}) => {
//         const { code, data, message } = result;

//         if (code === 200) {
//           return resolve({ isSuccess: true, data });
//         } else {
//           return resolve({ isSuccess: false, message });
//         }
//       });
//     });
//   }
//   static async getMenuListLite(queryString = '') {
//     return new Promise((resolve) => {
//       Request.send({
//         method: 'GET',
//         path: `menu_permission/get_list_lite?${queryString}`
//       }).then((result = {}) => {
//         const { code, data, message } = result;

//         if (code === 200) {
//           return resolve({ isSuccess: true, data });
//         } else {
//           return resolve({ isSuccess: false, message });
//         }
//       });
//     });
//   }
//   static async getMenuListAPI(queryString = '') {
//     return new Promise((resolve) => {
//       Request.send({
//         method: 'GET',
//         path: `bo/menu/get_list_api?${queryString}`
//       }).then((result = {}) => {
//         const { code, data, message } = result;

//         if (code === 200) {
//           return resolve({ isSuccess: true, data });
//         } else {
//           return resolve({ isSuccess: false, message });
//         }
//       });
//     });
//   }
//   static async updateMenuDetail(data = {}) {
//     return new Promise((resolve) => {
//       Request.send({
//         method: 'POST',
//         path: 'bo/menu/edit',
//         data
//       }).then((result = {}) => {
//         const { code, data, errors } = result;

//         if (code === 200) {
//           return resolve({ isSuccess: true, data });
//         } else {
//           return resolve({ isSuccess: false, message: errors });
//         }
//       });
//     });
//   }
//   static async createMenuDetail(data = {}) {
//     return new Promise((resolve) => {
//       Request.send({
//         method: 'POST',
//         path: 'bo/menu/create',
//         data
//       }).then((result = {}) => {
//         const { code, data, errors } = result;

//         if (code === 200) {
//           return resolve({ isSuccess: true, data });
//         } else {
//           return resolve({ isSuccess: false, message: errors });
//         }
//       });
//     });
//   }
//   static async updateMenuRelationship(data = {}) {
//     return new Promise((resolve) => {
//       Request.send({
//         method: 'POST',
//         path: 'bo/menu/edit_api',
//         data
//       }).then((result = {}) => {
//         const { code, data, errors } = result;

//         if (code === 200) {
//           return resolve({ isSuccess: true, data });
//         } else {
//           return resolve({ isSuccess: false, message: errors });
//         }
//       });
//     });
//   }
//   static async deleteMenuDetail(data = {}) {
//     return new Promise((resolve) => {
//       Request.send({
//         method: 'POST',
//         path: 'bo/menu/delete',
//         data
//       }).then((result = {}) => {
//         const { code, data, errors } = result;

//         if (code === 200) {
//           return resolve({ isSuccess: true, data });
//         } else {
//           return resolve({ isSuccess: false, message: errors });
//         }
//       });
//     });
//   }
//   static async createMenuPermission(data = {}) {
//     return new Promise((resolve) => {
//       Request.send({
//         method: 'POST',
//         path: 'menu_permission/create',
//         data
//       }).then((result = {}) => {
//         const { code, data, errors } = result;

//         if (code === 200) {
//           return resolve({ isSuccess: true, data });
//         } else {
//           return resolve({ isSuccess: false, message: errors });
//         }
//       });
//     });
//   }
// }

