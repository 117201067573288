import { createSlice } from '@reduxjs/toolkit';

import { ThemeMGMTAction } from 'redux/actions';

export const themeMgmtSlice = createSlice({
  name: 'themeMgmt',
  initialState: {
    listData: [],
    listDataArrange: [],
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(ThemeMGMTAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(ThemeMGMTAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { listData, totalCount } = data;
      state.listData = listData;
      state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(ThemeMGMTAction.GetListArrange.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    
    });
    builder.addCase(ThemeMGMTAction.GetListArrange.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { listData } = data;
      state.listDataArrange = listData;
      state.isSuccess = isSuccess;
    });

    builder.addCase(ThemeMGMTAction.Update.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(ThemeMGMTAction.Create.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(ThemeMGMTAction.Delete.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(ThemeMGMTAction.UpdateArrange.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});

export default themeMgmtSlice.reducer;
