import { Button } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

const ButtonCustom = (props) => {
  const { 
    children, 
    type, disabled, className, color, bg, 
    onClick, isBorder, htmlType, ...otherProps } =
    props;
  return (
    <Button
      type={type || 'default'}
      className={classNames(
        `button-custom button-custom-hover bg-[${bg}]`,
        {
          [type]: type,
          [color]: color,
          border: isBorder
        },
        className
      )}
      htmlType={htmlType}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

ButtonCustom.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  bg: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isBorder: PropTypes.bool,
  htmlType: PropTypes.string
};

export default ButtonCustom;
