import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar, { showLoading } from 'react-redux-loading-bar';
import { Breadcrumb, Dropdown, Layout } from 'antd';
import classNames from 'classnames';
import Hamburger from 'hamburger-react';
import _, { get, isEmpty, uniqueId } from 'lodash';

import ModalDetailProfile from './modalDetailProfile';
import ModalPasswordProfile from './modalPasswordProfile';
import { routesRoot } from 'App';
import { IconArrowDown, IconDark, IconLight, LogoNText, LogoNTextDark } from 'assets/icons';
import { DARK, LIGHT, LOCAL_STORAGE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import useLanguage from 'common/hook/useLanguage';
import useTheme from 'common/hook/useTheme';
import { clearStorage, formatToPrice, notificationUpdateForm, SwalConfirmAction } from 'common/shares';
import SelectCustom from 'components/SelectCustom';
import { handleUpdateDetail } from 'redux/actions';
import routers, { renderPageName } from 'routes';
import AccountService from 'services/account.service';

import './index.scss';

const { Header: HeaderAntd } = Layout;

export default function HeaderPage(props) {
  const { keyName } = props;
  const { theme, toggle: toggleTheme } = useTheme();
  const {language, toggle: toggleLanguage} = useLanguage();
  const { t: translation } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisiblePass, setIsModalVisiblePass] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [namePage, setNamePage] = useState('');
  const { setOpen, isOpen, history } = props;

  const member = useSelector((state) => (state.member ? state.member : {}));
  const stateGlobal = useSelector((state) => state);
  const [titleTotal, setTitleTotal] = useState('');
  const dispatch = useDispatch();
  const [rootPaths, setRootPath] = useState({});
  const [total, setTotal] = useState('');

  useEffect(() => {
    const dataDetail = encryptStorage.getItem(LOCAL_STORAGE.data);
    if (!dataDetail) return;

    const dataDetailJSON = dataDetail;
    setDataDetail(dataDetailJSON);
    // detect url
    const { name, title, rootPath } = renderPageName(keyName);
    setNamePage(name);
    setTitleTotal(title);
    setRootPath(rootPath);
  }, [member]);

  const menuItems = [
    {
      label: (
        <div
          onClick={() => {
            setIsModalVisible(true);
          }}
          className="login__forget"
        >
          {translation('headerMenu.profile')}
        </div>
      ),
      onClick: () => setIsModalVisible(true),
      key: 'p-1'
    },
    {
      label: (
        <div
          // onClick={() => {
          //   setIsModalVisiblePass(true);
          // }}
          className="login__forget"
        >
          {translation('headerMenu.changePassword')}
        </div>
      ),
      onClick: () => setIsModalVisiblePass(true),
      key: 'p-2'
    },
    {
      label: (
        <div
          className="login__forget"
        >
          {translation('headerMenu.logout')}
        </div>
      ),
      onClick: () => logout(),
      key: 'p-3'
    }
  ];

  const logout = () => {
    SwalConfirmAction(translation('areYouSure'), () => {
      dispatch(showLoading());
      clearStorage();
      window.location.replace(routers?.login?.path);
    });
  };

  async function handleUpdateLabeler(data, fnCallBack) {
    const formData = new FormData();
    if (data.avatar) {
      formData.append('avatar', data.avatar);
    }
    formData.append('fullname', data.fullname);
    formData.append('phone', data.phone || '');
    formData.append('email', data.email);

    const response = await AccountService.accountUpdate(formData);
    dispatch(
      handleUpdateDetail({
        ...dataDetail,
        ...response.data
      })
    );
    if (typeof fnCallBack === 'function') fnCallBack();
    notificationUpdateForm({ isSuccess: true });
  }

  async function handleResetPassword(data, fnCallBack) {
    await AccountService.changePassword(data);
    notificationUpdateForm({ isSuccess: true });
    if (typeof fnCallBack === 'function') fnCallBack();
  }

  const listLanguage = [
    {
      value: 'vi',
      name: translation('login.langVi')
    },
    {
      value: 'en',
      name: translation('login.langEng')
    },
    {
      value: 'kr',
      name: translation('login.langKr')
    }
  ];
  useEffect(() => {
    const currentRoute = Object.values(routesRoot)?.find(o => o?.path === window.location.pathname);
    const currentState = currentRoute?.state;
    const currentNamePage = currentRoute?.title;
    setNamePage(currentNamePage);
    const totalCount = get(stateGlobal, [currentState, 'totalCount'], 0);
    setTotal(totalCount);
  }, [stateGlobal]);

  return (
    <>
      <HeaderAntd className="headerHome header w-full">
        <LoadingBar
          updateTime={100}
          maxProgress={100}
          progressIncrease={10}
          style={{
            backgroundColor: '#73F9BC',
            height: '3px',
            position: 'absolute',
            bottom: '-1px'
          }}
        />

        <div className="flex items-center headerHome__logoText">
          {theme === LIGHT ? (
            <LogoNText
              onClick={() => {
                history.push(process.env.REACT_APP_API_ROOT || '/');
              }}
              className="headerHome__logo cursor-pointer"
            />
          ) : (
            <LogoNTextDark
              onClick={() => {
                history.push(process.env.REACT_APP_API_ROOT || '/');
              }}
              className="headerHome__logo cursor-pointer"
            />
          )}
          <Hamburger
            toggled={isOpen}
            size={20}
            className={'headerHome__hambergur'}
            toggle={(value) => {
              setOpen(value);
            }}
          />
        </div>

        <div className="headerHome__right items-center  w-full">
          <div className="headerHome__right-breadcrumb">
            <Breadcrumb className="mb-3">
              {!_.isEmpty(rootPaths?.titleHeader) &&
                rootPaths.titleHeader.map((path, idx) => (
                  <Breadcrumb.Item key={uniqueId()}>
                    {rootPaths.urlHeader[idx] !== '#' ? (
                      <a href={rootPaths.urlHeader[idx]} className="link">
                        {' '}
                        {get(path, language, '')}
                      </a>
                    ) : (
                      <a className="link not-link"> {get(path, language, '')}</a>
                    )}
                  </Breadcrumb.Item>
                ))}

              <Breadcrumb.Item>{get(namePage, language, '')}</Breadcrumb.Item>
            </Breadcrumb>
            {titleTotal && (
              <div className="headerHome__right-total">
                <p className="title">{`${translation(titleTotal)} `}: </p>
                <p className="number">{formatToPrice(total)}</p>
              </div>
            )}
          </div>

          <div className="headerHome__right_info flex items-center">
            {!isEmpty(theme) && (
              <div
                className={classNames(
                  'box-theme cursor-pointer mr-4 flex items-center',
                  theme
                )}
                onClick={toggleTheme}
              >
                {theme !== DARK ? <IconDark /> : <IconLight />}
                <div className="box-theme__title ml-1">
                  {theme === DARK ? translation(DARK) : translation(LIGHT)}
                </div>
              </div>
            )}
            <SelectCustom
              handleChange={toggleLanguage}
              fullWidth={false}
              className="login__select headerHome__select sm-hidden"
              value={language}
              data={listLanguage}
              size="small"
            />

            <div className="flex items-center">
              <div className="headerHome__group-info">
                <p className="headerHome__name">{dataDetail?.fullname}</p>
                <p className="headerHome__role-title headerHome__country">{dataDetail?.roleTitle}</p>
              </div>
              <Dropdown className="headerHome__avatar sm-hidden"
                menu={{ items: menuItems }}
              // overlay={menu}
              >
                {dataDetail.avatar ? (
                  <div className="text-dark flex items-center avatar">
                    <div style={dataDetail.avatar ? {} : { backgroundColor: '#26d7ff' }}>
                      <img
                        className="headerHome__avatar__img"
                        src={dataDetail.avatar}
                        alt="avatar"
                        height={32}
                        width={32}
                      />
                    </div>
                    <div className="text-dark">
                      <IconArrowDown />
                    </div>
                  </div>
                ) : (
                  <IconArrowDown />
                )}
              </Dropdown>
            </div>
          </div>
        </div>
      </HeaderAntd>
      {isModalVisible ? (
        <ModalDetailProfile
          handleUpdateLabeler={handleUpdateLabeler}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          data={dataDetail}
          setDataDetail={setDataDetail}
        />
      ) : null}
      <ModalPasswordProfile
        handleResetPassword={handleResetPassword}
        isModalVisible={isModalVisiblePass}
        setIsModalVisible={setIsModalVisiblePass}
        data={dataDetail}
      />
    </>
  );
}
