import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.cityGetList, params);
};
export const GetDist = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.cityGetDist, params);
};

export const GetWard = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.cityGetWard, params);
};


