import { createAsyncThunk } from '@reduxjs/toolkit';

import { MemberMgmtConstants } from 'redux/constant';
import { MemberMgmtService } from 'services/index';
export const GetList = createAsyncThunk(MemberMgmtConstants.GetList, async (query) => {
  let result = {};
  await MemberMgmtService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const Export = createAsyncThunk(MemberMgmtConstants.Export, async (query) => {
  let result = {};
  await MemberMgmtService.Export(query).then(() => {
    const isSuccess = true;
    result = { isSuccess };
  });
  return result;
});

export const ExportByType = createAsyncThunk(MemberMgmtConstants.ExportByType, async (query) => {
  let result = {};
  await MemberMgmtService.ExportByType(query).then(() => {
    const isSuccess = true;
    result = { isSuccess };
  });
  return result;
});

export const GetListPointHistory = createAsyncThunk(MemberMgmtConstants.GetListPointHis, async (query) => {
  let result = {};
  await MemberMgmtService.GetListPointHis(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const Update = createAsyncThunk(MemberMgmtConstants.Update, async (query) => {
  let result = {};
  const { id, data } = query;
  await MemberMgmtService.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});


export const ApproveSeller = createAsyncThunk(MemberMgmtConstants.ApproveSeller, async (payload) => {
  let result = {};
  await MemberMgmtService.ApproveSeller(payload).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const DenySeller = createAsyncThunk(MemberMgmtConstants.DenySeller, async (payload) => {
  let result = {};
  await MemberMgmtService.DenySeller(payload).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});


