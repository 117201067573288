import { createSlice } from '@reduxjs/toolkit';
import { isArray } from 'lodash';

import {
  AttributeAction
} from 'redux/actions';

export const attributeSlice = createSlice({
  name: 'attribute',
  initialState: {
    listAttributeData: [],
    totalCount: 0,
    isSuccess: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(AttributeAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(AttributeAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.isSuccess = isSuccess;
      if (isArray(data)) {
        state.totalCount = data?.length;
        state.listAttributeData = data;
      } else {
        state.listAttributeData = data;
      }
    });
  }
});


export default attributeSlice.reducer;