import { useEffect } from 'react';

import { clearStorage } from 'common/shares';
import { renderPath } from 'routes';

const ClearStorage = () => {
  useEffect(() => {
    clearStorage();
    window.location.replace(renderPath('login'));
  }, []);
  return <div></div>;
};

export default ClearStorage;
