import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

import {
  FAQAction
} from 'redux/actions';

export const faqSlice = createSlice({
  name: 'faq',
  initialState: {
    listData: [],
    listDataTag: [],
    isSuccess: false,
    isSuccessCreateTag: false,
    totalCount: 0,
    message: '',
    createId: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(FAQAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
      state.isSuccessCreateTag = false;
      state.createId = null;
    });
    builder.addCase(FAQAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { listData, totalCount } = data;
      state.listData = listData;
      state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(FAQAction.GetListTag.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(FAQAction.GetListTag.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.listDataTag = data;
      state.isSuccess = isSuccess;
    });
    builder.addCase(FAQAction.Update.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(FAQAction.Create.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(FAQAction.CreateTag.fulfilled, (state, action) => {
      const { isSuccess, data } = action.payload;
      state.message = '';
      state.listDataTag = [...state.listDataTag, data];
      state.isSuccess = isSuccess;
      state.isSuccessCreateTag = true;
      state.createId = get(data, '_id', null);
    });
    builder.addCase(FAQAction.Delete.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(FAQAction.DeleteTag.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});

export default faqSlice.reducer;
