import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

const ActionHeader = (props) => {
  const { setModal, isCanAdd } = props;
  const { t: translation } = useTranslation();
  return (
    <>
      <div className="action-header">
        {isCanAdd && (
          <Button
            onClick={() => setModal(preModal => ({
              ...preModal,
              title: translation('addNew'),
              data: {
                navigateAppParam: [{key: '', value: ''}],
                status: true,
                sendOneTimes: false,
              },
              open: true,
            }))}
            className="ml-0 mb-3 button-submit"
            type="primary"
          >
            {translation('addNew')}
          </Button>
        )}
      </div>
    </>
  );
};

export default ActionHeader;
