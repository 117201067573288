import { LOCAL_STORAGE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import { CHANGE_ENUM } from 'redux/constant';

let initialState = {};
// const data = encryptStorage.getItem(LOCAL_STORAGE.enum);
// if (data && data.length) {
//   const newData = JSON.parse(data);
//   initialState = {
//     ...initialState,
//     ...newData
//   };
// }

export default function enumReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ENUM: {
      encryptStorage.setItem(
        LOCAL_STORAGE.enum,
        JSON.stringify({
          ...state,
          ...action.data
        })
      );
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
