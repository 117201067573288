import configServices from './configService';
import Request from './request.service';
import ApiEndpoints from 'common/apiEndPoints';
export default class accountService {
  static async accountUpdate(data) {
    return configServices.putService(ApiEndpoints.userUpdateProfile, data, true, true);
   
  }

  static async changePassword(data) {
    return configServices.putService(ApiEndpoints.userChangePassword, data, true, false);
  }

  static async logout(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'auth/logout',
        data
      }).then((result = {}) => {
        const { code, data, errors } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message: errors });
        }
      });
    });
  }
}
