import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProductConstants } from 'redux/constant';
import { ProductServices } from 'services/index';
export const GetList = createAsyncThunk(ProductConstants.GetList, async (query) => {
  let result = {};
  await ProductServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
export const Update = createAsyncThunk(ProductConstants.Update, async (query) => {
  let result = {};
  const { id, data } = query;
  await ProductServices.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const UpdateStatus = createAsyncThunk(ProductConstants.UpdateStatus, async (query) => {
  let result = {};
  await ProductServices.UpdateStatus(query).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});