import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.mediaGetList, params);
};

export const Delete = async (id) => {
  return await configServices.deleteService(`${ApiEndpoints.mediaDelete}/${id}`);
};

export const Upload = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.mediaUpload, data, true, true);
};