import { Select } from 'antd';
import classNames from 'classnames';
import _, { isEmpty, uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import { IconArrowDown, IconSearch } from 'assets/icons';

import './style.scss';

function SelectCustom(props) {
  const { Option, OptGroup } = Select;
  const {
    suffixIcon,
    mode,
    disableIcon = false,
    children,
    handleChange,
    data = [],
    className,
    name,
    disableFilterOption = false,
    isBorderYellow = false,
    fullWidth = true,
    showSearch = false,
    filterOption,
    showIcon,
    view,
    placeholder,
    value = null,
    defaultValue,
    defaultValues,
    maxTagCount,
    showArrow,
    onChange,
    allowClear = false,
    disabled,
    loading,
    ...otherProps
  } = props;
  return (
    <Select
      disabled={disabled}
      suffixIcon={
        loading ? undefined: 
        showIcon ? (
          suffixIcon
        ) : showSearch ? (
          <IconSearch className="icon-select" />
        ) : (
          <IconArrowDown className="icon-select" />
        )
      }
      value={mode === 'multiple' ? value || [] : value}
      onChange={(value) => {
        if (handleChange) {
          if (name) {
            handleChange(name, value);
          } else {
            handleChange(value);
          }
        } else {
          onChange && onChange(value);
        }
      }}
      name={name}
      defaultValue={defaultValues ?? defaultValue}
      mode={mode} //multiple
      placeholder={placeholder}
      showArrow={allowClear || (showArrow ?? !!showSearch)}
      maxTagCount={maxTagCount || 3}
      allowClear={allowClear}
      showSearch={showSearch}
      loading={loading}
      className={classNames(
        className,
        'select-custom',
        { 'w-full': fullWidth },
        { 'disableIcon': disableIcon },
        // { 'border-right': isBorderRight },
        { 'border-yellow': isBorderYellow }
      )}
      filterOption={
        filterOption ||
        ((input, option) => {
          if (disableFilterOption) return false;
          return (
            option.children?.toString()?.toLowerCase()?.indexOf(input?.toString().toLowerCase()) >=
            0 ||
            option.value?.toString()?.toLowerCase()?.indexOf(input?.toString().toLowerCase()) >= 0
          );
        })
      }
      {...otherProps}
    >
      {
        children
          ? children
          : defaultValue && (
            <Option key={uniqueId()} value="">
              {defaultValue}
            </Option>
          )
      }
      {
        view === 'tree'
          ? data?.map((parentItem) => (
            <OptGroup className="text-bold" key={uniqueId()} label={parentItem?.label}>
              {!isEmpty(parentItem?.child) &&
                parentItem?.child?.map((subItem) => (
                  <Option key={uniqueId()} value={subItem?.value}>
                    {subItem?.label}
                  </Option>
                ))}
            </OptGroup>
          ))
          : !_.isEmpty(data) &&
          data?.map((item) => {
            return (
              <Option
                disabled={item?.disabled}
                key={item.key || item.value || uniqueId()}
                value={item.value}
              >
                {item.name || item.label}
              </Option>
            );
          })
      }
    </Select >
  );
}

SelectCustom.propTypes = {
  suffixIcon: PropTypes.any,
  // fullWidth: propTypes.bool,
  mode: PropTypes.any,
  // filterOption: propTypes.func,
  // disableIcon: propTypes.bool,
  handleChange: PropTypes.func,
  className: PropTypes.any,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  data: PropTypes.any,
  showArrow: PropTypes.bool,
  showSearch: PropTypes.bool,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

SelectCustom.defaultProps = {
  // suffixIcon: null,
  // mode: '',
  // className: '',
  // placeholder: '',
  // name: '',
  // value: '',
};

export default SelectCustom;
