import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Form, Input, Switch, Tooltip, Upload } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { get, isEmpty, map, range, toNumber } from 'lodash';
import moment from 'moment';

import { IconCloseCircle, IconImagePreview, IconPlusCircle } from 'assets/icons';
import { commonGetEnum } from 'common/shares';
import Editor from 'components/Editor';
import Modal from 'components/Modal';
import SelectCustom from 'components/SelectCustom';
import { AppNavigateAction } from 'redux/actions';
import { NotificationGroupService } from 'services/index';

function ModalNotification(props) {
  const {
    modal, setModal,
    updateModal,
  } = props;

  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();
  const [imageUrl, setImageUrl] = useState();
  const [listNotificationGroup, setListNotificationGroup] = useState([]);

  const hour = Form.useWatch(['time', 'hour'], form);
  const minute = Form.useWatch(['time', 'minute'],form);
  const {
    listData: listAppNavigateState,
  } = useSelector(state => state.appNavigate);

  const {
    listNotificationMainAccountType,
    listNotificationMainStatus,
    listNotificationMainSendOneTimes,
    listNotificationMainGender,
    commonDayOfTheWeek
  } = commonGetEnum();

  const listAppNavigate = map(listAppNavigateState, o => ({
    value: get(o, '_id', null),
    label: get(o, 'title', ''),
  }));

  const uploadButton = (
    <div>
      <IconImagePreview />
      <div
        className='mt-2'
      >
        Upload
      </div>
    </div>
  );

  useEffect(() => {
    if (!modal.open) {
      setImageUrl(null);
    } else {
      const image = get(modal, ['data', 'image', 'thumb', 'url'], null);
      setImageUrl(image);
      dispatch(AppNavigateAction.GetList());

      const fetchNotificationGroupById = async (query) => {
        return await NotificationGroupService.GetList(query).then((res) => {
          const { code, data } = res;
          const isSuccess = code === 200;
          if (isSuccess) {
            const { listData } = data;
            setListNotificationGroup(map(listData, item => ({
              label: item.name,
              value: item._id
            })));
          }
        });
      };

      fetchNotificationGroupById({
        orderBy: 'name',
        sortedBy: 'asc',
        limit: 1000,
        page: 1,
      });
    }
  }, [modal.open]);

  const listSelectHour = map(range(24), (_, index) => ({
    value: index,
    label: index,
  }));
  const listSelectMinute = map(range(60), (_, index) => ({
    value: index,
    label: index,
  }));
  const listSelectDateOfMonth = map(range(31), (_, index) => ({
    value: index + 1,
    label: index + 1,
  }));

  const labelActive =
    listNotificationMainStatus?.find((o) => o?.value === 'A')?.label || translation('Active');
  const labelDeActive =
    listNotificationMainStatus?.find((o) => o?.value === 'F')?.label || translation('DeActive');
  const labelRepeat =
    listNotificationMainSendOneTimes?.find((o) => o?.value === 'true')?.label;
  const labelUnRepeat =
    listNotificationMainSendOneTimes?.find((o) => o?.value === 'false')?.label;

  const onValidatorModal = values => {
    const newValues = {
      ...values,
    };

    const navigateAppParam = get(newValues, ['navigateAppParam'], []);
    const navigateAppParamKeys = map(navigateAppParam, 'key');

    const navigateAppErrors = [];
    navigateAppParam?.forEach((navigateAppItem, navigateAppIndex) => {
      form.setFields([{
        name: ['navigateAppParam', navigateAppIndex, 'key'],
        errors: []
      }]);
      const counts = navigateAppParamKeys?.reduce((accumulator, value) => {
        accumulator[value] = ++accumulator[value] || 1;
        return accumulator;
      }, {});

      Object.entries(counts)?.forEach(([key, val]) => {
        if (val > 1 && navigateAppItem?.key === key) {
          navigateAppErrors.push({
            name: ['navigateAppParam', navigateAppIndex, 'key'],
            errors: [translation('keyCannotBeDuplicated')]
          });
          return;
        }
      });
    });
    if (!isEmpty(navigateAppErrors)) {
      form.setFields(navigateAppErrors);
      return;
    }
    setModal(preModal => ({
      ...preModal,
      loading: true
    }));
    updateModal(newValues);
  };

  return (
    <Modal
      modal={modal}
      setModal={setModal}
      onFinish={onValidatorModal}
      form={form}
      width={600}
    >
      <Form.Item name="_id" className="w-full">
        <div></div>
      </Form.Item>
      <div className="mb-3 flex items-center w-full">
        <div className="w-200">
          {translation('notificationType')} <span className="danger">*</span>
        </div>
        <Form.Item
          name={['notificationType', 'value']}
          rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
          className="w-full"
        >
          <SelectCustom
            placeholder={translation('filterBy.notificationType')}
            data={listNotificationMainAccountType}
          />
        </Form.Item>
      </div>
      <div className="mb-3 flex items-center w-full">
        <div className="w-200">
          {translation('notificationGroup')} <span className="danger">*</span>
        </div>
        <Form.Item
          name={['notificationGroupId']}
          rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
          className="w-full"
        >
          <SelectCustom
            placeholder={translation('filterBy.notificationGroup')}
            data={listNotificationGroup}
          />
        </Form.Item>
      </div>
      <div className="mb-3 flex items-center w-full">
        <div className="w-200">
          {translation('schedule')} <span className="danger">*</span>
        </div>
        <div className="grid grid-cols-2 gap-2 w-full">
          <Form.Item
            name={['time', 'dayOfWeek']}
            className='w-full'
          >
            <SelectCustom
              placeholder={translation('dateOfTheWeek')}
              data={map(commonDayOfTheWeek, item => ({...item, value: toNumber(item.value)}))}
              allowClear
              className={'min-w-[144px]'}
              showArrow
            />
          </Form.Item>
          <Form.Item
            name={['time', 'dayOfMonth']}
            className='w-full'
          >
            <SelectCustom
              placeholder={translation('dateOfTheMonth')}
              data={listSelectDateOfMonth}
              allowClear
              className={'min-w-[144px]'}
              showArrow
            />
          </Form.Item>
          <Form.Item
            name={['time', 'hour']}
            rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
            className='w-full'
          >
            <SelectCustom
              placeholder={translation('hour')}
              data={listSelectHour}
              allowClear
              className={'min-w-[94px]'}
              showArrow
            />
          </Form.Item>
          <Form.Item
            name={['time', 'minute']}
            rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
            className='w-full'
          >
            <SelectCustom
              placeholder={translation('minute')}
              data={listSelectMinute}
              allowClear
              className={'min-w-[94px]'}
              showArrow
            />
          </Form.Item>
        </div>
      </div>
      <div className='flex items-center w-full'>
        <div className="w-200"></div>
        <div className="w-full">
          {(() => {
            const timezone = 0;
            const time = moment().set({ hour: hour + timezone, minute }).format('HH:mm');
            const time7 = moment().set({ hour: hour + timezone + 7, minute }).format('HH:mm');
            const time9 = moment().set({ hour: hour + timezone + 9, minute }).format('HH:mm');
            return <div className='flex gap-2 mb-1'>
                <div>{`${time} (UTC${timezone > 0 ? `+${timezone}` : ''})`}</div>
                <div>|</div>
                <div>{`${time7} (UTC+7)`}</div>
                <div>|</div>
                <div>{`${time9} (UTC+9)`}</div>
              </div>;
          })()}
          <Form.Item
            name="sendOneTimes"
            className="w-full"
            valuePropName="checked"
          >
            <Switch checkedChildren={labelRepeat} unCheckedChildren={labelUnRepeat} />
          </Form.Item>
        </div>
      </div>

      <Divider />

      <div className="mb-3 flex items-center w-full">
        <div className="w-200">{translation('title')} <span className="danger">*</span></div>
        <Form.Item
          name={'title'}
          rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
          className="w-full"
        >
          <Input />
        </Form.Item>
      </div>
      <div className="mb-3 flex items-center w-full">
        <div className="w-200">{translation('summary')} <span className="danger">*</span></div>
        <Form.Item
          name="summary"
          rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
          className="w-full"
        >
          <TextArea rows={3} />
        </Form.Item>
      </div>
      <div className="mb-3 flex items-center w-full">
        <div className="w-200">
          {translation('gender')} <span className="danger">*</span>
        </div>
        <Form.Item
          name={['gender', 'value']}
          rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
          className="w-full"
        >
          <SelectCustom
            placeholder={translation('filterBy.gender')}
            data={listNotificationMainGender}
          />
        </Form.Item>
      </div>

      <div className="mb-3 flex items-center w-full">
        <div className="w-200">
          {translation('image')}
        </div>
        <Form.Item
          name="file"
          valuePropName='file'
          className="w-full"
        >
          <Upload
            name="file"
            accept="image/*"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={(info) => {
              setImageUrl(URL.createObjectURL(info));
              return false;
            }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
      </div>

      <div className="flex items-center w-full">
        <div className="w-200 mb-3">
          {translation('googleAnalytics')}
        </div>
        <div className='w-full'>
          <div className='flex items-center gap-2'>
            <div className='mb-3'>
              <Form.Item
                name={['googleAnalytics', 'eventName']}
                className="w-full"
              >
                <Input placeholder={translation('eventName')} />
              </Form.Item>
            </div>
            <div className='mb-3'>
              <Form.Item
                name={['googleAnalytics', 'eventParams']}
                className="w-full"
              >
                <Input placeholder={translation('eventParams')} />
              </Form.Item>
            </div>
            <div className='mb-3'>
              <Form.Item
                name={['googleAnalytics', 'eventValue']}
                className="w-full"
              >
                <Input placeholder={translation('eventValue')} />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        <div className="w-200 mb-3">
          {translation('adjust')}
        </div>
        <div className='w-full'>
          <div className='flex items-center gap-2'>
            <div className='mb-3 w-full'>
              <Form.Item
                name={['adjust', 'eventName']}
                className="w-full"
              >
                <Input placeholder={translation('eventName')} />
              </Form.Item>
            </div>
            <div className='mb-3 w-full'>
              <Form.Item
                name={['adjust', 'eventToken']}
                className="w-full"
              >
                <Input placeholder={translation('eventToken')} />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3 flex items-center w-full">
        <div className="w-200">{translation('appPage')}</div>
        <Form.Item
          name={['navigateAppPage']}
          className="w-full">
          <SelectCustom
            name='navigateAppPage'
            showArrow
            data={listAppNavigate}
            placeholder={translation('filterBy.appPage')} />
        </Form.Item>
      </div>
      <div className="mb-3 flex items-start w-full">
        <div className="w-200">{translation('appPageParams')}</div>
        <div className='w-full flex-col'>
          <Form.List
            name={'navigateAppParam'}
          >
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, idx, name, ...restField) => (
                    <div key={idx} className="mb-3 flex items-start w-full">
                      <div>
                        <Form.Item
                          name={[field.name, 'key']}
                          {...restField}
                          className="w-full overflow-x-hidden"
                        >
                          <Input placeholder={translation('key')} className='w-[100px]' />
                        </Form.Item>
                      </div>
                      <Form.Item
                        name={[field.name, 'value']}
                        {...restField}
                        className="w-full overflow-x-hidden ml-1"
                      >
                        <Input placeholder={translation('value')} />
                      </Form.Item>
                      <div className="w-[94px] ml-2 flex items-center h-[36px]">
                        <Tooltip placement="top" title={translation('clickToRemoveItem')}>
                          <IconCloseCircle className='icon-close cursor-pointer text-[#FFA1A1]' onClick={() => remove(idx)} />
                        </Tooltip>
                        {idx == fields.length - 1 && (
                          <Tooltip placement="top" title={translation('clickToaddItem')}>
                            <IconPlusCircle className='ml-2 cursor-pointer' onClick={() => add()} />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </div>
      </div>

      <div className="mb-3 flex items-center w-full">
        <div className="w-200">{translation('status')}</div>
        <div className="flex  w-full">
          <Form.Item
            name="status"
            className="w-full detailQc__form__input"
            valuePropName="checked"
          >
            <Switch checkedChildren={labelActive} unCheckedChildren={labelDeActive} />
          </Form.Item>
        </div>
      </div>
      <div className="mb-3 w-full">
        <div className="w-200 mb-2">
          {translation('notificationContent')} <span className="danger">*</span>
        </div>
        <Form.Item
          name="content"
          rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
          valuePropName='data'
          className="w-full"
        >
          <Editor
            _id={get(modal, 'data._id')}
            type='content'
          />
        </Form.Item>
      </div>
    </Modal >
  );
}
export default ModalNotification;

