import { createSlice } from '@reduxjs/toolkit';

import {
  MemberLevelAction
} from 'redux/actions';

export const memberLevelSlice = createSlice({
  name: 'memberLevel',
  initialState: {
    listDataLite: [],
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(MemberLevelAction.GetListLite.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(MemberLevelAction.GetListLite.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData, totalCount} = data;
      state.listDataLite = listData;
      state.isSuccess = isSuccess;
      state.totalCount = totalCount;
    });
  }
});

export default memberLevelSlice.reducer;
