import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MobileConfigService } from 'services/index';

const initialState = { 
   filter: {}, 
   data: {}, 
   modal: {
   isOpen: false,
   dataEdit: {}
   }
};

export const setMobileConfigFilterAction = createAsyncThunk('mobileconfig/setFilter', async (query: any, {getState}) => {
   const state: any = getState();
   const response = await MobileConfigService.GetList({...state.mobileConfig.filter, ...query});
   return {data: response.data, filter: query};
});


const mobileConfigSlice = createSlice({
   name: 'mobileConfig',
   initialState: initialState,
   reducers: {
      toggleModalMobileConfigAction(state) {
         state.modal.isOpen = !state.modal.isOpen;
         state.modal.dataEdit = {};
      },
      editMobileConfigAction(state, action) {
         state.modal.isOpen = true;
         state.modal.dataEdit = action.payload;
      },
   },
   extraReducers(builder) {
      builder.addCase(setMobileConfigFilterAction.fulfilled, (state, action) => {
         const {data, filter} = action.payload;
         state.data = data;
         state.filter = {...state.filter, ...filter};
      });
   },
});

export const { toggleModalMobileConfigAction, editMobileConfigAction } = mobileConfigSlice.actions;

export default mobileConfigSlice.reducer;
