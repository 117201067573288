import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenModal: false,
  loading: false,
};

export const globalSlice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    openModal(state) {
      state.isOpenModal = true;
    },
    closeModal(state) {
      state.isOpenModal = false;
    },
    
  }
});

export default globalSlice.reducer;
