import { replace } from 'lodash';

import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.magazineGetList, params);
};

export const GetDetail = async (id, params = {}) => {
  return await configServices.getService(replace(ApiEndpoints.magazineGetDetail, ':id', id), params);
};

export const Upload = async (id, data) => {
  return await configServices.postService(replace(ApiEndpoints.magazineUpload, ':id', id), data, true, true);
};

export const Create = async (data) => {
  return await configServices.postService(ApiEndpoints.magazineCreate, data);
};

export const Update = async (id, data) => {
  return await configServices.putService(replace(ApiEndpoints.magazineUpdate, ':id', id), data);
};

export const CreateSection = async (id, data) => {
  return await configServices.postService(replace(ApiEndpoints.magazineSectionCreate, ':id', id), data);
};

export const UpdateSectionAll = async (id, data) => {
  return await configServices.putService(replace(ApiEndpoints.magazineSectionUpdateAll, ':id', id), data);
};

export const UpdateSection = async (id, sectionId, data) => {
  return await configServices.putService(`${replace(ApiEndpoints.magazineSectionUpdate, ':id', id)}/${sectionId}`, data);
};

export const Delete = async (id) => {
  return await configServices.deleteService(replace(ApiEndpoints.magazineDelete, ':id', id));
};

export const DeleteSection = async (id, sectionId) => {
  return await configServices.deleteService(`${replace(ApiEndpoints.magazineSectionDelete, ':id', id)}/${sectionId}`);
};

