function Loader() {
  return (
    <div>
      <div className="loader-con">
        <span>
          <div id="bowlG">
            <div id="bowl_ringG">
              <div className="ball_holderG">
                <div className="ballG" />
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}
export default Loader;
