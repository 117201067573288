import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { find, get, map, toNumber } from 'lodash';
import moment from 'moment';

import { IconSend } from 'assets/icons';
import { commonGetEnum, formatToPrice, getLanguage, SwalConfirmAction } from 'common/shares';
import Table from 'components/Table';
import { renderCell } from 'components/Table/Cell';
import { NotificationAction } from 'redux/actions';

function TableBody(props) {
  const {
    data,
    totalCount,
    loading,
    setFilters,
    setModal,
  } = props;
  const dataSource = map(data, (item, index) => ({ ...item, key: index }));

  const {
    listNotificationMainSendOneTimes,
    commonDayOfTheWeek
  } = commonGetEnum();

  const { t: translation } = useTranslation();
  const language = getLanguage();
  const dispatch = useDispatch();

  const handleDeleteAction = (result) => {
    SwalConfirmAction('areYouSure', () => {
      dispatch(NotificationAction.Delete(result));
    });
  };

  const columns = [
    renderCell({ header: 'index' }),
    renderCell({ header: 'image' }),
    renderCell({
      dataIndex: 'notificationGroup',
      title: translation('titleAndGroup'),
      width: 300,
      render: (value, record) => {
        const title = get(record, 'title', '-');
        const memberType = get(record, 'notificationGroup.memberType', '-');
        const infoType = get(record, ['notificationGroup', 'info'], '-');
        return (<div>
          <div>{title}</div>
          <div className="flex gap-1">
            <IconSend className='w-4 h-4 text-main-1' />
            {`[${memberType}] ${infoType}`}
          </div>
        </div>);
      }
    }),
    renderCell({
      title: translation('notificationInfo'),
      dataIndex: 'noticeType',
      render: (_, record) => {
        const noticeType = get(record, ['notificationType', 'title', language]);
        const gender = get(record, ['gender', 'title', language]);
        return <div>
          <div className='flex items-center'>
            <div className='min-w-[60px]'>
              {translation('type')}:
            </div>
            {noticeType}
          </div>
          <div className='flex items-center'>
            <div className='min-w-[60px]'>
              {translation('gender')}:
            </div>
            {gender}
          </div>
        </div>;
      }
    }),
    renderCell({
      title: translation('schedule'),
      dataIndex: 'time',
      render: (value, record) => {
        const sendOneTimes = get(record, 'sendOneTimes') ? 'false': 'true';
        const dayOfWeek = find(commonDayOfTheWeek, item => toNumber(item.value) === get(value, 'dayOfWeek'))?.label;
        const dayOfMonth = get(value, 'dayOfMonth');

        const timezone = get(value, 'timezone', 0);
        const hour = get(value, 'hour');
        const minute = get(value, 'minute');
        const time = moment().set({ hour: hour + timezone, minute }).format('HH:mm');
        const time7 = moment().set({ hour: hour + timezone + 7, minute }).format('HH:mm');
        const time9 = moment().set({ hour: hour + timezone + 9, minute }).format('HH:mm');

        return <div>
          {sendOneTimes && <div>
            {find(listNotificationMainSendOneTimes, item => item.value === sendOneTimes)?.label}
          </div>}
          {dayOfWeek && <div className='flex items-center'>
            <div>{translation('dateOfTheWeek')}: {dayOfWeek}</div>
          </div>}
          {dayOfMonth && <div className='flex items-center'>
            <div>{translation('dateOfTheMonth')}: {dayOfMonth}</div>
          </div>}
            <div className="flex gap-1">
              <div>
                {`${translation('time')}:`}
              </div>
              <div>
                <div>{`${time} (UTC${timezone > 0 ? `+${timezone}` : ''})`}</div>
                <div>{`${time7} (UTC+7)`}</div>
                <div>{`${time9} (UTC+9)`}</div>
              </div>
            </div>

        </div>;
      }
    }),
    renderCell({
      dataIndex: 'quicklyStatistic',
      title: translation('quicklyStatistic'),
      render: (_, record) => {
        const pushed = formatToPrice(get(record, 'pushedCnt'));
        const viewed = formatToPrice(get(record, 'viewedCnt'));
        return (<div>
          <div className="flex items-center">
            <div className='min-w-[60px]'>
              {translation('pushed')}:
            </div>
            {pushed}
          </div>
          <div className="flex items-center">
            <div className='min-w-[60px]'>
              {translation('viewed')}:
            </div>
            {viewed}
          </div>
        </div>);
      }
    }),
    renderCell({
      title: translation('pushStatus'),
      dataIndex: 'pushStatus',
      render: (value) => get(value, ['title', language], '-')
    }),
    renderCell({
      header: 'status'
    }),
    renderCell({
      header: 'createAndUpdate'
    }),
    renderCell({
      header: 'action',
      actions: [
        {
          key: 'edit',
          onClick: (_, record) => {
            setModal(preModal => ({
              ...preModal,
              title: translation('edit'),
              data: {
                ...record,
                status: get(record, 'status.value') === 'A' ? true : false,
                sendOneTimes: !record?.sendOneTimes,
                navigateAppParam: record.navigateAppParam ?
                  map(Object.entries(JSON.parse(record.navigateAppParam)),
                    ([key, value]) => ({ key, value })) : [{ key: '', value: '' }]
              },
              open: true
            }));
          }
        },
        {
          key: 'delete',
          onClick: (_, record) => {
            handleDeleteAction(record?._id);
          }
        }
      ]
    }),
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      setFilters={setFilters}
      total={totalCount}
      key={'_id'}
      loading={loading}
    />
  );
}
export default TableBody;
