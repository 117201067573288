import { createAsyncThunk } from '@reduxjs/toolkit';

import { MemberLevelConstants } from 'redux/constant';
import { MemberLevelService } from 'services/index';

export const GetListLite = createAsyncThunk(MemberLevelConstants.GetListLite, async (query) => {
  let result = {};
  await MemberLevelService.GetListLite(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
