import { createAsyncThunk } from '@reduxjs/toolkit';

import { AttributeCatalogConstants } from 'redux/constant';
import { AttributeCatalogServices } from 'services/index';

export const GetList = createAsyncThunk(AttributeCatalogConstants.GetList, async (query) => {
  let result = {};
  await AttributeCatalogServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
export const Create = createAsyncThunk(AttributeCatalogConstants.Create, async (query) => {
  let result = {};
  await AttributeCatalogServices.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
export const UpdateArrange = createAsyncThunk(AttributeCatalogConstants.UpdateArrange, async (query) => {
  let result = {};
  await AttributeCatalogServices.UpdateArrange(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
export const Update = createAsyncThunk(AttributeCatalogConstants.Update, async (query) => {
  let result = {};
  await AttributeCatalogServices.Update(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});