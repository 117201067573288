import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

const useLoading = (loadingDefault = true) => {
  const [loading, setLoading] = useState(loadingDefault);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading) {
      dispatch(showLoading());
    } else {
      dispatch(hideLoading());
    }
  }, [loading, dispatch]);

  return [loading, setLoading];
};

export default useLoading;
