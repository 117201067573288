import { replace } from 'lodash';

import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.notificationGroupGetList, params);
};
export const Update = async (id, data) => {
  return await configServices.putService(
    replace(ApiEndpoints.notificationGroupUpdate, ':id', id), data);
};
export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.notificationGroupCreate, data);
};
export const Delete = async (id) => {
  return await configServices.deleteService(`${ApiEndpoints.notificationGroupDelete}/${id}`);
};