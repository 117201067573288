import { CopyRight, LogoLogin, TextLogin } from 'assets/icons';

function WrapperLogin(props) {
  const { children } = props;
  return (
    <>
      <LogoLogin className="login__logoLogin" />
      <TextLogin className="login__textImageLogin" />
      <div className="flex items-center justify-center login__container flex-col">
        {children || null}
        <img src={require('assets/img/groupPeople.png')} alt="img"></img>
      </div>
      <CopyRight className="login__logoCopyRight" />
    </>
  );
}
export default WrapperLogin;
