import { createSlice } from '@reduxjs/toolkit';

import {
  AttributeCatalogAction
} from 'redux/actions';

export const attributeCatalogSlice = createSlice({
  name: 'attributeCatalog',
  initialState: {
    listData: [],
    detail: {},
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(AttributeCatalogAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(AttributeCatalogAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
        const { listData, totalCount } = data;
        state.listData = listData;
        state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(AttributeCatalogAction.Create.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(AttributeCatalogAction.UpdateArrange.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(AttributeCatalogAction.Update.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});


export default attributeCatalogSlice.reducer;