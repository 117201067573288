import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useHistory, useLocation } from 'react-router-dom';
import { Divider, Menu } from 'antd';
import cn from 'classnames';
import { get, isEmpty } from 'lodash';

import { routesRoot } from 'App';
import { IconDark, IconLight } from 'assets/icons';
import { DARK, LIGHT, LOCAL_STORAGE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import useTheme from 'common/hook/useTheme';
import { getLanguage } from 'common/shares';
import { routerSetting } from 'routes';

const MenuComponent = (props) => {
  const { keyName } = props;
  const dataUser = encryptStorage.getItem(LOCAL_STORAGE.data);
  const permission = get(dataUser, 'permission', []);
  const language = getLanguage();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState('');
  const { theme, toggle: toggleTheme } = useTheme();
  useEffect(() => {
    const showMenu = get(routesRoot, [keyName, 'show_on_menu'], 'F') === 'T';
    const showParentMenu = get(routesRoot, [keyName, 'showParentMenu'], 'F') === 'T';
    if (!showMenu && !showParentMenu) {
      setOpenKeys([]);
      setSelectedKeys('');
      return;
    }
    if (!isEmpty(routesRoot)) {
      Object.keys(routesRoot).map((o) => {
        const { keyPaths, showParentMenu, path } = routesRoot[o];
        let checkPath = pathname.includes(path);
        let newOpenKeys = [...keyPaths]?.reverse();

        if (showParentMenu && checkPath) {
          newOpenKeys?.pop();
          const newSelectedKeys = get(keyPaths, [1], '');
          setOpenKeys(newOpenKeys);
          setSelectedKeys(newSelectedKeys);
        } else if (checkPath) {
          const newSelectedKeys = get(keyPaths, [0], []);
          setOpenKeys(newOpenKeys);
          setSelectedKeys(newSelectedKeys);
        }
      });
    }
  }, [routesRoot]);

  function getItem(label, key, children, icon, type, path) {
    return {
      key,
      icon,
      children,
      label,
      type,
      path
    };
  }
  const { t: translation } = useTranslation();
  const convertMenu = (list = []) => {

    const listMenuFilter = !isEmpty(list)
      ? list.filter((p) => {
        const isView = get(p, 'action', []).find((o) => o === 'view');
        const isShowMenu = get(p, 'show_on_menu', 'F') === 'T';
        return isView && isShowMenu;
      })
      : [];
    return listMenuFilter.map((o) => {
      const label = get(o, ['title', language], '');
      const key = get(o, 'key', '');
      const path = get(o, 'path', '');
      const checkChild = o?.child?.filter(c => c?.show_on_menu === 'T').length
      // const children = !isEmpty(o?.child) && o?.child?.every(c => c?.show_on_menu) ? convertMenu(o.child) : undefined;
      const children = !isEmpty(o?.child) && checkChild ? convertMenu(o.child) : undefined;
      const icon = !isEmpty(o.icon) ? <SVG cacheRequests={true} src={o.icon} /> : undefined;
      return getItem(label, key, children, icon, undefined, path);
    });
  };

  const items = convertMenu([...permission]);

  items.push({
    label: (
      <div className="menu-setting">
        <Divider />
        <div
          className="menu-item flex justify-between items-center"
          onClick={() => {
            history.push(routerSetting?.path);
          }}
        >
          <div className='flex items-center'>
            {routerSetting?.icon} <div className='text-base'>{translation(routerSetting?.title)}</div>
          </div>
          <div className="number">3</div>
        </div>
        <div className="mode-theme">
          <div
            onClick={toggleTheme}
            className={cn('flex cursor-pointer', { active: theme === LIGHT })}
          >
            <IconDark />
            <span>{translation('light')}</span>
          </div>
          <div
            onClick={toggleTheme}
            className={cn('flex cursor-pointer', { active: theme === DARK })}
          >
            <IconLight />
            {translation('dark')}
          </div>
        </div>
      </div>
    ),
    key: routerSetting?.path
  });
  const onOpenChange = (keys = []) => {
    const latestOpenKey = keys.find((key) => openKeys?.indexOf(key) === -1);
    if (!isEmpty(openKeys) && openKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onSelect = (propsMenu) => {
    const { key } = propsMenu;
    if (routesRoot[key]?.path) {
      history.push(routesRoot[key]?.path);
    }
  };

  return (
    <Menu
      mode="inline"
      onSelect={onSelect}
      items={items}
      onOpenChange={onOpenChange}
      selectedKeys={selectedKeys}
      openKeys={openKeys}
    />
  );
};

export default MenuComponent;
