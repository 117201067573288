import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import LayoutComponent from './components';

import './style.scss';

function LayoutPage(props) {
  const { className } = props;
  const drawer = useSelector((state) => (state.drawer ? state.drawer : {}));
  const { isOpen } = drawer;

  useEffect(() => {
    const bodyId = document.getElementById('body-root');
    bodyId.classList.remove('forgot__pass');
    if (className !== '') {
      bodyId.classList.add(className);
    } else {
      bodyId.classList.toggle('id');
    }
  }, [className]);

  return <LayoutComponent isOpen={isOpen} {...props} />;
}

const mapStateToProps = (state) => ({
  member: state.member || {}
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
