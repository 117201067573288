import { createAsyncThunk } from '@reduxjs/toolkit';

import { SellerRegisterConstants } from 'redux/constant';
import { RequestEntranceService } from 'services/index';
export const GetList = createAsyncThunk(SellerRegisterConstants.GetList, async (query) => {
  let result = {};
  await RequestEntranceService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});