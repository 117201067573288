import { LOCAL_STORAGE } from 'common/constants';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useLanguage = () => {
   const _handleGetLange = async () => {
      let lang = `${await window.localStorage.getItem(LOCAL_STORAGE.language)}`;

      if (lang === 'null') {
         lang = 'en';
      }

      document.documentElement.setAttribute('lang', lang || '');
      i18n.changeLanguage(lang);
      setLanguage(lang);
   };

   const [language, setLanguage] = useState<string>('en');
   const { i18n } = useTranslation();

   useEffect(() => {
      _handleGetLange();
   }, []);

   const toggle = useCallback(
      (newLang: string) => {
         setLanguage(newLang);
         window.localStorage.setItem(LOCAL_STORAGE.language, newLang);
         document.documentElement.setAttribute('lang', newLang || '');
         i18n.changeLanguage(newLang);
      },
      [language]
   );

   return {
      language,
      toggle
   };
};

export default useLanguage;
