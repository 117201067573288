import { createSlice } from '@reduxjs/toolkit';

import {
  FeedbackAction
} from 'redux/actions';

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    listData: [],
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(FeedbackAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(FeedbackAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData, totalCount} = data;
      state.listData = listData;
      state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(FeedbackAction.Update.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(FeedbackAction.Create.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(FeedbackAction.Delete.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});

export default feedbackSlice.reducer;
