import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.attributeAgeGetList, params);
};
export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.attributeAgeCreate, data);
};
export const UpdateArrange = async (data = {}) => {
  return await configServices.putService(ApiEndpoints.attributeAgeUpdateArrange, data);
};
export const Update = async ({ id, data }) => {
  return await configServices.putService(`${ApiEndpoints.attributeAgeUpdate}/${id}`, data);
};