import { createAsyncThunk } from '@reduxjs/toolkit';

import { NotificationConstants } from 'redux/constant';
import { NotificationService } from 'services/index';
export const GetList = createAsyncThunk(NotificationConstants.GetList, async (query) => {
  let result = {};
  await NotificationService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const Update = createAsyncThunk(NotificationConstants.Update, async (query) => {
  let result = {};
  const { id, data } = query;
  await NotificationService.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(NotificationConstants.Create, async (query) => {
  let result = {};
  await NotificationService.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const PushNotification = createAsyncThunk(
  NotificationConstants.PushNotification,
  async (query) => {
    let result = {};
    await NotificationService.PushNotification(query).then((res) => {
      const { message, code } = res;
      const isSuccess = code === 200;
      if (isSuccess) {
        result = { isSuccess, message };
      }
    });
    return result;
  }
);

export const Delete = createAsyncThunk(NotificationConstants.Delete, async (query) => {
  let result = {};
  await NotificationService.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
