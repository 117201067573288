import { createAsyncThunk } from '@reduxjs/toolkit';

import { NoticeConstants } from 'redux/constant';
import { NoticeService } from 'services/index';
export const GetList = createAsyncThunk(NoticeConstants.GetList, async (query) => {
  let result = {};
  await NoticeService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});


export const Update = createAsyncThunk(NoticeConstants.Update, async (query) => {
  let result = {};
  const {id, data} = query;
  await NoticeService.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(NoticeConstants.Create, async (query) => {
  let result = {};
  await NoticeService.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Delete = createAsyncThunk(NoticeConstants.Delete, async (query) => {
  let result = {};
  await NoticeService.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});


