import { mapValues } from 'lodash';

const listPath = {
  /* user */
  languageGetList: 'language/getList',
  languageUpdate: 'language/update',
  userLogin: 'user/login',
  userGetList: 'user/getList',
  userCreate: 'user/create',
  userUpdate: 'user/update',
  userDelete: 'user/delete',
  userUpdateProfile: 'user/updateProfile',
  userChangePassword: 'user/changePassword',
  userResetPassword: 'user/resetPass',
  /* user level */
  userLevelGetList: 'userLevel/getList',
  userLevelCreate: 'userLevel/create',
  userLevelUpdate: 'userLevel/update',
  userLevelDelete: 'userLevel/delete',
  /* event category */
  eventCategoryGetList: 'eventCategory/getList',
  eventCategoryCreate: 'eventCategory/create',
  eventCategoryUpdate: 'eventCategory/update',
  eventCategoryDelete: 'eventCategory/delete',
  /* Store */
  storeGetList: 'store/getList',
  storeGetStoreByInstagramLink: 'store/getStoreByInstagramLink',
  storeGetListLite: 'store/getListLite',
  storeCreate: 'store/create',
  storeUpdate: 'store/update',
  storeDelete: 'store/delete',
  /* member */
  memberGetList: 'member/getList',
  memberCreate: 'member/create',
  memberUpdate: 'member/update',
  memberApproveSeller: 'member/approveSeller',
  memberDenySeller: 'member/denySeller',
  memberDelete: 'member/delete',
  memberExport: 'member/export',
  memberPointHisGet: 'memberPointHis/getList',
  /* notice */
  noticeGetList: 'notice/getList',
  noticeCreate: 'notice/create',
  noticeUpdate: 'notice/update',
  noticeDelete: 'notice/delete',
  /* notification */
  notificationGetList: 'notification/getList',
  notificationCreate: 'notification/create',
  notificationUpdate: 'notification/update',
  notificationDelete: 'notification/delete',
  notificationPush: 'notification/push',
  /* notification group*/
  notificationGroupGetList: 'notificationGroup/getList',
  notificationGroupCreate: 'notificationGroup/create',
  notificationGroupUpdate: 'notificationGroup/update/:id',
  notificationGroupDelete: 'notificationGroup/delete',
  /* member level*/
  memberLevelGetListLite: 'memberLevel/getListLite',
  /* platform*/
  platformGetListLite: 'platform/getListLite',
  /* feedback*/
  feedbackGetList: 'feedback/getList',
  feedbackCreate: 'feedback/create',
  feedbackUpdate: 'feedback/update',
  feedbackDelete: 'feedback/delete',
  /* store address*/
  storeAddressGetList: 'storeAddress/getList',
  storeAddressCreate: 'storeAddress/create',
  storeAddressUpdate: 'storeAddress/update',
  storeAddressDelete: 'storeAddress/delete',
  /* store main style */
  storeMainStyleGetList: 'store/getListStyle',
  storeMainStyleSet: '/store/setMainStyle',
  storeMainStyleUnset: 'store/unsetMainStyle',
  /* City */
  cityGetList: 'city/getList',
  cityGetDist: 'city/getDist',
  cityGetWard: 'city/getWard',
  /* comment */
  commentGetList: 'comment/getList',
  commentUpdate: 'comment/update',
  commentGetDetail: 'comment/getDetail',
  /* comment */
  reviewGetList: 'review/getList',
  reviewUpdate: 'review/update',
  reviewGetDetail: 'review/getDetail',

  /* event*/
  eventGetList: 'event/getList',
  eventCreate: 'event/create',
  eventUpdate: 'event/update',
  eventDelete: 'event/delete',
  eventGetDetail: 'event/getDetail',
  // theme
  themeGetList: 'theme/getList',
  themeUpdate: 'theme/update',
  themeCreate: 'theme/create',
  themeDelete: 'theme/delete',
  themeArrange: 'theme/arrange',
  // theme style
  themeStyleGetList: 'theme/getListStyle',
  themeStyleAddStyle: 'theme/addStyle',
  themeStyleDeleteStyle: 'theme/deleteStyle',
  // theme style
  themeProductGetList: 'theme/getListProduct',
  themeProductAddStyle: 'theme/addProduct',
  themeProductDeleteStyle: 'theme/deleteProduct',
  /* faq */
  faqGetList: 'faq/getList',
  faqCreate: 'faq/create',
  faqUpdate: 'faq/update',
  faqDelete: 'faq/delete',
  faqGetListTag: 'faq/getListTag',
  faqCreateTag: 'faq/createTag',
  faqDeleteTag: 'faq/deleteTag',
  /* qna */
  qnaGetList: 'qna/getList',
  qnaGetDetail: 'qna/getDetail',
  qnaReply: 'qna/reply',
  /* qna category */
  qnaCategoryGetList: 'qnaCategory/getList',
  qnaCategoryGetListLite: 'qnaCategory/getListLite',
  /* style */
  styleGetList: 'style/getList',
  styleUpdate: 'style/update',
  styleUpdateStatus: 'style/updateStatus',

  preferenceList: 'style/getListPreference',
  setPreference: 'style/setPreference',
  unSetPreference: 'style/unsetPreference',
  preferenceArrange: 'style/updateArrange',

  /* product */
  productGetList: 'product/getList',
  productUpdate: 'product/update',
  productUpdateStatus: 'product/updateStatus',
  /* Attribute */
  attributeGetList: 'attribute/getList',
  attributeAgeCreate: 'age/create',
  attributeAgeGetList: 'age/getList',
  attributeAgeGetDetail: 'age',
  attributeAgeGetDelete: 'age',
  attributeAgeUpdate: 'age/update',
  attributeAgeUpdateArrange: 'age/updateArrange',
  attributeColorCreate: 'color/create',
  attributeColorGetList: 'color/getList',
  attributeColorGetDetail: 'color',
  attributeColorUpdate: 'color/update',
  attributeColorUpdateArrange: 'color/updateArrange',
  attributePatternCreate: 'pattern/create',
  attributePatternGetList: 'pattern/getList',
  attributePatternGetDetail: 'pattern',
  attributePatternUpdate: 'pattern/update',
  attributePatternUpdateArrange: 'pattern/updateArrange',
  attributeCatalogCreate: 'styleCatalog/create',
  attributeCatalogGetList: 'styleCatalog/getList',
  attributeCatalogGetDetail: 'styleCatalog',
  attributeCatalogUpdate: 'styleCatalog/update',
  attributeCatalogUpdateArrange: 'styleCatalog/updateArrange',
  attributeCategoryCreate: 'category/create',
  attributeCategoryGetList: 'category/getList',
  attributeCategoryGetDetail: 'category',
  attributeCategoryUpdate: 'category/update',
  attributeCategoryUpdateArrange: 'category/updateArrange',
  /* menu */
  menuGetList: 'menu/getList',
  menuUpdate: 'menu/update',
  menuCreate: 'menu/create',
  menuDelete: 'menu/delete',
  menuPermissionGetListLite: 'menuPermission/getListLite',
  menuPermissionCreate: 'menuPermission/create',
  menuGetListApi: 'menu/getListApi',
  menuEditApi: 'menu/editApi',
  /* Upload file */
  fileUpload: 'file/upload',
  /**
   * mobile config
   */
  mobileConfigGetList: 'mobileConfig/getList',
  mobileConfigCreate: 'mobileConfig/create',
  mobileConfigDelete: 'mobileConfig/delete',
  mobileConfigUpdate: 'mobileConfig/update',
  /**
   * media
   */
  mediaGetList: 'fileMgmt/getList',
  mediaUpload: 'fileMgmt/upload',
  mediaDelete: 'fileMgmt/delete',
  /**
   * appNavigate/getList
   */
  appNavigateGetList: 'appNavigate/getList',
  /*magazine*/
  magazineCreate: 'magazine/create',
  magazineUpdate: 'magazine/update/:id',
  magazineGetList: 'magazine/getList',
  magazineGetDetail: 'magazine/getDetail/:id',
  magazineUpload: 'magazine/:id/upload',
  magazineSectionCreate: 'magazine/:id/section/create',
  magazineSectionUpdateAll: 'magazine/:id/section/update-all',
  magazineSectionUpdate: 'magazine/:id/section/update',
  magazineSection: 'magazine/:id/section/update',
  magazineSectionDelete: 'magazine/:id/section/delete',
  magazineDelete: 'magazine/delete/:id',

  // reportSearch
  reportSearchGetTotalList: 'reportSearch/getTotalList',
  reportSearchGetStatistic: 'reportSearch/getStatistic',

  // seller
  sellerRegisterGetList: 'seller/getList',

  // requestEntrance
  requestEntranceGetList: 'storeEntrance/getList',
  getListRoleMGMT: 'userlevel/getList',
  createRoleMGMT: 'userlevel/create',
  updateRoleMGMT: 'userlevel/update/:id',
  deleteRoleMGMT: 'userlevel/delete/:id'
};

const ApiEndpoints = mapValues(listPath, (val) => `bo/${val}`);

export default ApiEndpoints;
