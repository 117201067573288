import { createAsyncThunk } from '@reduxjs/toolkit';

import { MenuConstants } from 'redux/constant';
import { MenuServices } from 'services/index';

export const GetList = createAsyncThunk(MenuConstants.GetList, async (query) => {
  let result = {};
  await MenuServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const GetListApi = createAsyncThunk(MenuConstants.GetListApi, async (query) => {
  let result = {};
  await MenuServices.GetListApi(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const GetListLite = createAsyncThunk(MenuConstants.GetListLite, async (query) => {
  let result = {};
  await MenuServices.GetListLite(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
export const Update = createAsyncThunk(MenuConstants.Update, async (query) => {
  let result = {};
  const {id, set} = query;
  await MenuServices.Update(id, set).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const EditApi = createAsyncThunk(MenuConstants.EditApi, async (query) => {
  let result = {};
  await MenuServices.EditApi(query).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(MenuConstants.Create, async (query) => {
  let result = {};
  await MenuServices.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const CreateMenuPermission = createAsyncThunk(MenuConstants.CreatePermission, async (query) => {
  let result = {};
  await MenuServices.CreatePermission(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Delete = createAsyncThunk(MenuConstants.Delete, async (query) => {
  let result = {};
  await MenuServices.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
