import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';

import SelectCustom from 'components/SelectCustom';

import './style.scss';

const FilterSelect = (props) => {
  const {
    dataSelect,
    filter,
    handleChange,
    handleSearch,
    label,
    name,
    placeholder,
    showSearch,
    mode,
    ...otherProps
  } = props;
  const { t: translation } = useTranslation();

  const value = filter[name];
  const propsCustom = {
    value: value,
    handleChange,
    showSearch: handleSearch || showSearch,
    showArrow: true,
    name,
    mode, //'multiple'
    placeholder: translation(placeholder),
    className: 'input__search input__group-two',
    defaultActiveFirstOption: false,
    filterOption: false,
    notFoundContent: null,
    data: dataSelect
  };

  if (isFunction(handleSearch)) {
    propsCustom.onSearch = (value) => {
      handleSearch(value);
    };
  }

  return (
    <div className="store__relative w-full">
      {label ? (
        <Input.Group compact>
          <Button className="button-select r">{translation(label)}</Button>
          <SelectCustom {...propsCustom} {...otherProps} />
        </Input.Group>
      ) : (
        <SelectCustom {...propsCustom} {...otherProps} />
      )}

    </div>
  );
};

FilterSelect.propTypes = {
  dataSelect: PropTypes.any,
  filter: PropTypes.object,
  handleChange: PropTypes.func,
  handleSearch: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  showSearch: PropTypes.bool
};

export default FilterSelect;
