import { createSlice } from '@reduxjs/toolkit';

const initialState = { totalCount: 0 };

const totalCountSlice = createSlice({
  name: 'totalCount',
  initialState: initialState,
  reducers: {
    seTotalCount(state, action) {
      state.totalCount = action.payload;
    }
  }
});

export const { seTotalCount } = totalCountSlice.actions;
export default totalCountSlice.reducer;


