import { LOCAL_STORAGE } from 'common/constants';
import { useCallback, useEffect, useState } from 'react'


const DEFAULT_THEME = window.localStorage.getItem(LOCAL_STORAGE.theme) || 
process.env.REACT_APP_MODE === 'DEV' ? 'dark': 'light';

const useTheme = () => {
  const [theme, setTheme] = useState<string>(DEFAULT_THEME);

  useEffect(() => {
    if (theme) {
      window.localStorage.setItem(LOCAL_STORAGE.theme, theme);
      
      const isLogin = window.localStorage.getItem(LOCAL_STORAGE.isLogin);
      document.documentElement.setAttribute('data-mode', isLogin && theme || '');
    }
  }, [theme]);

  const toggle = useCallback(() => {
    if (theme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  }, [theme]);

  return ({
    theme,
    toggle,
  })
}

export default useTheme