import { createAsyncThunk } from '@reduxjs/toolkit';

import { AttributeCategoryConstants } from 'redux/constant';
import { AttributeCategoryServices } from 'services/index';

export const GetList = createAsyncThunk(AttributeCategoryConstants.GetList, async (query) => {
  let result = {};
  await AttributeCategoryServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
export const Create = createAsyncThunk(AttributeCategoryConstants.Create, async (query) => {
  let result = {};
  await AttributeCategoryServices.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
export const UpdateArrange = createAsyncThunk(AttributeCategoryConstants.UpdateArrange, async (query) => {
  let result = {};
  await AttributeCategoryServices.UpdateArrange(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
export const Update = createAsyncThunk(AttributeCategoryConstants.Update, async (query) => {
  let result = {};
  await AttributeCategoryServices.Update(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});