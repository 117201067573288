import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import classNames from 'classnames';
import i18next from 'i18next';
import { get, isEmpty, isFunction, omitBy } from 'lodash';
import moment from 'moment/moment';
import queryString from 'query-string';
import swal from 'sweetalert';

import { ENUM_TYPES, LOCAL_STORAGE, ADMINISTRATOR } from './constants';
import { encryptStorage } from 'common/encryptLocal';

export const getQueryString = (query) => {
  const result = queryString.stringify(query);

  if (!result) return '';
  return `?${result}`;
};

export const debounced = (delay, fn) => {
  let timerId;

  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
};

export const capitalize = (stringText) => {
  return stringText.charAt(0).toUpperCase() + stringText.slice(1);
};

export const getParameterByName = (name, url) => {
  if (!url) url = '';
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isEquivalent = (a, b) => {
  // Create arrays of property names
  let aProps = Object.getOwnPropertyNames(a);
  let bProps = Object.getOwnPropertyNames(b);
  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }
  for (let i = 0; i < aProps.length; i++) {
    let propName = aProps[i];
    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const xoa_dau = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  return str;
};

export const number_to_price = (v = 0) => {
  if (v === 0) {
    return '0';
  }

  if (!v || v === '') {
    return v;
  }
  v = v.toString();

  v = v.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');

  v = v.split(',').join('*').split('.').join(',').split('*').join('.');
  return v;
};

export const price_to_number = (v) => {
  if (!v) {
    return 0;
  }
  v = v.split(',').join('');
  // v = v.split('.').join(',');

  return Number(v);
};

export function validateEmail(email) {
  // eslint-disable-next-line
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePass(pass) {
  const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  return passw.test(pass);
}

export function validateNumberPhone(number) {
  let c1 = number.substring(0, 4);
  if (c1 === '+855') {
    return true;
  }
  c1 = number.substring(0, 3);
  return c1 === '+60' || c1 === '+84';
}

export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export function convertPhoneNumber(phoneNumber) {
  if (phoneNumber) {
    const areaCode = '+84';
    let checkareaCode = phoneNumber.substring(0, 2);
    let checkZero = phoneNumber.substring(0, 1);
    if (checkareaCode === areaCode) {
      return phoneNumber;
    } else if (checkZero === '0') {
      return areaCode + phoneNumber.substring(1, phoneNumber.length);
    } else {
      return areaCode + phoneNumber;
    }
  }

  return '';
}

export function formatToUSDText(x) {
  // currency USD
  var parts = Number(x).toFixed(2);
  parts = parts.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) {
    parts[1] = parts[1].substring(0, 2);
  } else {
    parts[1] = '00';
  }
  return parts.join('.') + 'USD';
}

export function formatToUSDPrice(x) {
  // currency $
  var parts = Number(x).toFixed(2);
  parts = parts.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) {
    parts[1] = parts[1].substring(0, 2);
  } else {
    parts[1] = '00';
  }
  return '$' + parts.join('.');
}

export function formatToPrice(num) {
  // currency $
  if (num) {
    return String(num).replace(/(.)(?=(\d{3})+$)/g, '$1,');
  } else {
    return 0;
  }
}

export function makeId(length, prefix = '') {
  var result = prefix;
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const user = encryptStorage.getItem(LOCAL_STORAGE.data) || {};

export function checkPermission(value, listAction) {
  return user?.roleTitle === ADMINISTRATOR || Array.isArray(listAction) && listAction.findIndex(el => el.toUpperCase() === value.toUpperCase()) !== -1;
}

export const renderButtonStatus = (status, title, handleClick, className) => {
  let color = '';
  switch (status) {
    case 'primary':
      color = 'var(--point-color-green)';
      break;
    case 'warning':
      color = 'var(--point-color-pink)';
      break;
    case 'secondary':
      color = 'var(--sub-color-gray)';
      break;
  }

  return (
    <Button
      onClick={handleClick}
      className={[className, `bg-[${color}] w-[112px] h-[36px] text-white font-light flex items-center justify-center rounded-[3px] 
      text-sm border-0`]}
    >
      {title}
    </Button>
  );
};

export const commonCheckPermission = (action = []) => {
  const isCanEdit = checkPermission('EDIT', action);
  const isCanImport = checkPermission('IMPORT', action);
  // const isCanCreate = checkPermission('CREATE', action);
  const isCanDelete = checkPermission('DELETE', action);
  const isCanApproveQC = checkPermission('QC', action);
  const isCanView = checkPermission('VIEW_DETAIL', action);
  const isCanReply = checkPermission('REPLY', action);
  const isCanPush = checkPermission('PUSH', action);
  const isCanAdd = checkPermission('CREATE', action);
  const isCanModifyWorker = checkPermission('MODIFY_WORKER', action);
  const isCanExport = checkPermission('EXPORT', action);
  return {
    isCanAdd,
    isCanEdit,
    isCanDelete,
    isCanView,
    isCanModifyWorker,
    isCanImport,
    isCanApproveQC,
    // isCanCreate,
    isCanReply,
    isCanPush,
    isCanExport
  };
};

export function copyToClipboard(text) {
  var selected = false;
  var el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  if (document.getSelection().rangeCount > 0) {
    selected = document.getSelection().getRangeAt(0);
  }
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
  toast.success(i18next.t('copyLinkSuccess'));
}

export const renderColorProgress = (progress) => {
  if (Number(progress) < 30) {
    return '#BFBFBF';
  } else if (Number(progress) < 60) {
    return '#E9B85F';
  } else if (Number(progress) < 100) {
    return 'var(--main-color-pink-button)';
  } else if (Number(progress) === 100) {
    return 'var(--menu-color-active)';
  }
};

export const notificationUpdate = (result, defaultLang, func) => {
  if (!isEmpty(result)) {
    const { isSuccess } = result;
    if (isSuccess) {
      toast.success(i18next.t('actionSuccess'));
      isFunction(func) && func();
    }
  }
};
export const notificationUpdateForm = (result, language, updateSuccess, updateFailed) => {
  if (!isEmpty(result)) {
    const { isSuccess } = result;
    if (isSuccess) {
      toast.success(i18next.t('actionSuccess'));
      isFunction(updateSuccess) && updateSuccess();
    } else {
      // toast.error(get(message, ['0', 'error', [language]], i18next.t('updateFail')))
      isFunction(updateFailed) && updateFailed();
    }
  }
};
export const getLanguage = () =>
  i18next.language || window.localStorage.getItem(LOCAL_STORAGE.language);

export const formatDateTime = (time, format, defaultValue) =>
  time
    ? moment(time).format(
      format ?? getLanguage() !== 'vi' ? 'YYYY/MM/DD HH:mm:ss' : 'DD/MM/YYYY HH:mm:ss'
    )
    : defaultValue || '-';

export const imageExists = (image_url) => {

  var http = new XMLHttpRequest();

  http.open('HEAD', image_url, false);
  http.send();

  return http.status != 404;
};

export const hexToRgb = (hex) => {
  hex = hex.replace('#', '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return !isNaN(r) && !isNaN(g) && !isNaN(b) ? `${r},${g},${b}` : '';
};
export const formatDate = (date, format) =>
  date
    ? moment(date).format(format ?? getLanguage() !== 'vi' ? 'YYYY/MM/DD' : 'DD/MM/YYYY')
    : '-';
export const formatTime = (time, format) =>
  time ? moment(time).format(format ?? 'HH:mm:ss') : '-';

export const convertEnum = (data, language) => {
  return !isEmpty(data)
    ? data?.map((item) => ({
      value: item?.value,
      label: get(item, ['title', language], '')
    }))
    : [];
};

export const commonGetEnum = () => {
  const language = getLanguage();
  const enumObject = useSelector((state) => state.enum);
  const obj = { enumObject };
  if (!isEmpty(enumObject)) {
    !isEmpty(ENUM_TYPES) &&
      Object.keys(ENUM_TYPES).forEach((key) => {
        obj[key] = convertEnum(enumObject[ENUM_TYPES[key]], language);
      });
  }
  return obj;
};

export const titleTextRow = (record) => get(record, ['title', [getLanguage()]], '-');
export const titleTimeRow = (record) =>
  record ? moment(record).format('YYYY/MM/DD HH:mm:ss') : '-';

/* replace url this page */
export const replaceUrlPage = (filters, historyPath) => {
  const pageUrl = '?' + queryString.stringify(filters);
  window.history.pushState(historyPath, '', pageUrl);
};

export const paramsURL = queryString.parse(window.location.search, {
  parseBooleans: true,
  parseNumbers: true,
  arrayFormat: 'bracket'
});
export const omitNull = (values) => omitBy(values, (val) => val === null);
/* START Convert JSX */
export const ButtonStatus = (props) => {
  const language = getLanguage();
  const { data } = props;
  if (!isEmpty(data)) {
    const title = data?.title[language] ?? '';
    return <Button className={classNames('button-status', data.css_class)}>{title}</Button>;
  }
  return null;
};
/* END Convert JSX */

export const SwalConfirmAction = (message, confirmYes, ObjVal = {}) => {
  const elem = document.createElement('div');
  elem.innerHTML = i18next.t(message, ObjVal);
  swal({
    title: '',
    content: elem,
    icon: 'warning',
    buttons: [i18next.t('cancel'), i18next.t('yes')]
  }).then((willDelete) => {
    if (willDelete) {
      confirmYes && confirmYes();
    }
  });
};

export const startCase = (str) =>
  str.split(' ')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');

export const getDrawerLocal = encryptStorage.getItem(LOCAL_STORAGE.drawer) || {};
// export const getDrawerLocal = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE.drawer) || '{}');

export const toastError = (errMess, options = {}) => {
  toast.dismiss();
  toast.error(<div dangerouslySetInnerHTML={{ __html: errMess }}></div>, options);
};
export const toastSuccess = (succMess, options = {}) =>
  toast.success(<div dangerouslySetInnerHTML={{ __html: succMess }}></div>, options);

export const clearStorage = () => {
  Object.values(LOCAL_STORAGE)?.forEach(val => {
    encryptStorage.removeItem(val);
    window.localStorage.removeItem(val);
  });
};
export const arrayMove = (arr, fromIndex, toIndex) => {
  const arrayCopy = [...arr];
  while (fromIndex < 0) {
    fromIndex += arrayCopy.length;
  }
  while (toIndex < 0) {
    toIndex += arrayCopy.length;
  }
  if (toIndex >= arrayCopy.length) {
    let k = toIndex - arrayCopy.length;
    while (k-- + 1) {
      arrayCopy.push(undefined);
    }
  }
  arrayCopy.splice(toIndex, 0, arrayCopy.splice(fromIndex, 1)[0]);
  return arrayCopy;
};

export const swapArr = (array, indexA, indexB) => {
  let tmp = array[indexA];
  array[indexA] = array[indexB];
  array[indexB] = tmp;
};

