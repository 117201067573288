import { createAsyncThunk } from '@reduxjs/toolkit';

import { PlatformConstants } from 'redux/constant';
import { PlatformService } from 'services/index';
export const GetListLite = createAsyncThunk(PlatformConstants.GetListLite, async (query) => {
  let result = {};
  await PlatformService.GetListLite(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
