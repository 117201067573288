import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThemeMgmtConstants } from 'redux/constant';
import { ThemeMgmtService } from 'services/index';
export const GetList = createAsyncThunk(ThemeMgmtConstants.GetList, async (query) => {
  let result = {};
  await ThemeMgmtService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const Update = createAsyncThunk(ThemeMgmtConstants.Update, async (query) => {
  let result = {};
  const { id, data } = query;
  await ThemeMgmtService.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(ThemeMgmtConstants.Create, async (query) => {
  let result = {};
  await ThemeMgmtService.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Delete = createAsyncThunk(ThemeMgmtConstants.Delete, async (query) => {
  let result = {};
  await ThemeMgmtService.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const GetListArrange = createAsyncThunk(ThemeMgmtConstants.GET_LIST_ARRANGE, async (query) => {
  let result = {};
  await ThemeMgmtService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const UpdateArrange = createAsyncThunk(ThemeMgmtConstants.UpdateArrange, async (query) => {
  let result = {};
  const { ids } = query;
  await ThemeMgmtService.UpdateArrange(ids).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
