import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import { messages_en, messages_kr, messages_vi } from './index';

const messages = {
  en: messages_en,
  vi: messages_vi,
  kr: messages_kr,
};

i18next.use(initReactI18next).init({
  interpolation: {
    escapeValue: false // not needed for react!!
  }, // React already does escaping
  lng: 'vi', // language to use
  resources: {
    vi: {
      translation: messages.vi
    },
    en: {
      translation: messages.en // 'common' is our custom namespace
    },
    kr: {
      translation: messages.kr // 'common' is our custom namespace
    },
  },
  // returnedObjectHandler: (key, value, options) => {
  //   if ("html" in value) return renderHTML(value.html)
  //   // if ("markdown" in value) return <ReactMarkdown source={value.markdown} />;
  //   return value;
  // },
  debug: false, //process.env.NODE_ENV !== "production"
  fallbackLng: 'vi',
  react: {
    // wait: true,
    ns: ['translation'],
    defaultNS: 'translation',
    defaultTransParent: 'div', // a valid react element - required before react 16
    transEmptyNodeValue: '', // what to return for empty Trans
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
    transWrapTextNodes: '', // Wrap text nodes in a user-specified element.
    useSuspense: true //   <---- this will do the magic
  }
});

export default i18next;
