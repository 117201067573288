import { createAsyncThunk } from '@reduxjs/toolkit';

import { userMgmtConstants } from 'redux/constant';
import { UserMgmtService } from 'services/index';
export const GetList = createAsyncThunk(userMgmtConstants.GetList, async (query) => {
  let result = {};
  await UserMgmtService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});


export const Update = createAsyncThunk(userMgmtConstants.Update, async (query) => {
  let result = {};
  const {id, data} = query;
  await UserMgmtService.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(userMgmtConstants.Create, async (query) => {
  let result = {};
  await UserMgmtService.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Delete = createAsyncThunk(userMgmtConstants.Delete, async (query) => {
  let result = {};
  await UserMgmtService.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const ResetPass = createAsyncThunk(userMgmtConstants.ResetPass, async (query) => {
  let result = {};
  const {id, data} = query;
  await UserMgmtService.ResetPass(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
