import { createAsyncThunk } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import Querystring from 'query-string';

import { FETCH_LIST_PERMISSION, GET_LIST_USER_PERMISSION, UPDATE_PERMISSION } from 'redux/constant';
import PermissionService from 'services/permission.service';
export const actionGetListPermission = createAsyncThunk(FETCH_LIST_PERMISSION, async (query) => {
  let result = {};

  await PermissionService.getListPermission(Querystring.stringify(query)).then((res) => {
    const { isSuccess, data, } = res;
    if (isSuccess) {
      const { levelTitle } = data
      const newData = data.listData.map((item) => {
        return {
          ...item,
          key: uniqueId()
        };
      });

      result = { data: newData, isSuccess, levelTitle: levelTitle };
    }
  });
  return result;
});
export const actionGetListUser = createAsyncThunk(GET_LIST_USER_PERMISSION, async (query) => {
  let result = {};

  await PermissionService.getListUser(Querystring.stringify(query)).then((res) => {
    const { isSuccess, data } = res;
    if (isSuccess) {
      const { listData } = data;

      const newData = listData.map((item) => {
        return {
          ...item,
          name: item.fullname,
          value: item._id,
          key: uniqueId()
        };
      });
      result = { data: newData, isSuccess };
    }
  });
  return result;
});

export const actionUpdatePermission = createAsyncThunk(UPDATE_PERMISSION, async (query) => {
  let result = {};

  await PermissionService.updatePermission(query).then((res) => {
    const { isSuccess, data } = res;
    if (isSuccess) {
      const { message } = data;
      result = { isSuccess, message };
    }
  });
  return result;
});
