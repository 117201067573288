import { createAsyncThunk } from '@reduxjs/toolkit';

import { CityConstants } from 'redux/constant';
import { CityServices } from 'services/index';

export const GetList = createAsyncThunk(CityConstants.GetList, async (query) => {
  let result = {};
  await CityServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const GetWard = createAsyncThunk(CityConstants.GetWard, async (query) => {
  let result = {};
  await CityServices.GetWard(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const GetDist = createAsyncThunk(CityConstants.GetDist, async (query) => {
  let result = {};
  await CityServices.GetDist(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
