import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.qnaGetList, params);
};
export const GetDetail = async (params = {}) => {
  const {id} = params;
  return await configServices.getService(`${ApiEndpoints.qnaGetDetail}/${id}`);
};
export const Reply = async (id, data) => {
  return await configServices.postService(`${ApiEndpoints.qnaReply}/${id}`, data);
};