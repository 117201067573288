import { createSlice } from '@reduxjs/toolkit';

import {
  actionCreateUserLevel,
  actionDeleteUserLevel,
  actionGetListUserLevel,
  actionUpdateUserLevel
} from 'redux/actions';

export const userLevelSlice = createSlice({
  name: 'userLevel',
  initialState: {
    listData: [],
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(actionGetListUserLevel.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(actionGetListUserLevel.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData, totalCount} = data;
      state.listData = listData;
      state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(actionUpdateUserLevel.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(actionCreateUserLevel.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(actionDeleteUserLevel.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});

export default userLevelSlice.reducer;
