import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import { IconArrowDown, IconArrowUp } from 'assets/icons';
import ButtonCustom from 'components/ButtonCustom';

const FilterGroupAction = (props) => {
  const {
    handleSearchFilter,
    handleClearFilter,
    handleExpandFilter,
    showExpand = false,
    expand
  } = props;
  const { t: translation } = useTranslation();

  return (
    <div className="flex justify-end w-full">
      <Button
        className="button-submit h-auto px-[15px] py-[5px]"
        onClick={handleSearchFilter}
        htmlType={!handleSearchFilter ? 'submit': undefined}
        type="primary"
      >
        {translation('search')}
      </Button>
      <Button className="button-clear px-[15px] py-[5px] h-auto" onClick={handleClearFilter} type="default">
        {translation('clearFilter')}
      </Button>
      {showExpand && (
        <ButtonCustom
          className="button-expand button-custom-hover flex"
          onClick={handleExpandFilter}
          type="default"
        >
          {!expand ? translation('expand') : translation('collapse')}
          {!expand ? <IconArrowDown className="ml-1" /> : <IconArrowUp className="ml-1" />}
        </ButtonCustom>
      )}
    </div>
  );
};

FilterGroupAction.propTypes = {
  handleSearchFilter: PropTypes.func,
  handleClearFilter: PropTypes.func,
  handleExpandFilter: PropTypes.func,
  showExpand: PropTypes.bool
};

export default FilterGroupAction;
