import { QueryCache, QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  // queryCache: queryCache,
  defaultOptions: {
    queries: {
      retry: (_failureCount, error: any) => error.status >= 404,
      staleTime: 1000 * 60 * 5, // 5 minute
      cacheTime: 1000 * 60 * 5, // 5 minute
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false, //30 seconds
      refetchIntervalInBackground: false,
      suspense: false
    }
  }
});
