import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Form, Input, Modal, Spin, Upload } from 'antd';
import { omit } from 'lodash';

import { EditOutlined, LoadingOutlined } from '@ant-design/icons';

function ModalDetailProfile(props) {
  const {
    isModalVisible,
    setIsModalVisible,
    data = {},
    handleUpdateLabeler,
    setDataDetail
  } = props;
  const { t: translation } = useTranslation();
  const [form] = Form.useForm();
  const [loading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [refreshForm, setRefeshForm] = useState(false);
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    if (data.message) {
      form.setFields(data.message);
    } else {
      setRefeshForm(true);
      form.resetFields();
      form.setFieldsValue({ ...data });
      setTimeout(() => {
        setRefeshForm(false);
      }, 500);
      // return clearTimeout(timeOut)
    }

    if (data.avatar && data.avatar !== '') {
      setImageUrl(data.avatar);
    }
  }, [data, form]);

  useEffect(() => {
    if (isModalVisible) {
      const dataTemp = { ...data };
      setDataDetail(omit(dataTemp, ['message']));
    }
  }, [isModalVisible]);

  const uploadButton = <div>{loading ? <LoadingOutlined /> : <EditOutlined />}</div>;

  return (
    <>
      <Modal
        forceRender
        getContainer={false}
        title={`${translation('userProfile')}`}
        footer={null}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          const dataTemp = { ...data };
          setDataDetail(omit(dataTemp, ['message']));
        }}
      >
        {!refreshForm ? (
          <Form
            form={form}
            onFinish={(values) => {
              const newValues = {
                ...values,
                avatar
              };
              handleUpdateLabeler(newValues, () => {
                setIsModalVisible(false);
                const dataTemp = { ...data };
                setDataDetail(omit(dataTemp, ['message']));
              });
            }}
          >
            <div className="mb-3 flex items-center w-full">
              <Form.Item name="avatar" className="w-full detailQc__form__input">
                <Upload
                  listType="picture-card"
                  className={`avatar-uploader  flex items-center justify-center headerHome__avatar-second ${imageUrl ? 'border-none' : ''
                    }`}
                  showUploadList={false}
                  beforeUpload={(info) => {
                    if (info?.size > 1000000) {
                      toast.error(translation('errorUploadImageProfile'));
                      return false;
                    }
                    setImageUrl(URL.createObjectURL(info));
                    setAvatar(info);
                    return false;
                  }}
                  fileList={[]}
                >
                  {imageUrl ? (
                    <>
                      <img
                        className="headerHome__avatar__img"
                        src={imageUrl}
                        alt="avatar"
                        height={104}
                        width={104}
                      />
                      <EditOutlined className={`${imageUrl ? 'headerHome__avatar__icon' : ''}`} />
                    </>
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </div>
            <div className="mb-3 flex items-center w-full">
              <div className="w-200">
                {translation('fullname')} <span className="danger">*</span>
              </div>
              <Form.Item
                name="fullname"
                className="w-full detailQc__form__input"
                rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className="mb-3 flex items-center w-full">
              <div className="w-200">
                Email <span className="danger">*</span>
              </div>
              <Form.Item
                name="email"
                className="w-full detailQc__form__input"
                rules={[
                  { required: true, message: translation('thisFieldNotAllowEmpty') },
                  { type: 'email', message: translation('invalidEmailAddress') }
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="mb-3 flex items-center w-full">
              <div className="w-200">{translation('Phone')} </div>
              <Form.Item name="phone" className="w-full detailQc__form__input">
                <Input />
              </Form.Item>
            </div>
            <div className="flex items-center w-full mt-3 justify-end">
              <Button
                onClick={() => {
                  setIsModalVisible(false);
                }}
                className="btn-cancel mr-1 button-custom-hover"
              >
                {translation('cancel')}
              </Button>
              <Button htmlType="submit" type="primary" className="ml-1 button-custom-hover">
                {translation('save')}
              </Button>
            </div>
          </Form>
        ) : (
          <div
            style={{ minHeight: '304px' }}
            className="flex items-center justify-center"
          >
            <Spin></Spin>
          </div>
        )}
      </Modal>
    </>
  );
}
export default ModalDetailProfile;
