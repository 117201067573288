import React, { forwardRef, memo } from 'react';
import { Table as TableAntd } from 'antd';
import { TableProps as TableAntdProps } from 'antd/es/table';
import { defaultPageSize } from 'common/constants';
import { first } from 'lodash';
import querystring from 'query-string'

type TableProps = {
  onChangePagination?: (page: number, pageSize: number) => void
  setFilters: (preFilters: any) => void
  total: number
  isPagination: boolean
  limit?: number
  page?: number
}

const Table = (props: TableProps & React.Attributes & TableAntdProps<any>, ref?: React.Ref<HTMLDivElement>) => {
  const {
    key,
    setFilters,
    total,
    loading,
    dataSource,
    isPagination = true,
    columns = [],
    limit: pageSizePaginationProp,
    page: currentPaginationProp,
    ...restProps
  } = props;

  const queryParams: any = querystring.parse(window.location.search, {parseNumbers: true});
  const { page: currentPagination, limit: pageSizePagination } = queryParams;
  
  return (
    <TableAntd
      ref={ref}
      key={key || '_id'}
      dataSource={dataSource}
      columns={columns}
      pagination={isPagination && ({
        onChange(page) {
          setFilters?.((preFilter: any) => ({
            ...preFilter,
            page: page,
          }))
        },
        onShowSizeChange(current, size) {
          setFilters?.((preFilter: any) => ({
            ...preFilter,
            limit: size,
          }))
        },
        defaultPageSize: first(defaultPageSize),
        showSizeChanger: true,
        pageSizeOptions: defaultPageSize,
        total: total,
        current: currentPaginationProp || currentPagination,
        pageSize: pageSizePaginationProp || pageSizePagination
      })}
      loading={loading}
      {...restProps}
    />
  );
};

export default forwardRef(Table);