import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { LOCAL_STORAGE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import Drawer from 'components/Drawer';
import Loader from 'components/Loader';
import Header from 'components/Widtget/HeaderPage';
import { changeDrawer, changeSetting } from 'redux/actions';

function LayoutComponent(props) {
  const { Component, isOpen } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const setting = encryptStorage.getItem(LOCAL_STORAGE.setting);
    if (!isEmpty(setting)) {
      dispatch(changeSetting(setting));
    }
  }, []);

  return (
    <>
      <Header
        {...props}
        setOpen={(value) => {
          dispatch(changeDrawer(value));
        }}
        isOpen={isOpen}
      />
      <main className="layout__main">
        <Drawer
          {...props}
          isShowSidebar={isOpen}
          setOpen={(value) => {
            dispatch(changeDrawer(value));
          }}
        />
        <div className={classNames('layout__parent', { toggled: !isOpen })}>
          <Component {...props} />
        </div>
      </main>
      <Loader />
    </>
  );
}

const mapStateToProps = (state) => ({
  member: state.member || {}
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
