import { createAsyncThunk } from '@reduxjs/toolkit';

import { SellerRegisterConstants } from 'redux/constant';
import { MemberMgmtConstants } from 'redux/constant';
import { SellerRegisterService } from 'services/index';
import { MemberMgmtService } from 'services/index';

export const GetList = createAsyncThunk(SellerRegisterConstants.GetList, async (query) => {
  let result = {};
  await SellerRegisterService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});



export const ApproveSeller = createAsyncThunk(MemberMgmtConstants.ApproveSeller, async (payload) => {
  let result = {};
  await MemberMgmtService.ApproveSeller(payload).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const DenySeller = createAsyncThunk(MemberMgmtConstants.DenySeller, async (payload) => {
  let result = {};
  await MemberMgmtService.DenySeller(payload).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});