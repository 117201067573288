import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { SLUG_ROOT } from 'common/constants';
import ClearStorage from 'pages/Other/ClearStorage';
import ForgetPass from 'pages/Other/ForgetPass';
import LayoutPageSecond from 'pages/Other/Layout/components/second';
import LoginPage from 'pages/Other/Login';
import routers from 'routes';

const DefaultRoutes = () => {
  return (
    <Switch>
      <Route path={`${SLUG_ROOT}/clear-storage`} component={ClearStorage} />
      <Route
        path={routers.login.path}
        exact
        component={(props) => (
          <LayoutPageSecond {...props} Component={LoginPage} className="forgot__pass" />
        )}
      />
      <Route
        path={routers.forgotPass.path}
        exact
        component={(props) => (
          <LayoutPageSecond {...props} Component={ForgetPass} className="forgot__pass" />
        )}
      />
      <Route
        path={'*'}
        exact
        component={() => (
          <Redirect to={routers.login.path}/>
        )}
      />
    </Switch>
  );
};

export default DefaultRoutes;