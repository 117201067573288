import * as actionTypes from '../constant/drawer.constants';

const changeDrawer = (data) => ({
  type: actionTypes.DRAWER_OPEN,
  data: {
    isOpen: data
  }
});

export { changeDrawer };
