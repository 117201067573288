import { createAsyncThunk } from '@reduxjs/toolkit';

import { QnACategoryServices } from '../../services';

import { QnACategory } from '../constant';
export const GetList = createAsyncThunk(QnACategory.GetList, async (query) => {
  let result = {};
  await QnACategoryServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
export const GetListLite = createAsyncThunk(QnACategory.GetListLite, async (query) => {
  let result = {};
  await QnACategoryServices.GetListLite(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
