import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.memberGetList, params);
};
export const GetListPointHis = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.memberPointHisGet, params);
};
export const GetListLite = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.memberGetListLite, params);
};
export const Update = async (id, data) => {
  return await configServices.putService(`${ApiEndpoints.memberUpdate}/${id}`, data);
};
export const ApproveSeller = async (payload) => {
  return await configServices.putService(`${ApiEndpoints.memberApproveSeller}`, payload, true, true);
};
export const DenySeller = async (payload) => {
  return await configServices.putService(`${ApiEndpoints.memberDenySeller}`, payload);
};
export const Export = async (params = {}) => {
  return await configServices.downloadXLSFile(ApiEndpoints.memberExport, params);
};
export const ExportByType = async (params = {}) => {
  return await configServices.downloadXLSFile(ApiEndpoints.memberExport, params);
};
