import { createAsyncThunk } from '@reduxjs/toolkit';

import { FAQConstants } from 'redux/constant';
import { FAQServices } from 'services/index';
export const GetList = createAsyncThunk(FAQConstants.GetList, async (query) => {
  let result = {};
  await FAQServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const GetListTag = createAsyncThunk(FAQConstants.GetListTag, async (query) => {
  let result = {};
  await FAQServices.GetListTag(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const Update = createAsyncThunk(FAQConstants.Update, async (query) => {
  let result = {};
  const { id, data } = query;
  await FAQServices.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(FAQConstants.Create, async (query) => {
  let result = {};
  await FAQServices.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const CreateTag = createAsyncThunk(FAQConstants.CreateTag, async (query) => {
  let result = {};
  await FAQServices.CreateTag(query).then((res) => {
    const { message, code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message, data };
    }
  });
  return result;
});

export const Delete = createAsyncThunk(FAQConstants.Delete, async (query) => {
  let result = {};
  await FAQServices.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const DeleteTag = createAsyncThunk(FAQConstants.DeleteTag, async (query) => {
  let result = {};
  await FAQServices.DeleteTag(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});


