import { createAsyncThunk } from '@reduxjs/toolkit';

import { ReportSearchConstants } from 'redux/constant';
import { ReportSearchServices } from 'services/index';
export const GetTotalList = createAsyncThunk(ReportSearchConstants.GetTotalList, async (query) => {
  let result = {};
  await ReportSearchServices.GetTotalList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});


export const GetStatisticByDaily = createAsyncThunk(ReportSearchConstants.GetStatisticByDaily, async (query) => {
  let result = {};
  await ReportSearchServices.GetStatistic({ ...query, type: 'byDaily' }).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});


export const GetStatisticByGender = createAsyncThunk(ReportSearchConstants.GetStatisticByGender, async (query) => {
  let result = {};
  await ReportSearchServices.GetStatistic({ ...query, type: 'byGender' }).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
