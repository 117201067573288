import { createAsyncThunk } from '@reduxjs/toolkit';

import { StoreConstants } from 'redux/constant';
import { StoreService } from 'services/index';
export const GetList = createAsyncThunk(StoreConstants.GetList, async (query) => {
  let result = {};
  await StoreService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
export const GetListLite = createAsyncThunk(StoreConstants.GetListLite, async (query) => {
  let result = {};
  await StoreService.GetListLite(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});


export const Update = createAsyncThunk(StoreConstants.Update, async (query) => {
  let result = {};
  const {id, data} = query;
  await StoreService.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(StoreConstants.Create, async (query) => {
  let result = {};
  await StoreService.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Delete = createAsyncThunk(StoreConstants.Delete, async (query) => {
  let result = {};
  await StoreService.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});


