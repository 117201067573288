import { createAsyncThunk } from '@reduxjs/toolkit';

import { StyleConstants } from 'redux/constant';
import { StyleServices } from 'services/index';
export const GetList = createAsyncThunk(StyleConstants.GetList, async (query) => {
  let result = {};
  await StyleServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});
export const Update = createAsyncThunk(StyleConstants.Update, async (query) => {
  let result = {};
  const { id, data } = query;
  await StyleServices.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const UpdateStatus = createAsyncThunk(StyleConstants.UpdateStatus, async (query) => {
  let result = {};
  await StyleServices.UpdateStatus(query).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});
