import { get, isEmpty } from 'lodash';

import { routesRoot } from 'App';
import { IconSetting } from 'assets/icons';
import { SLUG_ROOT } from 'common/constants';
import NotificationPage from 'pages/Notification/NotificationMGMT';
const { lazy } = require('react');

const Login = lazy(() => import('../pages/Other/Login'));
const ForgetPass = lazy(() => import('../pages/Other/ForgetPass'));
const OtpMail = lazy(() => import('../pages/Other/ForgetPass/opt-mail'));
const ChangePassword = lazy(() => import('../pages/Other/ForgetPass/change-password'));
const HomePage = lazy(() => import('../pages/Other/Home'));
const UserLevelPage = lazy(() => import('../pages/Administrator/UserLevelMGMT'));
const MenuMgmtPage = lazy(() => import('../pages/Administrator/MenuMGMT'));
const PermissionMgmtPage = lazy(() => import('../pages/Administrator/PermissionMGMT'));
const EventMgmtPage = lazy(() => import('../pages/Event/EventMGMT'));
const BannerMgmtPage = lazy(() => import('../pages/Event/BannerMGMT'));
const FAQMgmtPage = lazy(() => import('../pages/CustomerService/FaqMGMT'));
const QnAMgmtPage = lazy(() => import('../pages/CustomerService/QnAMGMT'));
const FeedbackMgmtPage = lazy(() => import('../pages/CustomerService/FeedbackMGMT'));
const UserMgmtPage = lazy(() => import('../pages/Administrator/UserMGMT'));
const EventCategoryMGMT = lazy(() => import('../pages/Event/EventCategoryMGMT'));
const MemberMGMTPage = lazy(() => import('../pages/Administrator/MemberMGMT'));
const NoticePage = lazy(() => import('../pages/CustomerService/NoticeMGMT'));
const StoreMgmtPage = lazy(() => import('../pages/Store/StoreMGMT'));
const StoreAddressMgmtPage = lazy(() => import('../pages/Store/StoreAddressMGMT'));
const ThemeMgmtPage = lazy(() => import('../pages/Theme/ThemeMGMT'));
const CommentPage = lazy(() => import('../pages/CustomerService/CommentMGMT'));
const ReviewPage = lazy(() => import('../pages/CustomerService/ReviewMGMT'));
const AppLanguage = lazy(() => import('../pages/Administrator/AppLanguage'));
const StylePage = lazy(() => import('../pages/Store/StyleMGMT'));
const ProductPage = lazy(() => import('../pages/Store/ProductMGMT'));
const StoreMainStylePage = lazy(() => import('../pages/Store/StoreMainStyleMGMT'));
const ThemesStyleMGMTPage = lazy(() => import('../pages/Theme/ThemeStyleMGMT'));
const AttributeMgmtPage = lazy(() => import('../pages/Administrator/AttributeMGMT'));
const PreferenceStyleMGMT = lazy(() => import('../pages/Store/PreferenceStyleMGMT'));
const MobileConfig = lazy(() => import('../pages/Administrator/MobileConfig'));
const MediaMgmtPage = lazy(() => import('../pages/CustomerService/MediaMGMT'));
const SearchStatisticPage = lazy(() => import('../pages/Report/SearchStatistic'));
const MagazinePage = lazy(() => import('../pages/Magazine/MagazineMGMT'));
const MagazineDetailPage = lazy(() => import('../pages/Magazine/MagazineDetail'));
const SellerRegisterPage = lazy(() => import('../pages/Store/SellerRegister'));
const RequestEntrancePage = lazy(() => import('../pages/Store/RequestEntrance'));
const NotificationGroupPage = lazy(() => import('../pages/Notification/NotificationGroupMGMT'));
export const renderPath = (path) => `${process.env.REACT_APP_API_ROOT || ''}/${path}`;

const routers = {
  login: {
    path: renderPath('login'),
    component: Login,
    className: 'forgot__pass'
  },
  forgotPass: {
    path: renderPath('forgot-password'),
    component: ForgetPass,
    className: 'forgot__pass'
  },
  optMail: {
    path: renderPath('otp-mail'),
    component: OtpMail,
    className: 'forgot__pass'
  },
  ChangePassword: {
    path: renderPath('change-password'),
    component: ChangePassword,
    className: 'forgot__pass'
  },

  /* ROUTER MENU SLIDE BAR */
  dashboard: {
    path: renderPath('dashboard'),
    component: HomePage,
    className: 'home-layout',
    state: '',
    keyPaths: ['dashboard']
    // icon: <IconHome />,
  },
  administrator: {
    path: `${SLUG_ROOT}/administrator`,
    component: UserLevelPage,
    state: ''
  },
  user_level_mgmt: {
    path: renderPath('administrator/user-level-mgmt'),
    component: UserLevelPage,
    state: 'userLevel'
  },
  menu_mgmt: {
    path: `${SLUG_ROOT}/administrator/menu-list`,
    component: MenuMgmtPage,
    state: 'menu'
  },
  permission_mgmt: {
    path: `${SLUG_ROOT}/administrator/permission-mgmt/:id`,
    component: PermissionMgmtPage,
    state: 'permission'
  },
  event: {
    path: `${SLUG_ROOT}/event`,
    component: EventMgmtPage,
    state: ''
  },
  event_mgmt: {
    path: `${SLUG_ROOT}/event/event-mgmt`,
    component: EventMgmtPage,
    state: 'event'
  },
  banner_mgmt: {
    path: `${SLUG_ROOT}/event/banner-mgmt`,
    component: BannerMgmtPage,
    state: 'banner'
  },
  customer_service: {
    path: '/backoffice/customer-service',
    state: 'customer',
    component: FAQMgmtPage
  },
  faq_mgmt: {
    path: '/backoffice/customer-service/faq-mgmt',
    state: 'faq',
    component: FAQMgmtPage
  },
  feedback_mgmt: {
    path: renderPath('customer-service/feedback-mgmt'),
    state: 'feedback',
    component: FeedbackMgmtPage
  },
  qna_mgmt: {
    path: renderPath('customer-service/qna-mgmt'),
    state: 'qna',
    component: QnAMgmtPage
  },
  event_category_mgmt: {
    path: '/backoffice/event/event-category-mgmt',
    state: 'eventCategory',
    component: EventCategoryMGMT
  },
  user_mgmt: {
    path: '/backoffice/administrator/user-mgmt',
    state: 'userMgmt',
    component: UserMgmtPage
  },
  member_mgmt: {
    path: '/backoffice/administrator/member-mgmt',
    state: 'memberMgmt',
    component: MemberMGMTPage
  },
  theme_style_mgmt: {
    path: '/backoffice/customer-service/theme-style-mgmt',
    state: 'themeStyle',
    component: ThemesStyleMGMTPage
  },
  store_style_mgmt: {
    path: '/backoffice/administrator/store-theme-mgmt',
    state: 'storeMainStyle',
    component: StoreMainStylePage
  },
  notice_mgmt: {
    path: renderPath('customer-service/notice-mgmt'),
    state: 'notice',
    component: NoticePage
  },
  notification_mgmt: {
    path: `${SLUG_ROOT}/notification/notification-mgmt`,
    state: 'notification',
    component: NotificationPage
  },
  store: {
    path: `${SLUG_ROOT}/store`,
    component: StoreMgmtPage,
    state: ''
  },
  store_mgmt: {
    path: '/backoffice/store/store-mgmt',
    state: 'store',
    component: StoreMgmtPage
  },
  store_address_mgmt: {
    path: '/backoffice/store/store-address-mgmt',
    state: 'storeAddress',
    component: StoreAddressMgmtPage
  },
  theme_mgmt: {
    path: `${SLUG_ROOT}/theme/theme-mgmt`,
    component: ThemeMgmtPage,
    state: 'themes'
  },
  comment_mgmt: {
    path: `${SLUG_ROOT}/administrator/comment-mgmt`,
    component: CommentPage,
    state: 'comments'
  },
  review_mgmt: {
    path: `${SLUG_ROOT}/customer-service/review-mgmt`,
    component: ReviewPage,
    state: 'review'
  },
  style_mgmt: {
    path: `${SLUG_ROOT}/store/style-mgmt`,
    component: StylePage,
    state: 'style'
  },
  product_mgmt: {
    path: renderPath('store/product-mgmt'),
    component: ProductPage,
    state: 'product'
  },
  app_language_mgmt: {
    path: renderPath('administrator/app-language'),
    component: AppLanguage,
    state: 'language',
    title: 'appLanguageMGMT'
  },
  attribute_mgmt: {
    path: renderPath('administrator/attribute-mgmt'),
    component: AttributeMgmtPage,
    state: 'attributeCatalog',
    title: 'attributeMGMT'
  },

  preference_style_mgmt: {
    path: renderPath('store/preference-style-mgmt'),
    title: 'preferenceStyle',
    state: 'preference',
    component: PreferenceStyleMGMT
  },
  style_catalog_mgmt: {
    path: renderPath('administrator/attribute-mgmt/style-catalog-mgmt'),
    component: AttributeMgmtPage,
    state: 'attributeCatalog',
    showParentMenu: true
  },
  category_mgmt: {
    path: renderPath('administrator/attribute-mgmt/category-mgmt'),
    component: AttributeMgmtPage,
    state: 'attributeCategory',
    showParentMenu: true
  },
  color_mgmt: {
    path: renderPath('administrator/attribute-mgmt/color-mgmt'),
    component: AttributeMgmtPage,
    showParentMenu: true,
    state: 'attributeColor'
  },
  pattern_mgmt: {
    path: renderPath('administrator/attribute-mgmt/pattern-mgmt'),
    component: AttributeMgmtPage,
    state: 'attributePattern',
    showParentMenu: true
  },
  age_mgmt: {
    path: renderPath('administrator/attribute-mgmt/age-mgmt'),
    component: AttributeMgmtPage,
    state: 'attributeAge',
    showParentMenu: true
  },
  mobile_config_mgmt: {
    path: renderPath('administrator/mobile-config-mgmt'),
    state: 'mobileConfig',
    component: MobileConfig
  },
  media_file_mgmt: {
    path: renderPath('customer-service/media-mgmt'),
    state: 'media',
    component: MediaMgmtPage
  },
  search_statistic: {
    path: renderPath('report/search-statistic'),
    state: 'searchStatistic',
    component: SearchStatisticPage
  },
  magazine_mgmt: {
    path: renderPath('magazine/magazine-mgmt'),
    state: 'magazine',
    component: MagazinePage
  },
  magazine_create: {
    path: renderPath('magazine/create'),
    state: 'magazine',
    component: MagazineDetailPage
  },
  magazine_detail: {
    path: renderPath('magazine/:id/detail'),
    state: 'magazine',
    component: MagazineDetailPage
  },
  magazine_edit: {
    path: renderPath('magazine/:id/edit'),
    state: 'magazine',
    component: MagazineDetailPage
  },
  seller_register: {
    path: renderPath('store/seller-register'),
    state: 'sellerRegister',
    component: SellerRegisterPage
  },
  store_request_entrance: {
    path: renderPath('store/store-request-entrance'),
    state: 'requestEntrance',
    component: RequestEntrancePage
  },
  notification_group: {
    path: renderPath('notification/notification-group'),
    state: 'notificationGroup',
    component: NotificationGroupPage
  },
};

export const routerSetting = {
  path: renderPath('setting'),
  component: StoreMgmtPage,
  state: '',
  title: 'setting',

  icon: <IconSetting />
};

export const renderPageName = (keyName) => {
  const route = get(routesRoot, [keyName], {});
  const keyPaths = get(route, 'keyPaths', []).slice(1);
  let name = get(routesRoot, [keyName, 'title'], '');
  let path = get(routesRoot, [keyName, 'path'], '');
  let title = 'total';
  let urlHeader = ['/'],
    titleHeader = [];
  !isEmpty(keyPaths) &&
    keyPaths.forEach((k) => {
      const currentRoute = routesRoot[k];
      if (currentRoute) {
        titleHeader.push(get(currentRoute, 'title', ''));
        urlHeader.push('#');
      }
    });

  urlHeader.push(path);
  const rootPath = {
    urlHeader,
    titleHeader
  };

  switch (keyName) {
    case undefined:
    case 'search_statistic':
    case 'dashboard':
    case 'menu_mgmt':
    case 'permission_mgmt':
    case 'magazine_detail':
    case 'magazine_edit':
    case 'magazine_create':
      title = '';
      break;
  }
  return { name, title, rootPath };
};

export default routers;
