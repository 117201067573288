import { Redirect, Route, Switch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { routesRoot } from 'App';
import { SLUG_ROOT } from 'common/constants';
import ClearStorage from 'pages/Other/ClearStorage';
import HomePage from 'pages/Other/Home';
import Layout from 'pages/Other/Layout';

const AdminRoutes = () => {

  return (
    <Switch>
      <Route path={`${SLUG_ROOT}/clear-storage`} component={ClearStorage} />
      {!isEmpty(routesRoot) && Object.entries(routesRoot).map(([key, routeItem]) => {
        const { isView } = routeItem;
        if (isView) {

          return (
            <Route
              key={routeItem?.key}
              exact
              path={routeItem.path}
              component={(props) => {
                if (!routeItem.component) {
                  return <Layout {...props} Component={HomePage} />;
                }
                return (
                  <Layout
                    {...props}
                    isSubMenu={routeItem.isSubMenu}
                    isView={routeItem?.isView}
                    Component={routeItem.component}
                    className={`${routeItem.className || 'HOME'}`}
                    keyName={key || ''}
                    action={routeItem.action || []}
                    title={routeItem.title}
                  />
                );
              }}
            />
          );
        }
      })}
      {!isEmpty(routesRoot) && <Route component={() => <Redirect to={routesRoot?.dashboard?.path || routesRoot[Object.keys(routesRoot)[0]]?.path} />} />}
    </Switch>
  );
};

export default AdminRoutes;
