import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.eventCategoryGetList, params);
};
export const Update = async (id, data) => {
  return await configServices.putService(
    `${ApiEndpoints.eventCategoryUpdate}/${id}`,
    data,
    true,
    true
  );
};
export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.eventCategoryCreate, data, true, true);
};
export const Delete = async (id) => {
  return await configServices.deleteService(`${ApiEndpoints.eventCategoryDelete}/${id}`);
};
