import { LOCAL_STORAGE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import { DRAWER_OPEN } from 'redux/constant';

let initialState = {
  isOpen: true
};
const data = encryptStorage.getItem(LOCAL_STORAGE.drawer);
if (data && data.length) {
  const newData = data;
  initialState = {
    ...initialState,
    ...newData
  };
}

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case DRAWER_OPEN: {
      encryptStorage.setItem(
        ...LOCAL_STORAGE.drawer,
        JSON.stringify({
          ...state,
          ...action.data
        })
      );
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
