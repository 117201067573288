import { toast } from 'react-toastify';
import axios from 'axios';
import { get, isEmpty } from 'lodash';

import { LOCAL_STORAGE, STATUS_CODE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import { renderPath } from 'routes';

import { clearStorage, getLanguage, getQueryString } from '../common/shares';
import { HOST } from '../redux/constant/url';

const addStorage = (data) => {
  encryptStorage.setItem(LOCAL_STORAGE.isLogin, true);
  encryptStorage.setItem(LOCAL_STORAGE.data, data);
};

function send({
  method = 'get',
  path,
  data = null,
  query = null,
  headers = {},
  isShowErr = true,
  newUrl
}) {
  return new Promise((resolve) => {
    let url = HOST + `${path}${getQueryString(query)}`;
    if (newUrl) {
      url = `${newUrl}${getQueryString(query)}`;
    }
    const dataString = encryptStorage.getItem(LOCAL_STORAGE.data);
    if (dataString) {
      // const newData = JSON.parse(dataString);
      headers.authorization = `Bearer ${dataString.token}`;
    }
    axios({
      method,
      url,
      data,
      headers
    })
      .then((result) => {
        const data = result.data;
        return resolve(data);
      })
      .catch((error) => {
        const lang = getLanguage();
        const result = get(error, 'response.data', null);
        const textError = get(result, ['errors', 0, 'error', lang], '');
        if (textError && isShowErr) {
          toast.error(textError, {
            toastId: textError
          });
        }
        if (!result) {
          return resolve({ code: 404, message: '' });
        } else {
          const code = get(error, 'response.status', null);
          if (
            code === STATUS_CODE.UNAUTHORIZED ||
            code === STATUS_CODE.FORBIDDEN ||
            code === STATUS_CODE.TOKEN_EXPIRED
          ) {
            handleRefreshToken(dataString);
          } else {
            return resolve(result);
          }
        }
      });
  });
}

async function handleRefreshToken(dataString) {
  return new Promise(() => {
    if (dataString) {
      const newData = JSON.parse(dataString);
      const { rf_token } = newData;
      if (rf_token) {
        fetch(`${HOST}Auth/rf_token`, {
          method: 'POST',
          body: JSON.stringify({ rf_token })
        })
          .then((res) => {
            return res.json();
          })
          .then((response) => {
            if (!isEmpty(response)) {
              const { status, data = {} } = response;
              if (status !== 'failure') {
                addStorage(data);
                return;
              }
            }
          });
      }
    }
    setTimeout(() => {
      clearStorage();
      window.location.replace(renderPath('login'));
    }, 100);
  });
}

export default {
  send
};
