import { useEffect, useState } from 'react';
import querystring from 'query-string';

import { omitNull } from 'common/shares';

const useFilters = (props) => {
  const {
    initial = {},
  } = props;
  const currentParams = querystring.parse(window.location.search, {
    parseNumbers: true,
    parseBooleans: true,
    arrayFormat: 'bracket'
  });

  const [filters, setFilters] = useState({
    ...initial,
    ...currentParams,
  });

  useEffect(() => {
    const currentFilters = omitNull({
      ...initial,
      ...currentParams,
    });

    const currentFiltersStr = '?' + querystring.stringify(currentFilters, {
      arrayFormat: 'bracket',
    });

    window.history.pushState(window.location.pathname, '', currentFiltersStr);
  }, [initial]);

  const handleReplaceURL = () => {
    const currentFilters = omitNull(filters);

    const currentFiltersStr = '?' + querystring.stringify(currentFilters, {
      arrayFormat: 'bracket',
    });

      setFilters(preFilters => ({
        ...preFilters,
        isReset: false,
        isFetch: false,
      }));
    window.history.pushState(window.location.pathname, '', currentFiltersStr);
  };

  useEffect(() => {
      handleReplaceURL();
  }, [filters.limit, filters.page]);

  useEffect(() => {
    if (filters.isFetch) {
      handleReplaceURL();
    }
  }, [filters.isFetch]);

  return [filters, setFilters];
};

export default useFilters;