import { createAsyncThunk } from '@reduxjs/toolkit';

import { MediaConstants } from 'redux/constant';
import { MediaServices } from 'services/index';

export const GetList = createAsyncThunk(MediaConstants.GetList, async (query) => {
  let result = {};
  await MediaServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const Upload = createAsyncThunk(MediaConstants.Upload, async (query) => {
  let result = {};
  await MediaServices.Upload(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Delete = createAsyncThunk(MediaConstants.Delete, async (query) => {
  let result = {};
  await MediaServices.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});


