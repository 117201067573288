import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppNavigateConstants } from 'redux/constant';
import { AppNavigateServices } from 'services/index';

export const GetList = createAsyncThunk(AppNavigateConstants.GetList, async(query) => {
  let result = {};
  await AppNavigateServices.GetList(query).then((res) => {
      const { code, data } = res;
      const isSuccess = code === 200;
      if (isSuccess) {
          result = { data, isSuccess };
      }
  });
  return result;
});