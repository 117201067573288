import { createSlice } from '@reduxjs/toolkit';

import {
  CityAction
} from 'redux/actions';

export const citySlice = createSlice({
  name: 'store',
  initialState: {
    listCity: [],
    listDist: [],
    listWard: [],
    isSuccess: false,
  },
  reducers: {
    clearListAddress(state) {
      // state.listCity= [];
      state.listDist= [];
      state.listWard= [];
    }
  },

  extraReducers: (builder) => {
    builder.addCase(CityAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(CityAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.listCity = data;
      state.isSuccess = isSuccess;
    });
    builder.addCase(CityAction.GetDist.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(CityAction.GetDist.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.listDist = data;
      state.isSuccess = isSuccess;
    });
    builder.addCase(CityAction.GetWard.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(CityAction.GetWard.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.listWard = data;
      state.isSuccess = isSuccess;
    });
  }
});

export const { clearListAddress } = citySlice.actions;

export default citySlice.reducer;
