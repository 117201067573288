import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';
import { first } from 'lodash';

import { INITIAL_FILTER } from 'common/constants';
import { commonGetEnum } from 'common/shares';
import FilterGroupAction from 'components/Filter/FilterGroupAction';
import SelectCustom from 'components/SelectCustom';

const { Option } = Select;
const withFilter = WrappedComponent => (props) => {
  const {
    filters,
    updateFilter,
    orderBy: orderByProps,
    moreButtons,
  } = props;

  const { t: translation } = useTranslation();
  const [form] = Form.useForm();
  const enums = commonGetEnum();

  const orderByDefault = [
    {
      value: 'updatedAt',
      label: translation('sortByFilter.updateTime'),
    },
    {
      value: 'createdAt',
      label: translation('sortByFilter.createTime'),
    },
  ];

  React.useEffect(() => {
    form.setFieldsValue(filters);
  }, [filters]);

  const clearFilter = useCallback(async () => {
    form.resetFields();
    updateFilter({
      ...INITIAL_FILTER,
      isFetch: true,
      isReset: true,
    });
  }, [form]);

  const orderBy = orderByProps || orderByDefault;
  return (
    <Form
      initialValues={{}}
      onFinish={(values) => {
        updateFilter({
          ...values,
          isFetch: true,
          limit: 20,
          page: 1,
        });
      }}
      onValuesChange={(values) =>
        updateFilter({
          ...values,
          isFetch: false,
        })}
      form={form}
    >
      <div className="grid grid-cols-4 gap-4 mb-4 group-filters">
        <Input.Group compact>
          <Form.Item name='orderBy'
            className='w-70'>
            <SelectCustom
              data={orderBy}
              showSearch
              placeholder={first(orderBy)?.label}
              className="border-right border-solid border-[#D9D9D9] dark:border-[#2F2F2F]"
            />
          </Form.Item>

          <Form.Item name='sortedBy'
            className='w-30'>
            <SelectCustom
              className=""
              name={'sortedBy'}
              showArrow
            >
              <Option value="desc">{translation('desc')}</Option>
              <Option value="asc">{translation('asc')}</Option>
            </SelectCustom>
          </Form.Item>
        </Input.Group>

        <WrappedComponent
          {...{
            ...props,
            ...enums,
            form,
          }}
        />

        <div className="flex items-center gap-1">
          <FilterGroupAction
            handleSearchFilter={() => form.submit()}
            handleClearFilter={clearFilter} />
          {moreButtons}
        </div>
      </div>
    </Form>);
};

export default withFilter;
