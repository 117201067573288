import * as actionTypes from '../constant/member.constants';

const handleSignin = (data) => ({
  type: actionTypes.USER_LOGIN,
  data: data
});

const handleSignout = () => ({
  type: actionTypes.USER_RESET
});

const handleUpdateDetail = (data) => ({
  type: actionTypes.USER_DETAILS_UPDATE,
  data: data
});

export { handleSignin, handleSignout, handleUpdateDetail };
