import moment from 'moment';

import './index.scss';
function Footer() {
  const year = moment().year();
  return (
    <footer className="container footer">
      Copyright © <span className="footer__text">Showniq</span> {year}.
    </footer>
  );
}
export default Footer;
