import { isEmpty } from 'lodash';

import { LOCAL_STORAGE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import { clearStorage } from 'common/shares';
import { USER_DETAILS_UPDATE, USER_LOGIN, USER_RESET } from 'redux/constant';
import routers from 'routes';
let initialState = {
   isUserLoggedIn: !!encryptStorage.getItem(LOCAL_STORAGE.isLogin)
};
const data = encryptStorage.getItem(LOCAL_STORAGE.data);
if (data && data.length) {
   const newData = data;
   initialState = {
      ...initialState,
      ...newData
   };
}
export default function userReducer(state = initialState, action) {
   switch (action.type) {
      case USER_LOGIN: {
         if (!isEmpty(action.data)) {
            encryptStorage.setItem(LOCAL_STORAGE.isLogin, true);
            encryptStorage.setItem(LOCAL_STORAGE.data, action.data);
            encryptStorage.setItem(LOCAL_STORAGE.token, action.data.token);
            encryptStorage.setItem(LOCAL_STORAGE.refreshToken, action.data.refreshToken);
            setTimeout(() => {
               window.location.replace(routers.dashboard.path);
            }, 300);
            return {
               ...state,
               ...action.data,
               isUserLoggedIn: true
            };
         }
         return {};
      }
      case USER_DETAILS_UPDATE: {
         if (action.data) {
            const data = {
               ...action.data
            };

            if (action.data.token) {
               encryptStorage.setItem(LOCAL_STORAGE.token, action.data.token);
               data.token = action.data.token;
            }
            encryptStorage.setItem(LOCAL_STORAGE.data, action.data);
            return {
               ...state,
               ...data,
               isUserLoggedIn: true
            };
         }
         return {};
      }
      case USER_RESET: {
         clearStorage();
         return {};
      }
      default:
         return state;
   }
}
