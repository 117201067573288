import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.themeGetList, params);
};

export const Update = async (id, data) => {
  return await configServices.putService(`${ApiEndpoints.themeUpdate}/${id}`, data, true, true);
};

export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.themeCreate, data, true, true);
};

export const Delete = async (id) => {
  return await configServices.deleteService(`${ApiEndpoints.themeDelete}/${id}`);
};

export const UpdateArrange = async (ids) => {
  return await configServices.putService(`${ApiEndpoints.themeArrange}`, { id: ids });
};
