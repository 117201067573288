import { createAsyncThunk } from '@reduxjs/toolkit';

import { CREATE_USER_LEVEL, DELETE_USER_LEVEL, FETCH_LIST_USER_LEVEL, UPDATE_USER_LEVEL } from 'redux/constant';
import UserLevelService from 'services/userLevel.service';
export const actionGetListUserLevel = createAsyncThunk(FETCH_LIST_USER_LEVEL, async (query) => {
  let result = {};
  await UserLevelService.getList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});


export const actionUpdateUserLevel = createAsyncThunk(UPDATE_USER_LEVEL, async (query) => {
  let result = {};
  const {id, data} = query;
  await UserLevelService.update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const actionCreateUserLevel = createAsyncThunk(CREATE_USER_LEVEL, async (query) => {
  let result = {};
  await UserLevelService.create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const actionDeleteUserLevel = createAsyncThunk(DELETE_USER_LEVEL, async (query) => {
  let result = {};
  await UserLevelService.delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});


