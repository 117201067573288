import { useEffect } from 'react';
import { connect } from 'react-redux';

import Header from 'components/Widtget/HeaderLoading';

function LayoutPageSecond(props) {
  const { Component, className } = props;

  useEffect(() => {
    const bodyId = document.getElementById('body-root');
    bodyId.classList.remove('forgot__pass');
    if (className !== '') {
      bodyId.classList.add(className);
    } else {
      bodyId.classList.toggle('id');
    }
  }, [className]);

  return (
    <>
      <Header className="login__header" />
      <Component {...props} />
    </>
  );
}

const mapStateToProps = (state) => ({
  member: state.member || {}
});

export default connect(mapStateToProps)(LayoutPageSecond);
