import { createSlice } from '@reduxjs/toolkit';

import {
  MagazineAction,
} from 'redux/actions';

export const magazineSlice = createSlice({
  name: 'magazine',
  initialState: {
    totalCount: 0,
    listData: [],
    detail: {},
    images: [],
    imagePath: '',
    isSuccess: false,
    isUpdateSuccess: false,
    isUpdateSectionAllSuccess: false,
    isUpdateSectionSuccess: false,
    isUploadSuccess: false,
    isCreateSuccess: false,
    isCreateSectionSuccess: false,
    isDeleteSuccess: false,
    isDeleteSectionSuccess: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    /* getList */
    builder.addCase(MagazineAction.GetList.pending, (state) => {
      state.isSuccess = false;
    });
    builder.addCase(MagazineAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { listData, totalCount } = data;
      state.listData = listData;
      state.isSuccess = isSuccess;
      state.totalCount = totalCount;
    });
    /* getDetail */
    builder.addCase(MagazineAction.GetDetail.pending, (state) => {
      state.isSuccess = false;
    });
    builder.addCase(MagazineAction.GetDetail.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.detail = data;
      state.isSuccess = isSuccess;
    });
    /* upload */
    builder.addCase(MagazineAction.Upload.pending, (state) => {
      state.isUploadSuccess = false;
    });
    builder.addCase(MagazineAction.Upload.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { imagePath, images } = data;
      state.isUploadSuccess = isSuccess;
      state.images = images;
      state.imagePath = imagePath;
      state.isUploadSuccess = isSuccess;
    });
    /* create */
    builder.addCase(MagazineAction.Create.pending, (state) => {
      state.message = '';
      state.isCreateSuccess = false;
    });
    builder.addCase(MagazineAction.Create.fulfilled, (state, action) => {
      const { isSuccess, data } = action.payload;
      state.detail = data;
      state.isCreateSuccess = isSuccess;
    });
    /* update */
    builder.addCase(MagazineAction.Update.pending, (state) => {
      state.message = '';
      state.isUpdateSuccess = false;
    });
    builder.addCase(MagazineAction.Update.fulfilled, (state, action) => {
      const { isSuccess } = action.payload;
      state.isUpdateSuccess = isSuccess;
    });
    /* create section */
    builder.addCase(MagazineAction.CreateSection.pending, (state) => {
      state.message = '';
      state.isCreateSectionSuccess = false;
    });
    builder.addCase(MagazineAction.CreateSection.fulfilled, (state, action) => {
      const { isSuccess } = action.payload;
      state.isCreateSectionSuccess = isSuccess;
    });
    /* update section all */
    builder.addCase(MagazineAction.UpdateSectionAll.pending, (state) => {
      state.message = '';
      state.isUpdateSectionAllSuccess = false;
    });
    builder.addCase(MagazineAction.UpdateSectionAll.fulfilled, (state, action) => {
      const { isSuccess } = action.payload;
      state.isUpdateSectionAllSuccess = isSuccess;
    });
    /* update section */
    builder.addCase(MagazineAction.UpdateSection.pending, (state) => {
      state.message = '';
      state.isUpdateSectionSuccess = false;
    });
    builder.addCase(MagazineAction.UpdateSection.fulfilled, (state, action) => {
      const { isSuccess } = action.payload;
      state.isUpdateSectionSuccess = isSuccess;
    });
    /* delete section */
    builder.addCase(MagazineAction.DeleteSection.pending, (state) => {
      state.message = '';
      state.isDeleteSectionSuccess = false;
    });
    builder.addCase(MagazineAction.DeleteSection.fulfilled, (state, action) => {
      const { isSuccess } = action.payload;
      state.isDeleteSectionSuccess = isSuccess;
    });
    /* delete */
    builder.addCase(MagazineAction.Delete.pending, (state) => {
      state.message = '';
      state.isDeleteSuccess = false;
    });
    builder.addCase(MagazineAction.Delete.fulfilled, (state, action) => {
      const { isSuccess } = action.payload;
      state.isDeleteSuccess = isSuccess;
    });
  }
});

export default magazineSlice.reducer;
