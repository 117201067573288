import { createSlice } from '@reduxjs/toolkit';

import {
  QnACategoryAction
} from 'redux/actions';

export const qnaCategorySlice = createSlice({
  name: 'qnaCategory',
  initialState: {
    listData: [],
    listDataLite: [],
    detail: {},
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(QnACategoryAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(QnACategoryAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData, totalCount} = data;
      state.listData = listData;
      state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(QnACategoryAction.GetListLite.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(QnACategoryAction.GetListLite.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.listDataLite = data;
      state.isSuccess = isSuccess;
    });
  }
});

export const { clearStoreListLite } = qnaCategorySlice.actions;

export default qnaCategorySlice.reducer;
