import React, { useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import classNames from 'classnames';
import { get } from 'lodash';

import styles from './index.module.scss';
import ApiEndpoints from 'common/apiEndPoints';
import { makeId } from 'common/shares';
import configServices from 'services/configService';

import 'react-quill/dist/quill.snow.css';

const Editor = (props) => {
  const {
    className,
    onChange,
    data,
    placeholder,
    _id, type,
    ...restProps
  } = props;
  const quillRef = useRef(null);

  const Link = Quill.import('formats/link');
  Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

  const insertToEditor = (url) => {
    const quillObj = quillRef?.current?.getEditor();
    const range = quillObj?.getSelection();
    quillObj.insertEmbed(range.index, 'image', url);
  };

  const handleUploadImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('_id', _id || makeId(12, 'create_'));
    formData.append('type', type);
    let fileURL = '';
    if (!file) return;
    const result = await configServices.postService(ApiEndpoints.fileUpload, formData, true, true);
    fileURL = get(result, ['data'], '');
    insertToEditor(get(fileURL, [0], ''));
  };


  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async function () {
      const file = input.files[0];
      if (handleUploadImage) {
        handleUploadImage(file);
      }
    };
  };

  class CustomLinkSanitizer extends Link {
    static sanitize(url) {
      return url;
    }
  }

  Quill.register(CustomLinkSanitizer, true);

  const modules = useMemo(() => ({
    toolbar: {
      handlers: {
        image: imageHandler,
      },
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image'],
        [{ 'align': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        ['clean']
      ]
    },
  }), []);

  return (
    <ReactQuill
      className={classNames('editor-custom', styles.editorCustom, className)}
      value={data ?? props?.value}
      onChange={onChange}
      ref={quillRef}
      theme="snow"
      modules={modules}
      placeholder={placeholder}
      {...restProps}
    />
  );
};

export default Editor;