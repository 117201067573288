import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.feedbackGetList, params);
};
export const Update= async (id, data) => {
  return await configServices.putService(`${ApiEndpoints.feedbackUpdate}/${id}`, data);
};
export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.feedbackCreate, data);
};
export const Delete = async (id) => {
  return await configServices.deleteService(`${ApiEndpoints.feedbackDelete}/${id}`);
};
