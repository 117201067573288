import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetTotalList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.reportSearchGetTotalList, params);
};

export const GetStatistic = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.reportSearchGetStatistic, params);
};
