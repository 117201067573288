import { createSlice } from '@reduxjs/toolkit';

import {   MemberMgmtAction,
SellerRegisterAction} from 'redux/actions';

export const sellerRegisterSlice = createSlice({
  name: 'sellerRegister',
  initialState: {
    listData: [],
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(SellerRegisterAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(SellerRegisterAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData, totalCount} = data;
      state.listData = listData;
      state.isSuccess = isSuccess;
      state.totalCount = totalCount;
    });
    builder.addCase(MemberMgmtAction.ApproveSeller.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(MemberMgmtAction.DenySeller.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});

export default sellerRegisterSlice.reducer;
