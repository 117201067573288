import { createSlice } from '@reduxjs/toolkit';

import {
  MemberMgmtAction
} from 'redux/actions';

export const memberMgmtSlice = createSlice({
  name: 'memberMgmt',
  initialState: {
    listData: [],
    ListPointHistory: [],
    isSuccess: false,
    isExportSuccess: null,
    totalCount: 0,
    totalUNQCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(MemberMgmtAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(MemberMgmtAction.Export.pending, (state) => {
      state.isExportSuccess = null;
    });
    builder.addCase(MemberMgmtAction.ExportByType.pending, (state) => {
      state.isExportSuccess = null;
    });
    builder.addCase(MemberMgmtAction.GetListPointHistory.pending, (state) => {
      state.isSuccess = false;
      state.totalUNQCount = 0;
    });
    builder.addCase(MemberMgmtAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { listData, totalCount } = data;
      state.listData = listData;
      state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(MemberMgmtAction.Export.fulfilled, (state) => {
      state.isExportSuccess = true;
    });
    builder.addCase(MemberMgmtAction.ExportByType.fulfilled, (state) => {
      state.isExportSuccess = true;
    });
    builder.addCase(MemberMgmtAction.GetListPointHistory.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { listData, totalCount } = data;
      state.ListPointHistory = listData;
      state.isSuccess = isSuccess;
      state.totalUNQCount = totalCount;
    });
    builder.addCase(MemberMgmtAction.Update.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(MemberMgmtAction.ApproveSeller.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(MemberMgmtAction.DenySeller.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});

export default memberMgmtSlice.reducer;
