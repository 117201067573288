import { createSlice } from '@reduxjs/toolkit';

import { ThemeStyleAction } from 'redux/actions';

export const themeStyleSlice = createSlice({
    name: 'themeStyle',
    initialState: {
        listData: [],
        isSuccess: false,
        totalCount: 0,
        message: ''
    },
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(ThemeStyleAction.GetList.pending, (state) => {
            state.message = '';
            state.isSuccess = false;
            state.totalCount = 0;
        });
        builder.addCase(ThemeStyleAction.GetList.fulfilled, (state, action) => {
            const { data, isSuccess } = action.payload;
            const { listData, totalCount } = data;
            state.listData = listData;
            state.totalCount = totalCount;
            state.isSuccess = isSuccess;
        });
        builder.addCase(ThemeStyleAction.AddTheme.fulfilled, (state, action) => {
            const { isSuccess, message } = action.payload;
            state.message = message;
            state.isSuccess = isSuccess;
        });
        builder.addCase(ThemeStyleAction.DeleteTheme.fulfilled, (state, action) => {
            const { isSuccess, message } = action.payload;
            state.message = message;
            state.isSuccess = isSuccess;
        });
        builder.addCase(ThemeStyleAction.ClearTheme.fulfilled, (state) => {
            state.listData = [];
            state.totalCount = 0;
            state.isSuccess = true;
        });
    }
});

export default themeStyleSlice.reducer;