import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.eventGetList, params);
};
export const GetDetail = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.eventGetDetail, params);
};
export const Update= async (id, data) => {
  return await configServices.putService(`${ApiEndpoints.eventUpdate}/${id}`, data, true, true);
};
export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.eventCreate, data, true, true);
};

