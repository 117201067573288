import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, Form, Input, Select } from 'antd';

import { CodiconAccount, Eye, MoveIcon } from 'assets/icons';
import WrapperLogin from 'components/WrapperLogin';
import { changeSetting } from 'redux/actions';
import routes from 'routes';

const { Option } = Select;

function LoginComponent(props) {
  const { isVisible, recaptcha, onFinish, language, form = { form } } = props;
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="container">
      <WrapperLogin>
        <div className="login">
          <div className="login__parent">
            <Form
              name="login"
              autoComplete="off"
              form={form}
              onFinish={async (values) => {
                onFinish(values);
              }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: translation('login.inputEmail')
                  }
                ]}
              >
                <div className="login__input__icon">
                  <Input
                    className="login__input"
                    placeholder="Username *"
                    type="text"
                    size="large"
                    prefix={<CodiconAccount className="login__iconLeft" />}
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: translation('login.inputPassword')
                  }
                ]}
              >
                <div className="login__input__icon">
                  <Input
                    className="login__input"
                    type="password"
                    placeholder={translation('login.password')}
                    size="large"
                    prefix={<Eye className="login__iconLeft" />}
                    suffix={<MoveIcon className="login__iconRight" />}
                  />
                </div>
              </Form.Item>

              <div className="login__parent  flex  mb-3">
                <Select
                  onChange={(value) => {
                    dispatch(
                      changeSetting({
                        language: value
                      })
                    );
                  }}
                  className="login__select white"
                  value={language}
                  size="small"
                >
                  <Option value="vi">{translation('login.langVi')}</Option>
                  <Option value="en">{translation('login.langEng')}</Option>
                  <Option value="kr">{translation('login.langKr')}</Option>
                </Select>
                <Checkbox className="login__left__auto">
                  <div className="white">{translation('login.remember')}</div>
                </Checkbox>
              </div>
              {recaptcha ? (
                <Form.Item
                  name="capChaValue"
                  rules={[
                    {
                      required: true,
                      message: translation('thisFieldNotAllowEmpty')
                    }
                  ]}
                >
                  <div id="recaptcha-container" className="g-recaptcha mb-2"></div>
                </Form.Item>
              ) : null}
              <div className="w-full flex justify-center">
                <Button
                  className="login__button blue_button w-full"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isVisible}
                >
                  {translation('login.title')}
                </Button>
              </div>
              <div
                onClick={() => {
                  history.push(routes.forgotPass.path);
                }}
                className="login__forget mt-3 white"
              >
                {translation('login.forgot')}
              </div>
            </Form>
          </div>
        </div>
      </WrapperLogin>
    </div>
  );
}
export default LoginComponent;
