import { createAsyncThunk } from '@reduxjs/toolkit';

import { AttributeConstants } from 'redux/constant';
import { AttributeServices } from 'services/index';

export const GetList = createAsyncThunk(AttributeConstants.GetList, async(query) => {
    let result = {};
    await AttributeServices.GetList(query).then((res) => {
        const { code, data } = res;
        const isSuccess = code === 200;
        if (isSuccess) {
            result = { data, isSuccess };
        }
    });
    return result;
});