import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { forEach, get, isEmpty, isUndefined } from 'lodash';

import ActionHeader from './ActionHeader';
import FilterHeader from './FilterHeader';
import ModalNotification from './ModalNotification';
import TableBody from './TableBody';
import withPage from 'common/hoc/withPage';
import { NotificationAction } from 'redux/actions';

import './style.scss';
const NotificationPage = (props) => {

  const {
    data,
    filters, setFilters,
    setIsFetch,
    loading,
    totalCount,
    message: messageUpdateSuccess,
    fetchList,
    updateFilter,
    modal, setModal,
    isCanAdd,
  } = props;

  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  React.useEffect(() => {
    if (messageUpdateSuccess) {
      setTimeout(() => {
        toast.success(translation('actionSuccess'));
        setModal(preModal => ({
          ...preModal,
          open: false,
          loading: false
        }));
        fetchList();
      }, 300);
    }
  }, [messageUpdateSuccess]);

  const orderBy = [
    {
      value: 'updatedAt',
      label: translation('sortByFilter.notificationUpdateTime')
    },
    {
      value: 'createdAt',
      label: translation('sortByFilter.notificationCreateTime')
    }
  ];

  const handleUpdateModal = (values) => {
    const id = get(values, '_id', '');
    const file = get(values, 'file.file', null);
    const formData = new FormData();

    formData.append('notificationType', get(values, 'notificationType.value'));
    formData.append('notificationGroupId', get(values, 'notificationGroupId'));
    formData.append('sendOneTimes', !get(values, 'sendOneTimes'));
    
    formData.append('title', get(values, 'title'));
    formData.append('summary', get(values, 'summary'));
    formData.append('gender', get(values, 'gender.value'));
    
    if(!isEmpty(get(values, 'time'))) {
      if(isUndefined(get(values, 'time.dayOfWeek'))) {
        values.time.dayOfWeek = null;
      }
      if(isUndefined(get(values, 'time.dayOfMonth'))) {
        values.time.dayOfMonth = null;
      }
      
      formData.append('time', JSON.stringify(get(values, 'time')));
    }


    if (!isEmpty(get(values, ['googleAnalytics', 'eventValue'], ''))) {
      formData.append('googleAnalyticsEventValue', get(values, ['googleAnalytics', 'eventValue'], ''));
    }
    if (!isEmpty(get(values, ['googleAnalytics', 'eventName'], ''))) {
      formData.append('googleAnalyticsEventName', get(values, ['googleAnalytics', 'eventName'], ''));
    }
    if (!isEmpty(get(values, ['googleAnalytics', 'eventParams'], ''))) {
      formData.append('googleAnalyticsEventParams', get(values, ['googleAnalytics', 'eventParams'], ''));
    }
    if (!isEmpty(get(values, ['adjust', 'eventName'], ''))) {
      formData.append('adjustEventName', get(values, ['adjust', 'eventName'], ''));
    }
    if (!isEmpty(get(values, ['adjust', 'eventToken'], ''))) {
      formData.append('adjustEventToken', get(values, ['adjust', 'eventToken'], ''));
    }
    if (file) {
      formData.append('file', file);
    }
    if (!isEmpty(values.navigateAppParam)) {
      const navigateAppParam = {};
      forEach(values.navigateAppParam, item => {
        if (item.key) {
          navigateAppParam[item.key] = item.value;
        }
      });
      if(!isEmpty(navigateAppParam)){
        formData.append('navigateAppParam', JSON.stringify(navigateAppParam));
      }
    }
    if (values.navigateAppPage) {
      formData.append('navigateAppPage', get(values, 'navigateAppPage'));
    }
    formData.append('status', get(values, 'status') ? 'A' : 'F');
    formData.append('content', get(values, 'content'));

    if (!id) {
      dispatch(NotificationAction.Create(formData));
    } else {
      dispatch(
        NotificationAction.Update({
          id,
          data: formData
        })
      );
    }
  };

  return (
    <div className='notification-mgmt-page'>
      <FilterHeader
        /* default */
        updateFilter={updateFilter}
        filters={filters}
        setFilters={setFilters}
        setIsFetch={setIsFetch}
        fetchList={fetchList}
        loading={loading}

        data={data}
        orderBy={orderBy}
      />
      <ActionHeader
        setModal={setModal}
        isCanAdd={isCanAdd}
      />
      <TableBody
        data={data}
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        fetchList={fetchList}
        totalCount={totalCount}

        /* modal */
        setModal={setModal}
      />
      <ModalNotification
        modal={modal}
        setModal={setModal}
        updateModal={handleUpdateModal}
      />
    </div>
  );
};

export default withPage({})(NotificationPage);
