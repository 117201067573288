import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.productGetList, params);
};
export const Update = async (id, data) => {
  return await configServices.putService(`${ApiEndpoints.productUpdate}/${id}`, data);
};
export const UpdateStatus = async (params = {}) => {
  return await configServices.putService(ApiEndpoints.productUpdateStatus, params);
};

