import { createAsyncThunk } from '@reduxjs/toolkit';

import { StoreMainStyleConstants } from 'redux/constant';
import { StoreMainStyleServices } from 'services/index';

export const GetList = createAsyncThunk(StoreMainStyleConstants.GetList, async (query) => {
    let result = {};
    await StoreMainStyleServices.GetList(query).then((res) => {
        const { code, data } = res;
        const isSuccess = code === 200;
        if (isSuccess) {
            result = { data, isSuccess };
        }
    });
    return result;
});

export const SetMainStyle = createAsyncThunk(StoreMainStyleConstants.SetMainStyle, async (query) => {
    let result = {};
    await StoreMainStyleServices.SetListStyle(query).then((res) => {
        const { code, message } = res;
        const isSuccess = code === 200;
        if (isSuccess) {
            result = { isSuccess, message };
        }
    });
    return result;
});

export const UnSetMainStyle = createAsyncThunk(StoreMainStyleConstants.UnSetMainStyle, async (query) => {
    let result = {};
    await StoreMainStyleServices.UnSetListStyle(query).then((res) => {
        const { code, message } = res;
        const isSuccess = code === 200;
        if (isSuccess) {
            result = { isSuccess, message };
        }
    });
    return result;
});
export const ClearMainStyle = createAsyncThunk(StoreMainStyleConstants.ClearMainStyle, async () => {
    let result = {};
    return result;
});