import { createAsyncThunk } from '@reduxjs/toolkit';

import { FeedbackConstants } from 'redux/constant';
import { FeedbackService } from 'services/index';
export const GetList = createAsyncThunk(FeedbackConstants.FETCH_LIST_FEEDBACK, async (query) => {
  let result = {};
  await FeedbackService.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});


export const Update = createAsyncThunk(FeedbackConstants.UPDATE_FEEDBACK, async (query) => {
  let result = {};
  const {id, data} = query;
  await FeedbackService.Update(id, data).then((res) => {
    const { code, message } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Create = createAsyncThunk(FeedbackConstants.CREATE_FEEDBACK, async (query) => {
  let result = {};
  await FeedbackService.Create(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});

export const Delete = createAsyncThunk(FeedbackConstants.DELETE_FEEDBACK, async (query) => {
  let result = {};
  await FeedbackService.Delete(query).then((res) => {
    const { message, code } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { isSuccess, message };
    }
  });
  return result;
});


