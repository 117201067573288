import { createSlice } from '@reduxjs/toolkit';

import { MenuAction } from 'redux/actions';

export const menuSlice = createSlice({
  name: 'menuList',
  initialState: {
    data: [],
    listAction: [],
    listAPI: [],
    isSuccess: false,
    isSuccessUpdate: false,
    message: '',
    messageUpdate: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(MenuAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(MenuAction.GetListLite.pending, (state) => {
      state.isSuccessUpdate = false;
    });
    builder.addCase(MenuAction.GetListApi.pending, (state) => {
      state.isSuccessUpdate = false;
    });
    builder.addCase(MenuAction.CreateMenuPermission.pending, (state) => {
      state.isSuccessUpdate = false;
      state.messageUpdate = '';
    });
    builder.addCase(MenuAction.CreateMenuPermission.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.isSuccessUpdate = isSuccess;
      state.messageUpdate=message;
    });
    builder.addCase(MenuAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData, totalCount} = data;
      state.data = listData;
      state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(MenuAction.GetListApi.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData} = data;
      state.listAPI = listData;
      state.isSuccessUpdate = isSuccess;
    });
    builder.addCase(MenuAction.GetListLite.fulfilled, (state, action) => {
      const { data:listData, isSuccess } = action.payload;
      state.listAction = listData;
      state.isSuccessUpdate = isSuccess;
    });
    builder.addCase(MenuAction.Update.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(MenuAction.EditApi.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(MenuAction.Create.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });

    builder.addCase(MenuAction.Delete.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});

export default menuSlice.reducer;
