import { createSlice } from '@reduxjs/toolkit';

import {
  ReportSearchAction
} from 'redux/actions';

export const reportSearchSlice = createSlice({
  name: 'reportSearch',
  initialState: {
    panelLeft: {
      listData: [],
      isSuccess: false,
      totalCount: 0,
      message: ''
    },
    byGender: {
      listData: [],
      isSuccess: false,
      totalCount: 0,
      message: ''
    },
    byDaily: {
      listData: [],
      isSuccess: false,
      totalCount: 0,
      message: ''
    }
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(ReportSearchAction.GetTotalList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { listData, totalCount } = data;
      state.panelLeft.listData = listData;
      state.panelLeft.totalCount = totalCount;
      state.panelLeft.isSuccess = isSuccess;
    });
    builder.addCase(ReportSearchAction.GetStatisticByDaily.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { listData, totalCount } = data;
      state.byDaily.listData = listData;
      state.byDaily.totalCount = totalCount;
      state.byDaily.isSuccess = isSuccess;
    });
    builder.addCase(ReportSearchAction.GetStatisticByGender.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const { listData, totalCount } = data;
      state.byGender.listData = listData;
      state.byGender.totalCount = totalCount;
      state.byGender.isSuccess = isSuccess;
    });
  }
});

export const { clearStoreListLite } = reportSearchSlice.actions;

export default reportSearchSlice.reducer;
