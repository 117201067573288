import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.storeGetList, params);
};

export const GetStoreByInstagramLink = async (link) => {
  return await configServices.postService(ApiEndpoints.storeGetStoreByInstagramLink, {link});
};

export const GetListLite = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.storeGetListLite, params);
};
export const Update= async (id, data) => {
  return await configServices.putService(`${ApiEndpoints.storeUpdate}/${id}`, data, true, true);
};
export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.storeCreate, data, true, true);
};
export const Delete = async (id) => {
  return await configServices.deleteService(`${ApiEndpoints.storeDelete}/${id}`);
};


export default {GetStoreByInstagramLink};