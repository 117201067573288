import { createSlice } from '@reduxjs/toolkit';

import { RequestEntranceAction
} from 'redux/actions';

export const requestEntranceSlice = createSlice({
  name: 'requestEntrance',
  initialState: {
    listData: [],
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(RequestEntranceAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(RequestEntranceAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData, totalCount} = data;
      state.listData = listData;
      state.isSuccess = isSuccess;
      state.totalCount = totalCount;
    });
  }
});

export default requestEntranceSlice.reducer;
