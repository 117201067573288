import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async(params = {}) => {
    return await configServices.getService(ApiEndpoints.themeStyleGetList, params);
};

export const AddListStyle = async(data = {}) => {
    return await configServices.postService(ApiEndpoints.themeStyleAddStyle, data, true, false);
};

export const DeleteListStyle = async(params = {}) => {
    return await configServices.deleteService(ApiEndpoints.themeStyleDeleteStyle, params);
};