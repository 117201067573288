import { loadingBarReducer } from 'react-redux-loading-bar';

import app from './app.reducer';
import appNavigateReducer from './appNavigate.reducer';
import attributeReducer from './attribute.reducer';
import attributeAgeReducer from './attributeAge.reducer';
import attributeCatalogReducer from './attributeCatalog.reducer';
import attributeCategoryReducer from './attributeCategory.reducer';
import attributeColorReducer from './attributeColor.reducer';
import attributePatternReducer from './attributePattern.reducer';
import cityReducer from './city.reducer';
import drawer from './drawer.reducer';
import enumR from './enum.reducer';
import eventReducer from './event.reducer';
import eventLevelReducer from './eventLevel.reducer';
import faqReducer from './faq.reducer';
import feedbackReducer from './feedback.reducer';
import globalReducer from './global.reducer';
import loading from './loading.reducer';
import magazineReducer from './magazine.reducer';
import mediaReducer from './media.reducer';
import memberReducer from './member.reducer';
import memberLevelReducer from './memberLevel.reducer';
import memberMgmtReducer from './memberMgmt.reducer';
import menuReducer from './menu.reducer';
import mobileConfigReducer from './mobileConfig.slice';
import noticeReducer from './notice.reducer';
import notificationReducer from './notification.reducer';
import notificationGroupReducer from './notificationGroup.reducer';
import platformReducer from './platform.reducer';
import productReducer from './product.reducer';
import qnaReducer from './qna.reducer';
import qnaCategoryReducer from './qnaCategory.reducer';
import reportSearchReducer from './reportSearch.reducer';
import requestEntranceReducer from './requestEntrance.reducer';
import sellerRegisterReducer from './sellerRegister.reducer';
import setting from './setting.reducer';
import storeReducer from './store.reducer';
import storeAddressReducer from './storeAddress.reducer';
import storeMainStyleReducer from './storeMainStyle.reducer';
import styleReducer from './style.reducer';
import themeMgmtReducer from './themeMgmt.reducer';
import ThemeStyleReducer from './themeStyle.reducer';
import totalCountReducer from './totalCountMgmt.slice';
import userLevelReducer from './userLevel.reducer';
import userMgmtReducer from './userMgmt.reducer';
import permissionReducer from './permission.reducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  app,
  setting,
  drawer,
  loading,
  enum: enumR,
  reportSearch: reportSearchReducer,
  loadingBar: loadingBarReducer,
  global: globalReducer,
  menu: menuReducer,
  member: memberReducer,
  userLevel: userLevelReducer,
  eventCategory: eventLevelReducer,
  userMgmt: userMgmtReducer,
  store: storeReducer,
  memberMgmt: memberMgmtReducer,
  memberLevel: memberLevelReducer,
  notice: noticeReducer,
  notification: notificationReducer,
  notificationGroup: notificationGroupReducer,
  event: eventReducer,
  platform: platformReducer,
  feedback: feedbackReducer,
  themes: themeMgmtReducer,
  // comments: totalCommentReducer,
  storeAddress: storeAddressReducer,
  city: cityReducer,
  comments: totalCountReducer,
  review: totalCountReducer,
  faq: faqReducer,
  qna: qnaReducer,
  qnaCategory: qnaCategoryReducer,
  style: styleReducer,
  product: productReducer,
  storeStyle: productReducer,
  storeMainStyle: storeMainStyleReducer,
  attribute: attributeReducer,
  attributeAge: attributeAgeReducer,
  attributeCatalog: attributeCatalogReducer,
  attributeCategory: attributeCategoryReducer,
  attributePattern: attributePatternReducer,
  attributeColor: attributeColorReducer,
  preference: totalCountReducer,
  themeStyle: ThemeStyleReducer,
  mobileConfig: mobileConfigReducer,
  media: mediaReducer,
  appNavigate: appNavigateReducer,
  magazine: magazineReducer,
  sellerRegister: sellerRegisterReducer,
  requestEntrance: requestEntranceReducer,
  permission: permissionReducer,
});

export default rootReducer;
