import { createSlice } from '@reduxjs/toolkit';

import {
    StoreAddressAction
} from 'redux/actions';

export const storeAddressSlice = createSlice({
    name: 'storeAddress',
    initialState: {
        listData: [],
        listDataLite: [],
        isSuccess: false,
        totalCount: 0,
        message: ''
    },
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(StoreAddressAction.GetList.pending, (state) => {
            state.message = '';
            state.isSuccess = false;
            state.totalCount = 0;
        });
        builder.addCase(StoreAddressAction.GetList.fulfilled, (state, action) => {
            const { data, isSuccess } = action.payload;
            const { listData, totalCount } = data;
            state.listData = listData;
            state.totalCount = totalCount;
            state.isSuccess = isSuccess;
        });
        builder.addCase(StoreAddressAction.Update.fulfilled, (state, action) => {
            const { isSuccess, message } = action.payload;
            state.message = message;
            state.isSuccess = isSuccess;
        });
        builder.addCase(StoreAddressAction.Create.fulfilled, (state, action) => {
            const { isSuccess, message } = action.payload;
            state.message = message;
            state.isSuccess = isSuccess;
        });
        builder.addCase(StoreAddressAction.Delete.fulfilled, (state, action) => {
            const { isSuccess, message } = action.payload;
            state.message = message;
            state.isSuccess = isSuccess;
        });
    }
});

export default storeAddressSlice.reducer;