import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Modal } from 'antd';

function ModalPasswordProfile(props) {
  const { isModalVisible, setIsModalVisible, data = {}, handleResetPassword } = props;
  const { t: translation } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ ...data });
    if (data.message) {
      form.setFields(data.message);
    }
  }, [data]);
  return (
    <>
      <Modal
        forceRender
        getContainer={false}
        title={`${translation('headerMenu.changePassword')}`}
        footer={null}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form
          form={form}
          initialValues={{}}
          onFinish={(values) => {
            handleResetPassword(values, () => {
              form.resetFields();
              setIsModalVisible(false);
            });
          }}
        >
          <div className="mb-3 flex items-center w-full">
            <div className="w-200">
              {translation('currentPassword')} <span className="danger">*</span>
            </div>

            <Form.Item
              name="oldPassw"
              className="w-full detailQc__form__input"
              rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
            >
              <Input.Password className="w-full detailQc__form__input" />
            </Form.Item>
          </div>
          <div className="mb-3 flex items-center w-full">
            <div className="w-200">
              {translation('newPassword')} <span className="danger">*</span>
            </div>

            <Form.Item
              name="passw"
              className="w-full detailQc__form__input"
              rules={[{ required: true, message: translation('thisFieldNotAllowEmpty') }]}
            >
              <Input.Password className="w-full detailQc__form__input" />
            </Form.Item>
          </div>
          <div className="mb-3 flex items-center w-full">
            <div className="w-200">
              {translation('login.confirmPassword')} <span className="danger">*</span>
            </div>

            <Form.Item
              name="rePassw"
              className="w-full detailQc__form__input"
              dependencies={['passw']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: translation('thisFieldNotAllowEmpty')
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('passw') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(translation('login.notMatch')));
                  }
                })
              ]}
            >
              <Input.Password className="w-full detailQc__form__input" />
            </Form.Item>
          </div>

          <div className="flex items-center w-full mt-3 justify-end">
            <Button
              onClick={() => {
                setIsModalVisible(false);
              }}
              className="btn-cancel mr-1 button-custom-hover"
            >
              {translation('cancel')}
            </Button>
            <Button htmlType="submit" type="primary" className="ml-1 button-custom-hover">
              {translation('save')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
export default ModalPasswordProfile;
