import LoadingBar from 'react-redux-loading-bar';

import './index.scss';
function HeaderHome(props) {
  const { className } = props;
  return (
    <header className={`header w-full ${className}`}>
      <LoadingBar
        updateTime={100}
        maxProgress={95}
        progressIncrease={10}
        style={{
          backgroundColor: '#73F9BC',
          height: '3px',
          position: 'absolute',
          bottom: '-1px'
        }}
      />
    </header>
  );
}
export default HeaderHome;
