import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';

export const GetList = async (params = {}) => {
  return await configServices.getService(ApiEndpoints.attributeCategoryGetList, params);
};
export const Create = async (data = {}) => {
  return await configServices.postService(ApiEndpoints.attributeCategoryCreate, data, true, true);
};
export const UpdateArrange = async (data = {}) => {
  return await configServices.putService(ApiEndpoints.attributeCategoryUpdateArrange, data);
};
export const Update = async ({ id, data }) => {
  return await configServices.putService(`${ApiEndpoints.attributeCategoryUpdate}/${id}`, data, true, true);
};