import { createSlice } from '@reduxjs/toolkit';

import {
  AttributeColorAction
} from 'redux/actions';

export const attributeColorSlice = createSlice({
  name: 'attributeColor',
  initialState: {
    listData: [],
    detail: {},
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(AttributeColorAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(AttributeColorAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
        const { listData, totalCount } = data;
        state.listData = listData;
        state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(AttributeColorAction.Create.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(AttributeColorAction.UpdateArrange.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(AttributeColorAction.Update.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});


export default attributeColorSlice.reducer;