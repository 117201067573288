import Request from './request.service';
export default class PermissionService {
  static async getListPermission(queryString = '') {
    return new Promise((resolve) => {
      Request.send({
        method: 'GET',
        path: `bo/menu/permission?${queryString}`
      }).then((result = {}) => {
        const { code, data, message } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }
  static async getListUser(queryString = '') {
    return new Promise((resolve) => {
      Request.send({
        method: 'GET',
        path: `bo/user/getListLite?${queryString}`
      }).then((result = {}) => {
        const { code, data, message } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }
  static async updatePermission(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'PUT',
        path: 'bo/menu/permissionUpdate',
        data
      }).then((result = {}) => {
        const { code, data, errors } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message: errors });
        }
      });
    });
  }
}
