import configServices from './configService';
import ApiEndpoints from 'common/apiEndPoints';
export default class UserLevelService {
  static async getList(params = {}) {
    return await configServices.getService(ApiEndpoints.userLevelGetList, params);
  }
  static async update(id, data) {
    return await configServices.putService(`${ApiEndpoints.userLevelUpdate}/${id}`, data);
  }
  static async create(data = {}) {
    return await configServices.postService(ApiEndpoints.userLevelCreate, data);
  }
  static async delete(id = '') {
    return await configServices.deleteService(`${ApiEndpoints.userLevelDelete}/${id}`);
  }
}
