import { createSlice } from '@reduxjs/toolkit';

import { AppNavigateAction } from 'redux/actions';

export const appNavigateSlice = createSlice({
  name: 'appNavigate',
  initialState: {
    listData: [],
    totalCount: 0,
    isSuccess: false,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(AppNavigateAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(AppNavigateAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData} = data;
      state.isSuccess = isSuccess;
      state.listData = listData;
    });
  }
});

export default appNavigateSlice.reducer;