import Request from './request.service';
export default class enumService {
  static async getList(queryString = '') {
    return new Promise((resolve) => {
      Request.send({
        method: 'GET',
        path: `common/getEnum?${queryString}`
      }).then((result = {}) => {
        const { code, data, message } = result;

        if (code === 200) {
          return resolve({ isSuccess: true, data });
        } else {
          return resolve({ isSuccess: false, message });
        }
      });
    });
  }
}
