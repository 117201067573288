import { createSlice } from '@reduxjs/toolkit';

import {
  AttributeCategoryAction
} from 'redux/actions';

export const attributeCategorySlice = createSlice({
  name: 'attributeCategory',
  initialState: {
    listData: [],
    detail: {},
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(AttributeCategoryAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(AttributeCategoryAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      if (data?.listData) {
        const { listData, totalCount } = data;
        state.listData = listData;
        state.totalCount = totalCount;
      } else {
        state.listData = data;
      }
      state.isSuccess = isSuccess;
    });
    builder.addCase(AttributeCategoryAction.Create.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(AttributeCategoryAction.UpdateArrange.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
    builder.addCase(AttributeCategoryAction.Update.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});


export default attributeCategorySlice.reducer;