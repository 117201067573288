import { Spin } from 'antd';

import './index.scss';
function Loader() {
  return (
    <div>
      <div className="w-full text-center loader loader__second">
        <Spin></Spin>
      </div>
    </div>
  );
}
export default Loader;
