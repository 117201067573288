
import { LIGHT, LOCAL_STORAGE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import { SETTING } from 'redux/constant';

let initialState = {
  lang: 'vi',
  theme: LIGHT,
  blocking: false,
};

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case SETTING: {

      encryptStorage.setItem(
        LOCAL_STORAGE.setting,
        {
          ...state,
          ...action.data
        }
      );
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}
