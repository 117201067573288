import axios from 'axios';
import i18next from 'i18next';
import _, { get } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

import { LOCAL_STORAGE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import { getLanguage, toastError } from 'common/shares';

import { HOST } from '../redux/constant/url';

const getToken = () => {
  const dataString = encryptStorage.getItem(LOCAL_STORAGE.data);
  if (dataString) {
    const newData = dataString;
    return newData.token;
  }
  return '';
};

const _responseConfig = async (response) => {
  try {
    const responseData = await response.json();
    if (responseData.status !== 'failure') return responseData;
    const errorObj = responseData.errors ? responseData.errors[0] : {};
    const lang = getLanguage();
    const message = errorObj.error ? errorObj.error[lang] : 'Server error';
    throw Error(message);
  } catch (error) {
    throw Error(error?.message || 'Server error');
  }
};

const postService = async (url, body, isAuthorization = true, isFormData = false) => {
  try {
    const headers = isFormData
      ? {}
      : { Accept: 'application/json', 'Content-Type': 'application/json' };
    if (isAuthorization) {
      headers.Authorization = `Bearer ${getToken()}`;
    }
    const requestInit = { method: 'POST', headers };
    if (body)
      if (isFormData) requestInit.body = body;
      else requestInit.body = JSON.stringify(body);

    const response = await fetch(`${HOST}${url}`, requestInit);

    return await _responseConfig(response);
  } catch (error) {
    toastError(error.message);
    return { error: true };
  }
};

const getService = async (url, params, body, isAuthorization = true) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    if (isAuthorization) {
      headers.Authorization = `Bearer ${getToken()}`;
    }
    const requestInit = { method: 'GET', headers };
    if (body) requestInit.body = JSON.stringify(body);
    let stringifyParams = `${url}?`;
    if (params && !_.isEmpty(params)) {
      stringifyParams += queryString.stringify(params, { arrayFormat: 'bracket' });
    }
    const response = await fetch(`${HOST}${stringifyParams}`, requestInit);
    return await _responseConfig(response);
  } catch (error) {
    toastError(error.message);
    return { error: true };
  }
};

const deleteService = async (url, body) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`
    };
    const requestInit = { method: 'DELETE', headers };
    if (body) requestInit.body = JSON.stringify(body);
    const response = await fetch(`${HOST}${url}`, requestInit);

    return await _responseConfig(response);
  } catch (error) {
    toastError(error.message);
    return { error: true };
  }
};

const putService = async (url, body, isAuthorization = true, isFormData = false) => {
  try {
    const headers = isFormData
      ? {}
      : { Accept: 'application/json', 'Content-Type': 'application/json' };
    if (isAuthorization) {
      headers.Authorization = `Bearer ${getToken()}`;
    }
    const requestInit = { method: 'PUT', headers };
    if (body)
      if (isFormData) requestInit.body = body;
      else requestInit.body = JSON.stringify(body);

    const response = await fetch(`${HOST}${url}`, requestInit);

    return await _responseConfig(response);
  } catch (error) {
    toastError(error.message);
    return { error: true };
  }
};

export const downloadXLSFile = async (url, params) => {
  try {
    let stringifyParams = `${url}?`;
    if (params && !_.isEmpty(params)) {
      stringifyParams += queryString.stringify(params);
    }
    await axios.get(`${HOST}${stringifyParams}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
      responseType: 'blob'
    })
      .then(async response => {
        const contentType = get(response, ['headers', 'content-type']);
        if (contentType !== 'text/csv') {
          const dataError = JSON.parse(await response.data?.text());
          if (dataError?.status === 'failure') {
            const lang = getLanguage();
            toastError(get(dataError, ['errors', 0, 'error', lang])
              || i18next.t('exportMemberMgmtError'), { autoClose: 3000 }
            );
          }
          return { code: 404 };
        } else {
          const outputFilename = `Member_report_${moment().format('YYYYMMDD_HHmmss')}.csv`;
          const outputLAUFilename = `Member_report_LAU_${moment().format('YYYYMMDD_HHmmss')}.csv`;
          const outputDAUFilename = `Member_report_DAU_${moment().format('YYYYMMDD_HHmmss')}.csv`;
          let fileName = outputFilename;
          if (params?.exportType && params?.lastActiveDateFrom) {
            fileName = outputLAUFilename;
          } else if (params?.exportType && params?.firstActiveDateFrom) {
            fileName = outputDAUFilename;
          }

          const urlDownload = URL.createObjectURL(new Blob([response?.data]));
          const link = document.createElement('a');
          link.href = urlDownload;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          return { code: 200 };
        }
      });
  } catch (error) {
    toastError(error.message);
    throw error;
  }
};

const configServices = {
  postService,
  getService,
  deleteService,
  putService,
  downloadXLSFile
};

export default configServices;
