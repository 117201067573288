import { createAsyncThunk } from '@reduxjs/toolkit';

import { MagazineConstants } from 'redux/constant';
import { MagazineServices } from 'services/index';

export const GetList = createAsyncThunk(MagazineConstants.GetList, async (query) => {
  let result = {};
  await MagazineServices.GetList(query).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const GetDetail = createAsyncThunk(MagazineConstants.GetDetail, async (query) => {
  let result = {};
  const {id, data}= query;
  await MagazineServices.GetDetail(id, data).then((res) => {
    const { code, data } = res;
    const isSuccess = code === 200;
    if (isSuccess) {
      result = { data, isSuccess };
    }
  });
  return result;
});

export const Upload = createAsyncThunk(MagazineConstants.Upload, async (query) => {
  let result = {};
  const {id, data} = query;
  await MagazineServices.Upload(id, data).then((res) => {
    const {code, data} = res;
    const isSuccess = code === 200;
    result = { isSuccess, data };
  });
  return result;
});

export const Create = createAsyncThunk(MagazineConstants.Create, async (query) => {
  let result = {};
  await MagazineServices.Create(query).then((res) => {
    const {code, data} = res;
    const isSuccess = code === 200;
    result = { isSuccess, data };
  });
  return result;
});

export const Update = createAsyncThunk(MagazineConstants.Update, async (query) => {
  let result = {};
  const {id, data} = query;
  await MagazineServices.Update(id, data).then((res) => {
    const {code, data} = res;
    const isSuccess = code === 200;
    result = { isSuccess, data };
  });
  return result;
});

export const CreateSection = createAsyncThunk(MagazineConstants.CreateSection, async (query) => {
  let result = {};
  const {id, data} = query;
  await MagazineServices.CreateSection(id,data).then((res) => {
    const {code, data} = res;
    const isSuccess = code === 200;
    result = { isSuccess, data };
  });
  return result;
});

export const UpdateSectionAll = createAsyncThunk(MagazineConstants.UpdateSectionAll, async (query) => {
  let result = {};
  const {id, data} = query;
  await MagazineServices.UpdateSectionAll(id,data).then((res) => {
    const {code, data} = res;
    const isSuccess = code === 200;
    result = { isSuccess, data };
  });
  return result;
});

export const UpdateSection = createAsyncThunk(MagazineConstants.UpdateSection, async (query) => {
  let result = {};
  const {id, sectionId, data} = query;
  await MagazineServices.UpdateSection(id,sectionId,data).then((res) => {
    const {code, data} = res;
    const isSuccess = code === 200;
    result = { isSuccess, data };
  });
  return result;
});

export const DeleteSection = createAsyncThunk(MagazineConstants.DeleteSection, async (query) => {
  let result = {};
  const {id, sectionId} = query;
  await MagazineServices.DeleteSection(id,sectionId).then((res) => {
    const {code} = res;
    const isSuccess = code === 200;
    result = { isSuccess };
  });
  return result;
});

export const Delete = createAsyncThunk(MagazineConstants.Delete, async (query) => {
  let result = {};
  const {id} = query;
  await MagazineServices.Delete(id).then((res) => {
    const {code} = res;
    const isSuccess = code === 200;
    result = { isSuccess };
  });
  return result;
});


