import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Form } from 'antd';
import { isEmpty } from 'lodash';

import LoginComponent from './components';
import { LOCAL_STORAGE } from 'common/constants';
import { encryptStorage } from 'common/encryptLocal';
import useLanguage from 'common/hook/useLanguage';
import { handleSignin } from 'redux/actions';
import LoginService from 'services/login.service';

import './style.scss';

function LoginPage(props) {
   const [isVisible, setIsVisible] = useState(false);
   const [recaptcha, setRecaptcha] = useState();
   const dataUser = encryptStorage.getItem(LOCAL_STORAGE.data);

   const {language} = useLanguage();
   const dispatch = useDispatch();
   const [form] = Form.useForm();

   useEffect(() => {
      if (!isEmpty(dataUser)) {
         // history.push(routes.dashboard.path);
      }
   }, [dataUser]);

   async function onFinish(values) {
      setIsVisible(true);
      dispatch(showLoading());
      setRecaptcha(false);
      try {
         const response = await LoginService.Signin(values);
         if (!isEmpty(response)) {
            dispatch(handleSignin(response.data));
         }
      } finally {
         setIsVisible(false);
         dispatch(hideLoading());
         form.resetFields();
      }
   }

   return (
      <LoginComponent
         isVisible={isVisible}
         recaptcha={recaptcha}
         onFinish={onFinish}
         language={language}
         form={form}
         {...props}
      />
   );
}
export default LoginPage;
