// export const FETCH_MENU_LIST = 'FETCH_MENU_LIST';
// export const FETCH_MENU_LIST_LITE = 'FETCH_MENU_LIST_LITE';
// export const FETCH_MENU_LIST_API = 'FETCH_MENU_LIST_API';
// export const UPDATE_API_MENU_DETAIL = 'UPDATE_API_MENU_DETAIL';
// export const CREATE_API_MENU_DETAIL = 'CREATE_API_MENU_DETAIL';
// export const UPDATE_API_MENU_RELATIONSHIP = 'UPDATE_API_MENU_RELATIONSHIP';
// export const DELETE_MENU_DETAIL = 'DELETE_MENU_DETAIL';
// export const CREATE_MENU_PERMISSION = 'CREATE_MENU_PERMISSION';
export const GetList = 'FETCH_MENU_LIST';
export const GetListLite = 'FETCH_MENU_LIST_LITE';
export const Update = 'UPDATE_MENU_LIST';
export const Create = 'CREATE_NEW_MENU';
export const CreatePermission = 'CREATE_MENU_PERMISSION';
export const Delete = 'DELETE_MENU';
export const GetListApi= 'FETCH_MENU_LIST_API';
export const EditApi= 'EDIT_MENU_API';