import { createSlice } from '@reduxjs/toolkit';

import {
  QnAAction
} from 'redux/actions';

export const qnaSlice = createSlice({
  name: 'qna',
  initialState: {
    listData: [],
    detail: {},
    isSuccess: false,
    totalCount: 0,
    message: ''
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(QnAAction.GetList.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
      state.totalCount = 0;
    });
    builder.addCase(QnAAction.GetList.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      const {listData, totalCount} = data;
      state.listData = listData;
      state.totalCount = totalCount;
      state.isSuccess = isSuccess;
    });
    builder.addCase(QnAAction.GetDetail.pending, (state) => {
      state.message = '';
      state.isSuccess = false;
    });
    builder.addCase(QnAAction.GetDetail.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.detail = data;
      state.isSuccess = isSuccess;
    });
    builder.addCase(QnAAction.Reply.fulfilled, (state, action) => {
      const { isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });
  }
});

export const { clearStoreListLite } = qnaSlice.actions;

export default qnaSlice.reducer;
