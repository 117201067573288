import { createSlice } from '@reduxjs/toolkit';

import {
    StoreMainStyleAction
} from 'redux/actions';

export const storeMainStyleSlice = createSlice({
    name: 'storeMainStyle',
    initialState: {
        listData: [],
        isSuccess: false,
        totalCount: 0,
        message: ''
    },
    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(StoreMainStyleAction.GetList.pending, (state) => {
            state.message = '';
            state.isSuccess = false;
            state.totalCount = 0;
        });
        builder.addCase(StoreMainStyleAction.GetList.fulfilled, (state, action) => {
            const { data, isSuccess } = action.payload;
            const { listData, totalCount } = data;
            state.listData = listData;
            state.totalCount = totalCount;
            state.isSuccess = isSuccess;

        });
        builder.addCase(StoreMainStyleAction.UnSetMainStyle.fulfilled, (state, action) => {
            const { isSuccess } = action.payload;

            state.isSuccess = isSuccess;
            state.message = 'unset main style successfully';
        });
        builder.addCase(StoreMainStyleAction.SetMainStyle.fulfilled, (state, action) => {
            const { isSuccess } = action.payload;
            state.isSuccess = isSuccess;
            state.message = 'set main style successfully';

        });
        builder.addCase(StoreMainStyleAction.ClearMainStyle.fulfilled, (state) => {
            state.listData = [];
            state.totalCount = 0;
            state.isSuccess = true;
        });
    }
});

export default storeMainStyleSlice.reducer;