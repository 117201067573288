import { createSlice } from '@reduxjs/toolkit';
import { actionGetListPermission, actionGetListUser, actionUpdatePermission } from 'redux/actions';

export const permissionSlice = createSlice({
  name: 'permission',
  initialState: {
    list: [],
    listUser: [],
    isSuccess: false,
    message: '',
    levelTitle: '',
  },
  reducers: {
  },

  extraReducers: (builder) => {
    builder.addCase(actionGetListPermission.pending, (state, action) => {
      state.message = '';
    });
    builder.addCase(actionGetListPermission.fulfilled, (state, action) => {
      const { data, isSuccess, levelTitle } = action.payload;
      state.list = data;
      state.isSuccess = isSuccess;
      state.levelTitle = levelTitle
    });
    builder.addCase(actionGetListUser.pending, (state, action) => {
      state.message = '';
    });
    builder.addCase(actionGetListUser.fulfilled, (state, action) => {
      const { data, isSuccess } = action.payload;
      state.listUser = data;
      state.isSuccess = isSuccess;
    });
    builder.addCase(actionUpdatePermission.pending, (state, action) => {
      state.message = '';
    });
    builder.addCase(actionUpdatePermission.fulfilled, (state, action) => {
      const { data, isSuccess, message } = action.payload;
      state.message = message;
      state.isSuccess = isSuccess;
    });

  },
});


export default permissionSlice.reducer;
