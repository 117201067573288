import 'assets/fonts/fonts.scss';
import 'common/styles/global.scss';
import 'common/styles/index.scss';
import 'common/styles/button.scss';
import 'common/styles/checkbox.scss';
import 'common/styles/custom.scss';
import 'common/styles/dark.scss';
import 'common/styles/detailLabeler.scss';
import 'common/styles/detailQc.scss';
import 'common/styles/library.scss';
import 'common/styles/folder.scss';
import 'common/styles/images.scss';
import 'common/styles/input.scss';
import 'common/styles/labelList.scss';
import 'common/styles/light.scss';
import 'common/styles/login.scss';
import 'common/styles/qcDashBoard.scss';
import 'common/styles/radio.scss';
import 'common/styles/select.scss';
import 'common/styles/store.scss';
import 'common/styles/tab.scss';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer
      limit={1}
      position="top-center"
      autoClose={2000}
      hideProgressBar
      newestOnTop={true}
      closeOnClick
      rtl={false}
      draggable
      transition={Slide}
      pauseOnHover
      theme="light"
    />
  </Provider>
);
