import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThemeStyleConstants } from 'redux/constant';
import { ThemeProductService, ThemeStyleServices } from 'services/index';
export const GetList = createAsyncThunk(ThemeStyleConstants.GetList, async (query) => {
  let result = {};
  if (query.type === 'theme_style') {
    delete query.type;
    await ThemeStyleServices.GetList(query).then((res) => {
      const { code, data } = res;
      const isSuccess = code === 200;
      if (isSuccess) {
        result = { data, isSuccess };
      }
    });
  } else {
    delete query.type;
    await ThemeProductService.GetList(query).then((res) => {
      const { code, data } = res;
      const isSuccess = code === 200;
      if (isSuccess) {
        result = { data, isSuccess };
      }
    });
  }

  return result;
});

export const AddTheme = createAsyncThunk(ThemeStyleConstants.AddThemeStyle, async (query) => {
  let result = {};
  if (query.type === 'theme_style') {
    delete query.type;
    await ThemeStyleServices.AddListStyle(query).then((res) => {
      const { code, message } = res;
      const isSuccess = code === 200;
      if (isSuccess) {
        result = { isSuccess, message };
      }
    });
  } else {
    delete query.type;
    await ThemeProductService.AddListStyle(query).then((res) => {
      const { code, message } = res;
      const isSuccess = code === 200;
      if (isSuccess) {
        result = { isSuccess, message };
      }
    });
  }

  return result;
});

export const DeleteTheme = createAsyncThunk(ThemeStyleConstants.DeleteThemeStyle, async (query) => {
  let result = {};
  if(query.type === 'theme_style') {
    await ThemeStyleServices.DeleteListStyle(query).then((res) => {
      const { message, code } = res;
      const isSuccess = code === 200;
      if (isSuccess) {
        result = { isSuccess, message };
      }
    });
  } else {
    await ThemeProductService.DeleteListStyle(query).then((res) => {
      const { message, code } = res;
      const isSuccess = code === 200;
      if (isSuccess) {
        result = { isSuccess, message };
      }
    });
  }
  return result;
});

export const ClearTheme = createAsyncThunk(ThemeStyleConstants.ClearThemeStyle, async () => {
  let result = {};
  return result;
});